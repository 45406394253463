import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  TableColumnsType,
  Typography
} from 'antd';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../../../components/ActionButton';
import BoldButtonLabel from '../../../../../components/BoldButtonLabel';
import FieldDisplay from '../../../../../components/FieldDisplay';
import { objectHelpers } from '../../../../../helpers';
import _ from '../../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../../layouts/primary-layout';
import { useLoader } from '../../../../../stores/use-loader';
import { useLogisticsNodes } from '../../../../promotion-engine/hooks/drop-down-hooks/use-logistics-nodes';
import { logisticService } from '../../../services';

interface IManagePostCodeProps {}

const DnnManagePostCode: React.FunctionComponent<IManagePostCodeProps> = props => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [createNodeForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dnnDetailResponse, setDnnDetailResponse] = React.useState({} as any);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const [dnnData, setDnnData] = React.useState({} as any);
  const [createNodesModal, setCreateNodesModal] = React.useState(false);
  const [nodeForm] = Form.useForm();
  const {
    debouncedFetchUserDropdownOptions,
    nodeDropdownFetching,
    nodeDropdownOptions,
    setNodeDropdownFetching,
    clearPreviousNodeDropdownOptions
  } = useLogisticsNodes();
  const [alreadyPresentNodes, setAlreadyPresentNodes] = React.useState([] as any);

  const columns: TableColumnsType<any> = [
    // {
    //   title: 'Node',
    //   dataIndex: 'node_id',
    //   align: 'center',
    //   sorter: (a: any, b: any) => a.node_id.localeCompare(b.node_id) // Corrected from postcode to node_id
    // },
    {
      title: 'Node Name',
      dataIndex: '',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <section className="flex justify-center items-center">{record?.node_info?.node_name}</section>;
      }
    },
    {
      title: 'Node Type',
      dataIndex: '',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <section className="flex justify-center items-center">{record?.node_info?.node_type}</section>;
      }
    },
    {
      title: 'Address',
      dataIndex: '',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <section className="flex justify-center items-center">
            {Object.entries(record?.node_info?.address)
              .filter(([key, value]) => key !== 'type' && value) // Remove 'type' and empty fields
              .map(([key, value]) => `${value}`)
              .join(', ')}
          </section>
        );
      }
    },

    {
      title: 'Active',
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <section className="flex justify-center items-center">
            <Switch
              checkedChildren={'Yes'}
              unCheckedChildren={'No'}
              onChange={value => {
                const data = dnnDetailResponse.dnn_nodes.map((node: any) => {
                  if (node.node_id === record.node_id) {
                    return {
                      ...node,
                      is_active: value
                    };
                  }
                  return node;
                });

                const bodyData = {
                  node_group: data
                };
                handleSaveNodes(bodyData, 'edit');
                // setDnnDetailResponse({
                //   ...dnnDetailResponse,
                //   dnn_nodes: data
                // });
              }}
              checked={record?.is_active ? true : false}
            />
          </section>
        );
      }
    },
    {
      title: 'Action',
      render(value, record, index) {
        return (
          <section className="flex justify-center gap-4">
            {/* <ActionButton action="CREATE_NEW_VERSION" onClick={() => {}} title="Edit DNN" /> */}
            {/* Uncomment and fix the following button if needed */}
            {/* 
            <ActionButton
              action={record.is_active ? 'DEACTIVATE' : 'ACTIVATE'}
              onClick={() => handleStatusChange(!record.is_active, record)}
              title={record.is_active ? 'Deactivate' : 'Activate'}
            /> 
            */}

            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                const data = {
                  node_group: dnnDetailResponse?.dnn_nodes.filter((item: any) => item.node_id !== record.node_id)
                };
                handleSaveNodes(data, 'delete');
                setDnnDetailResponse({
                  ...dnnDetailResponse,
                  node_group: data.node_group
                });
              }}
            >
              <ActionButton action="DELETE" title="Delete" onClick={() => {}} />
            </Popconfirm>
          </section>
        );
      },
      align: 'center'
    }
  ];

  React.useEffect(() => {
    getPostCodeList(0);
    loadDnnDetails();
  }, []);

  const closeNodesModal = () => {
    setCreateNodesModal(false);
    clearPreviousNodeDropdownOptions();
  };

  const getPostCodeList = async (offset = 0, limit = 10) => {
    setLoading(true);
    const formValues = nodeForm.getFieldsValue();
    const filteredFormValues = {
      ...objectHelpers.deleteUndefinedValuesFromObject(formValues),
      offset: offset + '',
      limit: limit + ''
    };
    setSearchParams(filteredFormValues);
    const params = {
      ...formValues,
      offset,
      limit
    };
    const { data, errors } = await logisticService.getNodeListDNN(id as string, params);
    if (_.isEmpty(errors)) {
      setDnnDetailResponse(data);
      setAlreadyPresentNodes(data.dnn_nodes.map((item: any) => item.node_id));
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const loadDnnDetails = async () => {
    setLoading(true);
    const { data, errors } = await logisticService.getDNNbyDNNId(id as string);
    if (_.isEmpty(errors)) {
      setDnnData(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };
  const handlePageChange = (currentPage: number | null, pageSize: number | null) => {
    const offset = currentPage ? currentPage - 1 : parseInt(searchParams.get('offset') || '0');
    const limit = pageSize ? pageSize : parseInt(searchParams.get('limit') || '10');
    getPostCodeList(offset, limit);
  };

  const handleSaveNodes = async (values: any, type: string) => {
    setLoading(true);
    let bodyData = {};

    if (type === 'add') {
      values.node_group.forEach((node: any) => {
        if (node.is_active === undefined) {
          node.is_active = false;
        }
      });
      bodyData = {
        node_group: [...dnnDetailResponse?.dnn_nodes, ...values.node_group]
      };
    } else {
      bodyData = values;
    }
    const { data, errors } = await logisticService.updateNodeListDNN(id as string, bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Nodes updated successfully' });
      closeNodesModal();
      getPostCodeList(0);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
    createNodeForm.resetFields();
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Form form={nodeForm} layout="vertical" onFinish={() => getPostCodeList(0)}>
            <Row gutter={[24, 12]} align={'middle'} className="mb-4">
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  Distribution Node Network &gt; Manage Nodes
                </Typography.Title>
              </Col>
            </Row>
            <section className="flex flex-wrap flex-col gap-2">
              <FieldDisplay label="Distribution Node Network Name" value={dnnData?.dnn_name || ''} />
              {dnnData?.description && <FieldDisplay label="Description" value={dnnData?.description || ''} />}
              <FieldDisplay label="Country" value={dnnData?.country_code || ''} />
            </section>
            <section>
              <Row className="my-4" gutter={[12, 12]}>
                <Col xs={24} md={6}>
                  <Form.Item name={'Node'} label="">
                    <Input.Search
                      placeholder="Search Node"
                      size="large"
                      enterButton
                      onSearch={() => getPostCodeList(0)}
                    ></Input.Search>
                  </Form.Item>
                  <Form.Item label="">
                    <Row justify={'start'}>
                      <Col xs={24} md={4}>
                        <Button onClick={() => setCreateNodesModal(true)} type="primary">
                          <BoldButtonLabel labelText="Add Node" />
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>

              {/* {dnnDetailResponse?.dnn_nodes?.length > 0 && (
                <div className="flex justify-end my-2">
                  <CustomPagination
                    currentPage={dnnDetailResponse?.current_page + 1 || 0}
                    totalPages={dnnDetailResponse.total_pages || 0}
                    handleNext={handlePageChange}
                    handlePageChange={handlePageChange}
                    handlePrevious={handlePageChange}
                  />
                </div>
              )} */}
              <Table
                loading={false}
                pagination={{
                  total: dnnDetailResponse?.total_pages
                    ? dnnDetailResponse.total_pages * parseInt(searchParams.get('limit') as string) || 10
                    : 0,
                  showSizeChanger: true,
                  pageSizeOptions: ['1', '10', '20', '50', '100'],
                  pageSize: parseInt(searchParams.get('limit') as string) || 10,
                  current: parseInt(searchParams.get('offset') as string) + 1 || 1,
                  onChange: (current, pageSize) => {
                    handlePageChange(current, pageSize);
                  }
                }}
                bordered
                dataSource={dnnDetailResponse?.dnn_nodes || []}
                columns={columns}
                scroll={{ x: 1000 }}
              ></Table>
            </section>
            <Row gutter={[12, 12]} className="mt-4">
              <Col xs={24} md={8} lg={6}>
                <Button
                  block
                  size="large"
                  onClick={() => {
                    if (searchParams.get('backUrl')) {
                      navigate(searchParams.get('backUrl') as string);
                    } else {
                      navigate('/logistics/dnn');
                    }
                  }}
                >
                  <BoldButtonLabel labelText="Go Back" />
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>

      <Modal
        width={900}
        centered
        open={createNodesModal}
        onCancel={closeNodesModal}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Add Nodes</Typography.Title>
          </div>
        }
      >
        <Form form={createNodeForm} layout="vertical" onFinish={values => handleSaveNodes(values, 'add')}>
          <Form.List name="node_group">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <React.Fragment key={key}>
                      <Row>
                        <Col xs={24}>
                          <Divider orientation="left" plain>
                            <span className="text-lg">Node - {index + 1} </span>
                          </Divider>
                        </Col>
                      </Row>

                      <Row gutter={20}>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item
                            {...restField}
                            name={[name, 'node_id']}
                            label="Node"
                            rules={[{ required: true, message: 'Please select Node' }]}
                          >
                            <Select
                              showSearch
                              allowClear
                              onClear={() => {}}
                              size="large"
                              filterOption={false}
                              placeholder="Search nodes"
                              notFoundContent={nodeDropdownFetching ? <Spin size="small" /> : null}
                              onFocus={async () => {
                                const node_group = [
                                  ...dnnDetailResponse?.dnn_nodes,
                                  ...createNodeForm.getFieldValue('node_group')
                                ];

                                const nodeIds = node_group
                                  .map(node => node?.node_id)
                                  .filter(node_id => node_id !== undefined);
                                await debouncedFetchUserDropdownOptions('', nodeIds, 'ALL');
                              }}
                              onSearch={async searchTerm => {
                                if (searchTerm) {
                                  const node_group = [
                                    ...dnnDetailResponse?.dnn_nodes,
                                    ...createNodeForm.getFieldValue('node_group')
                                  ];

                                  const nodeIds = node_group
                                    .map(node => node?.node_id)
                                    .filter(node_id => node_id !== undefined);
                                  await debouncedFetchUserDropdownOptions(searchTerm, nodeIds, 'ALL');
                                }
                              }}
                              onSelect={async value => {
                                setAlreadyPresentNodes([...alreadyPresentNodes, value]);
                                const node = createNodeForm
                                  .getFieldValue('node_group')
                                  .find((node: any) => node.node_id === value);

                                if (node) {
                                  const selectedNode = nodeDropdownOptions.find(
                                    (option: any) => option.value === value
                                  );
                                  if (selectedNode && 'address' in selectedNode) {
                                    node.address = selectedNode.address;
                                  }

                                  const node_group = createNodeForm
                                    .getFieldValue('node_group')
                                    .filter((node: any) => node.node_id !== value);
                                  createNodeForm.setFieldsValue({
                                    node_group: [...node_group, node]
                                  });
                                }
                              }} // onFocus={async searchTerm => {
                              //   if (searchTerm) {
                              //     // node_group: [...dnnDetailResponse?.dnn_nodes, ...values.node_group]
                              //     const node_group = [
                              //       ...dnnDetailResponse?.dnn_nodes,
                              //       ...createNodeForm.getFieldValue('node_group')
                              //     ];

                              //     const nodeIds = node_group
                              //       .map(node => node?.node_id)
                              //       .filter(node_id => node_id !== undefined);
                              //     await debouncedFetchUserDropdownOptions('', nodeIds);
                              //   }
                              // }}
                              options={nodeDropdownOptions}
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item {...restField} name={[name, 'address']} label="Address">
                            <Typography.Text>
                              {createNodeForm.getFieldValue(['node_group', name, 'address'])}
                            </Typography.Text>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8} lg={6}>
                          <Form.Item {...restField} name={[name, 'is_active']} label="Is Active?">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" />
                          </Form.Item>
                        </Col>

                        <div className="ml-2 my-2 flex justify-center items-center">
                          <DeleteOutlined
                            className="text-red-500 text-xl"
                            title="Delete Node"
                            onClick={() => {
                              setAlreadyPresentNodes((prevNodes: any) =>
                                prevNodes.filter((_: any, i: number) => i !== name)
                              );
                              remove(name);
                            }}
                          />
                        </div>
                      </Row>
                    </React.Fragment>
                  );
                })}

                <Form.Item>
                  <Row justify={'start'}>
                    <Col xs={24} md={4}>
                      <Button onClick={() => add()} icon={<PlusOutlined />}>
                        <BoldButtonLabel labelText="Add Nodes" />
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Row>
            <Col xs={24} md={6}>
              <Button type="primary" size="large" block htmlType="submit">
                <BoldButtonLabel labelText="Submit" />
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </PrimaryLayout>
  );
};

export default DnnManagePostCode;
