import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, FormInstance, Input, Row, Select, Switch, Typography } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import { useProfile } from '../../../hooks/use-profile';
import { nodeService } from '../../../services';
import { loggingIn } from '../../../services/login.services';
import { useAuth } from '../../../stores/use-auth';
import { useLoader } from '../../../stores/use-loader';

interface IManageUserForm {
  formInstance: FormInstance;
  formType: 'create' | 'edit';
  handleOnSave: () => void;
}

const ManageUserForm: React.FunctionComponent<IManageUserForm> = ({ formInstance, formType, handleOnSave }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [facilityCodeOptions, setFacilityCodeOptions] = React.useState<any>([]);
  const { profileDetails } = useProfile();
  const [productList, setProductList] = React.useState<any>([]);
  const [userGroupList, setUserGroupList] = React.useState<any>([]);
  const [facilityTypeBasedOnUserGrp, setfacilityTypeBasedOnUserGrp] = React.useState<any>([]);
  const [roleListBasedOnUserGrp, setRoleListBasedOnUserGrp] = React.useState<any>([]);

  const { tenentName } = useAuth(({ tenentName }) => ({ tenentName }));

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  React.useEffect(() => {
    if (formType === 'create') {
      formInstance.setFieldsValue({
        user_enabled: true,
        email_verified: true,
        subscriptions: [{ product: undefined, group_details: [{}] }]
      });
    }
  }, [formType]);

  React.useEffect(() => {
    if (Object.keys(profileDetails).length > 0) {
      getProducts();
      getUserGroup();
    }
  }, [profileDetails]);

  const getProducts = async () => {
    const intialData = profileDetails?.tenant_details?.subscriptions?.subscription_info?.filter(
      item => item?.is_enabled
    );
    const prodOptions = intialData?.map((item: any) => {
      return { label: item.product_id, value: item.product_id };
    });

    setProductList(prodOptions);
  };

  const getUserGroup = async () => {
    if (formType === 'edit') {
      let subscriptionData = formInstance.getFieldsValue().subscriptions;
      subscriptionData?.map(async (itm: any, parentIndex: any) => {
        await UserGroupSetList(itm.product, parentIndex, itm);
      });
    }
  };

  const UserGroupSetList = async (name: any, parentIndex: any, wholeParentData: any) => {
    const { data, errors } = await loggingIn.allRoleList(name);
    if (_.isEmpty(errors)) {
      const formattedData = (data.group_details || [])?.map((item: any) => {
        return {
          label: item?.user_group,
          value: item?.user_group,
          facilities: item?.facilities
        };
      });

      setUserGroupList((prev: any) => {
        const updated = [...prev];
        updated[parentIndex] = formattedData;
        return updated;
      });
      // ------------------------------UserGroup
      wholeParentData.group_details.map(async (childItem: any, childIndex: any, allData: any) => {
        let calarative = formattedData.find((Dc: any) => Dc.value === childItem.user_group);
        const floorformattedData = (calarative?.facilities || [])?.map((calo: any) => {
          return {
            label: calo?.facility_type,
            value: calo?.facility_type,
            roles: calo?.roles
          };
        });
        setfacilityTypeBasedOnUserGrp((prev: any) => {
          const updated = [...prev];
          if (!updated[parentIndex]) {
            updated[parentIndex] = [];
          }
          updated[parentIndex][childIndex] = floorformattedData;
          return updated;
        });
        // ------------------------------UserGroup
        // ------------------------------UserRole
        let calarativeRole = floorformattedData.find((Dc: any) => Dc.value === childItem.facility_type);
        let formatRoles = calarativeRole.roles.map((item: any) => {
          return { label: item, value: item };
        });
        setRoleListBasedOnUserGrp((prev: any) => {
          const updated = [...prev];
          if (!updated[parentIndex]) {
            updated[parentIndex] = [];
          }
          updated[parentIndex][childIndex] = formatRoles;
          return updated;
        });
        // ------------------------------UserRole
        // ------------------------------facilityCode
        let options = await getFacilityCodeOptions(childItem?.facility_type);
        setFacilityCodeOptions((prev: any) => {
          const updated = [...prev];
          if (!updated[parentIndex]) {
            updated[parentIndex] = [];
          }
          updated[parentIndex][childIndex] = options;
          return updated;
        });
        // ------------------------------facilityCode
      });
    } else {
      displayErrorNotifications(errors);
    }
  };

  const validateName = (message: string) => ({
    pattern: /^[A-Za-z.]+$/,
    message
  });

  const validatePassword = () => ({
    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[A-Za-z\d\W]{8,}$/,
    message: t('passwordValidation')
  });

  const validateConfirmPassword = () => ({
    validator: (_: unknown, value: string) => {
      if (!value || formInstance.getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(t('confirmPasswordDoesNotMatch')));
    }
  });

  const handleProductSelect = (value: any, selectedName: any) => {
    getRolesList(selectedName, value);
    addOrRemoveProducts();
  };

  const handleOnUserGrpSelect = (nest_1_channelName: any, selectedName: any, parent_name: any) => {
    let data = userGroupList[parent_name].find((itm: any) => {
      return itm?.value === selectedName;
    });
    const formattedData = (data.facilities || [])?.map((item: any) => {
      return {
        label: item?.facility_type,
        value: item?.facility_type,
        roles: item?.roles
      };
    });
    setfacilityTypeBasedOnUserGrp((prev: any) => {
      const updated = [...prev];
      if (!updated[parent_name]) {
        updated[parent_name] = [];
      }
      updated[parent_name][nest_1_channelName] = formattedData;
      return updated;
    });
  };

  const handleProductDeselect = (value: string) => {
    addOrRemoveProducts();
  };

  const callNodeServices = async (userGroup: any) => {
    const { data, errors } = await nodeService.getFacilityCodeByNode(userGroup);
    if (_.isEmpty(errors)) {
      const formattedData = (data.nodes || [])?.map((item: any) => ({
        label: item?.node_name,
        value: item?.node_name
      }));
      return formattedData;
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getFacilityCodeOptions = async (userGroup: any) => {
    setLoading(true);
    let updateUserGrp = userGroup;
    if (updateUserGrp === 'WAREHOUSE') {
      updateUserGrp = 'WH';
    } else if (updateUserGrp === 'VENDOR') {
      updateUserGrp = 'VENDOR_FACILITY';
    }
    if (['STORE', 'WH', 'VENDOR_FACILITY'].includes(updateUserGrp)) {
      let data = await callNodeServices(updateUserGrp);
      setLoading(false);
      return data;
    }
    if (['MARKETPLACE', 'ONLINE', 'ENTERPRISE'].includes(updateUserGrp)) {
      let getChannelData = profileDetails.tenant_details.channels;
      let getFilterUserGroupData = getChannelData.filter(data => data.channel_type === userGroup);
      const data = (getFilterUserGroupData || [])?.map((item: any) => ({
        label: item?.channel_id,
        value: item?.channel_id
      }));
      setLoading(false);
      return data;
    }
    setLoading(false);

    return [];
  };

  const handleUserFacilityChange = async (nest_1_channelName: any, facilityType: any, parentIndex: any) => {
    let listUGData = facilityTypeBasedOnUserGrp[parentIndex][nest_1_channelName].find(
      (itm: any) => itm.value === facilityType
    );
    let formatRoles = listUGData.roles.map((item: any) => {
      return { label: item, value: item };
    });
    setRoleListBasedOnUserGrp((prev: any) => {
      const updated = [...prev];
      if (!updated[parentIndex]) {
        updated[parentIndex] = [];
      }
      updated[parentIndex][nest_1_channelName] = formatRoles;
      return updated;
    });

    const subscriptionName = 'subscriptions';
    const path = [subscriptionName, parentIndex, 'group_details', nest_1_channelName, 'facility_code'];
    formInstance.setFieldValue(path, undefined);
    if (facilityType === 'ENTERPRISE') {
      setFacilityCodeOptions((prev: any) => {
        const updated = [...prev];
        if (!updated[parentIndex]) {
          updated[parentIndex] = [];
        }
        updated[parentIndex][nest_1_channelName] = [{ label: tenentName, value: tenentName }];
        return updated;
      });
    } else {
      const options = await getFacilityCodeOptions(facilityType);
      setFacilityCodeOptions((prev: any) => {
        const updated = [...prev];
        if (!updated[parentIndex]) {
          updated[parentIndex] = [];
        }
        updated[parentIndex][nest_1_channelName] = options;
        return updated;
      });
    }
  };

  const optionsRole = (userGroupList: any, parent_name: any) => {
    return userGroupList[parent_name] || [];
  };

  const optionFacilityType = (facilityTypeBasedOnUserGrp: any, parent_name: any, nest_1_channelName: any) => {
    if (facilityTypeBasedOnUserGrp.length > 0) {
      if (!!facilityTypeBasedOnUserGrp[parent_name]) {
        return facilityTypeBasedOnUserGrp[parent_name][nest_1_channelName];
      }
    }
    return [];
  };

  const optionRoleType = (roleListBasedOnUserGrp: any, parent_name: any, nest_1_channelName: any) => {
    if (roleListBasedOnUserGrp.length > 0) {
      if (!!roleListBasedOnUserGrp[parent_name]) {
        return roleListBasedOnUserGrp[parent_name][nest_1_channelName];
      }
    }
    return [];
  };

  const optionFacilityCode = (facilityCodeOptions: any, parent_name: any, nest_1_channelName: any) => {
    if (facilityCodeOptions.length > 0) {
      if (!!facilityCodeOptions[parent_name]) {
        return facilityCodeOptions[parent_name][nest_1_channelName];
      }
    }
    return [];
  };

  const getRolesList = async (name: any, value: any) => {
    setLoading(true);
    const { data, errors } = await loggingIn.allRoleList(name);
    if (_.isEmpty(errors)) {
      const formattedData = (data.group_details || [])?.map((item: any) => {
        return {
          label: item?.user_group,
          value: item?.user_group,
          facilities: item?.facilities
        };
      });
      setUserGroupList((prev: any) => {
        const updated = [...prev];
        updated[value] = formattedData;
        return updated;
      });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const addOrRemoveProducts = () => {
    const intialData = profileDetails?.tenant_details?.subscriptions?.subscription_info?.filter(
      item => item?.is_enabled
    );
    const productNames = formInstance.getFieldsValue()?.subscriptions?.map((data: any) => data.product);
    const remaining = intialData.filter((item: any) => {
      return !productNames.includes(item.product_id);
    });
    const prodOptions = remaining?.map((item: any) => {
      return { label: item.product_id, value: item.product_id };
    });
    setProductList(prodOptions);
  };

  const validateSubscriptionFields = async () => {
    const UserForm = formInstance.getFieldsValue();
    const fieldsToValidate: (string | (string | number)[])[] = [];
    UserForm?.subscriptions?.forEach((subscription: any, index: number) => {
      fieldsToValidate.push(['subscriptions', index, 'product']);
      subscription?.group_details?.forEach((_: any, groupIndex: number) => {
        fieldsToValidate.push(['subscriptions', index, 'group_details', groupIndex, 'user_group']);
        fieldsToValidate.push(['subscriptions', index, 'group_details', groupIndex, 'role']);
        fieldsToValidate.push(['subscriptions', index, 'group_details', groupIndex, 'facility_type']);
        fieldsToValidate.push(['subscriptions', index, 'group_details', groupIndex, 'facility_code']);
      });
    });
    return formInstance.validateFields(fieldsToValidate);
  };

  return (
    <Form
      form={formInstance}
      layout="vertical"
      onFinish={handleOnSave}
      initialValues={{
        subscriptions: formType === 'edit' ? formInstance.getFieldValue('subscriptions') : []
      }}
    >
      <Row gutter={12} className="mt-4">
        <Col xs={12} md={6}>
          <Form.Item
            name="first_name"
            label={t('firstName')}
            rules={[{ required: true, message: t('fieldIsRequired') }, validateName(t('fieldIsNotValid'))]}
          >
            <Input className="w-full" allowClear placeholder={t('firstName')} />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item name="middle_name" label={t('middleName')} rules={[validateName(t('fieldIsNotValid'))]}>
            <Input className="w-full" allowClear placeholder={t('middleName')} />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="last_name"
            label={t('lastName')}
            rules={[{ required: true, message: t('fieldIsRequired') }, validateName(t('fieldIsNotValid'))]}
          >
            <Input className="w-full" allowClear placeholder={t('lastName')} />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name="email"
            label={t('email')}
            rules={[
              { required: true, message: t('fieldIsRequired') },
              { type: 'email', message: t('emailIsNotValid') }
            ]}
          >
            <Input className="w-full" allowClear placeholder={t('email')} disabled={formType === 'edit'} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col xs={12} md={6}>
          <Form.Item name="user_name" label={t('userName')} rules={[{ required: true, message: t('fieldIsRequired') }]}>
            <Input className="w-full" allowClear placeholder={t('userName')} disabled={formType === 'edit'} />
          </Form.Item>
        </Col>
        {formType === 'create' && (
          <>
            <Col xs={12} md={6}>
              <Form.Item
                name="password"
                label={t('password')}
                rules={[{ required: true, message: t('fieldIsRequired') }, validatePassword()]}
              >
                <Input.Password className="w-full" allowClear placeholder={t('password')} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name="confirm_password"
                label={t('confirmPassword')}
                dependencies={['password']}
                rules={[{ required: true, message: t('fieldIsRequired') }, validateConfirmPassword()]}
              >
                <Input.Password className="w-full" allowClear placeholder={t('confirmPassword')} />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>

      {formType === 'create' && (
        <Row gutter={12}>
          <Col xs={12} md={6}>
            <Form.Item name="user_enabled" valuePropName="checked" label={t('enableUser')}>
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Typography.Title level={5}>Subscriptions:</Typography.Title>
      <Form.List
        name="subscriptions"
        rules={[
          {
            validator: async (_, subscriptions) => {
              if (!subscriptions || subscriptions.length < 1) {
                return Promise.reject(new Error(t('atLeastOneSubscriptionMustBeAdded')));
              }
            }
          }
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map(({ key: parent_key, name: parent_name, ...restField }) => {
                return (
                  <Card className="mb-2" key={parent_key}>
                    <div className="flex flex-row-reverse mb-2">
                      {fields.length > 1 && (
                        <DeleteOutlined
                          className="text-red-500 text-xl cursor-pointer"
                          role="button"
                          title={t('removeSubscription')}
                          onClick={() => {
                            remove(parent_name);
                            addOrRemoveProducts();
                          }}
                        />
                      )}
                    </div>
                    <Row gutter={12}>
                      <Col xs={12} md={6}>
                        <Form.Item
                          {...restField}
                          name={[parent_name, 'product']}
                          rules={[{ required: true, message: t('fieldIsRequired') }]}
                          label={t('product')}
                        >
                          <Select
                            placeholder={t('selectProduct')}
                            options={productList}
                            onChange={selectedName => handleProductSelect(parent_name, selectedName)}
                            onDeselect={handleProductDeselect}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Typography.Title level={5}>Group:</Typography.Title>
                    {/* NESTED FORM */}
                    <Form.List name={[parent_name, 'group_details']}>
                      {(groupFields, { add: addChannel, remove: removeChannel }) => (
                        <>
                          {groupFields.map(({ key: nest_1_key, name: nest_1_channelName, ...channelRestField }) => (
                            <Card className="mb-2" key={nest_1_key}>
                              <div className="flex flex-row-reverse mb-2">
                                {groupFields.length > 1 && (
                                  <DeleteOutlined
                                    className="text-red-500 text-xl cursor-pointer"
                                    role="button"
                                    title={'Remove Group'}
                                    onClick={() => removeChannel(nest_1_channelName)} // Use 'removeChannel' with correct name
                                  />
                                )}
                              </div>
                              <Row gutter={12}>
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    {...channelRestField}
                                    label={`User Group`}
                                    name={[nest_1_channelName, 'user_group']}
                                    rules={[{ required: true, message: 'Field is required' }]}
                                  >
                                    <Select
                                      placeholder="Select"
                                      options={optionsRole(userGroupList, parent_name)}
                                      onChange={selectedName =>
                                        handleOnUserGrpSelect(nest_1_channelName, selectedName, parent_name)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    {...channelRestField}
                                    label={`Facility Type`}
                                    name={[nest_1_channelName, 'facility_type']}
                                    rules={[{ required: true, message: 'Field is required' }]}
                                  >
                                    <Select
                                      placeholder="Select"
                                      options={optionFacilityType(
                                        facilityTypeBasedOnUserGrp,
                                        parent_name,
                                        nest_1_channelName
                                      )}
                                      onChange={value => {
                                        handleUserFacilityChange(nest_1_channelName, value, parent_name);
                                        // Check if the selected Facility Type is "Enterprize"
                                        // if (value === 'Enterprize') {
                                        //   formInstance.setFieldsValue({
                                        //     subscriptions: [
                                        //       ...formInstance
                                        //         .getFieldValue('subscriptions')
                                        //         .map((subscription: any, subIndex: any) => {
                                        //           if (subIndex === parent_name) {
                                        //             return {
                                        //               ...subscription,
                                        //               group_details: subscription.group_details.map(
                                        //                 (group: any, groupIndex: any) => {
                                        //                   if (groupIndex === nest_1_channelName) {
                                        //                     return { ...group, facility_code: ['rmg'] };
                                        //                   }
                                        //                   return group;
                                        //                 }
                                        //               )
                                        //             };
                                        //           }
                                        //           return subscription;
                                        //         })
                                        //     ]
                                        //   });
                                        // }
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    {...channelRestField}
                                    label={`Role`}
                                    name={[nest_1_channelName, 'role']}
                                    rules={[{ required: true, message: 'Field is required' }]}
                                  >
                                    <Select
                                      placeholder="Select"
                                      options={optionRoleType(roleListBasedOnUserGrp, parent_name, nest_1_channelName)}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    {...channelRestField}
                                    label={`Facility Code`}
                                    name={[nest_1_channelName, 'facility_code']}
                                    rules={[{ required: true, message: 'Field is required' }]}
                                  >
                                    <Select
                                      mode="multiple"
                                      allowClear
                                      placeholder="Select"
                                      options={optionFacilityCode(facilityCodeOptions, parent_name, nest_1_channelName)}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Card>
                          ))}
                          <Button
                            type="default"
                            onClick={async () => {
                              await validateSubscriptionFields();
                              addChannel();
                            }}
                            size="middle"
                            icon={<PlusOutlined />}
                          >
                            <BoldButtonLabel labelText="Add Group" />
                          </Button>
                        </>
                      )}
                    </Form.List>
                  </Card>
                );
              })}
              <Button
                type="default"
                onClick={async () => {
                  await validateSubscriptionFields();
                  add({ product: undefined, group_details: [{}] });
                }}
                size="middle"
                icon={<PlusOutlined />}
                disabled={productList.length < 1}
              >
                <BoldButtonLabel labelText="Add Subscription" />
              </Button>
            </>
          );
        }}
      </Form.List>

      <Row gutter={12}>
        <Col xs={12} md={6} className="mt-7">
          <Button type="primary" onClick={() => navigate(-1)} block>
            <BoldButtonLabel labelText={t('back')} />
          </Button>
        </Col>
        <Col xs={12} md={6} className="mt-7">
          <Button type="primary" htmlType="submit" block>
            <BoldButtonLabel labelText={t('save')} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ManageUserForm;
