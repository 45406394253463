import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  TimePicker,
  Typography
} from 'antd';
import * as React from 'react';
import { DAYS_OF_THE_WEEK } from '../../data/date-data';
import { validationHelpers } from '../../helpers';
import BoldButtonLabel from '../BoldButtonLabel';

const newcurrencyList = [
  { label: '🇮🇳 INR', value: 'IN', country: 'India' },
  { label: '🇨🇱 CLP', value: 'CLP', country: 'Chile' },
  { label: '🇺🇸 USD', value: 'US', country: 'USA' }
];

const shippingChargeTypes: { label: string; value: string }[] = [
  { label: 'Free', value: 'FREE' },
  { label: 'Flat', value: 'FLAT' },
  { label: 'Base Weight', value: 'BASE_WEIGHT' },
  { label: 'Slab Weight', value: 'SLAB_WEIGHT' },
  { label: 'Order Price', value: 'ORDER_PRICE' }
];

interface INodeConfigFormProps {
  nodeConfigForm: any;
  nodeDetails: any;
  handleClickNext: (data: any) => void;
  handleClickBack: (data: any) => void;
  currentStep: number;
}

const NodeConfigForm: React.FunctionComponent<INodeConfigFormProps> = ({
  currentStep,
  handleClickBack,
  handleClickNext,
  nodeDetails,
  nodeConfigForm
}) => {
  React.useEffect(() => {
    nodeConfigForm.setFieldsValue(nodeDetails);
    nodeConfigForm.setFieldsValue({
      is_package_provided: false
    });
  }, [nodeDetails]);

  return (
    <Form layout="vertical" form={nodeConfigForm} scrollToFirstError>
      <Row gutter={24}>
        <Col xs={12} md={8} xxl={6}>
          <Form.Item name="priority" label="Priority" rules={[{ required: true, message: 'Priority is required' }]}>
            <InputNumber className="w-full" min={0} placeholder="Priority is required" size="large"></InputNumber>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={12} md={8} xxl={6}>
          <Form.Item
            name="notification_time_in_minutes"
            label="Notification Time (Mins)"
            rules={[{ required: true, message: 'Notification time is required' }]}
          >
            <InputNumber
              className="w-full"
              min={0}
              placeholder="Enter notification time in mins"
              size="large"
            ></InputNumber>
          </Form.Item>
        </Col>

        <Col xs={24} md={12} xl={18}>
          <Form.Item
            name="consume_notification_time_in_non_working_hours"
            valuePropName="checked"
            label="Consume notification time in non working hours?"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={12} md={8} xxl={6}>
          <Form.Item
            name="inventory_execution_lead_time_in_minutes"
            label="Inventory execution lead time (Mins)"
            rules={[{ required: true, message: 'Inventory execution lead time is required' }]}
          >
            <InputNumber
              className="w-full"
              min={0}
              placeholder="Enter inventory execution lead time in mins"
              size="large"
            ></InputNumber>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={18}>
          <Form.Item
            name="consume_inventory_execution_lead_time_in_non_working_hours"
            valuePropName="checked"
            label="Consume inventory execution lead time in non working hours?"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8} xl={6}>
          <Form.Item
            name={'locality_range_in_km'}
            label="Locality Range (KMs)"
            rules={[{ required: true, message: 'Field Is Required' }]}
          >
            <InputNumber className="w-full" size="large" placeholder="Enter Locality Range" min={0}></InputNumber>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} xxl={4}>
          <Form.Item
            name="online_orders_delivery_available"
            valuePropName="checked"
            label="Online Orders Delivery Available?"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
          </Form.Item>
        </Col>

        <Col xs={12} md={6} xxl={4}>
          <Form.Item
            valuePropName="checked"
            name="online_orders_pickup_available"
            label="Online Orders Pickup Available?"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
          </Form.Item>
        </Col>
        <Col xs={12} md={6} xxl={4}>
          <Form.Item valuePropName="checked" name="is_fulfilment_centre" label="Is Node a Fulfillment Center?">
            <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
          </Form.Item>
        </Col>
        <Col xs={12} md={6} xxl={4}>
          <Form.Item valuePropName="checked" name="excepts_transfer_order" label="Accept Transfer Order?">
            <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
          </Form.Item>
        </Col>
        <Col xs={12} md={6} xxl={4}>
          <Form.Item valuePropName="checked" name="expects_return" label="Accept Return Order?">
            <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
          </Form.Item>
        </Col>
        <Col xs={12} md={6} xxl={4}>
          <Form.Item valuePropName="checked" name="allow_gift_wrap" label="Allows Gift Wrap?">
            <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
          </Form.Item>
        </Col>

        <Col xs={12} md={6} xxl={4}>
          <Form.Item valuePropName="checked" name="is_local_delivery_available" label="Is Local Delivery Available?">
            <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
          </Form.Item>
        </Col>
        <Col xs={12} md={6} xxl={4}>
          <Form.Item valuePropName="checked" name="is_local_return_available" label="Is Local Returns Available?">
            <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
          </Form.Item>
        </Col>
      </Row>
      <section className="my-2">
        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.online_orders_pickup_available !== curr.online_orders_pickup_available}
        >
          {form => {
            const { online_orders_pickup_available } = form.getFieldsValue();
            return online_orders_pickup_available ? (
              <Card className="my-2">
                <div className="mb-4">
                  <span className="text-lg font-medium">Pick Up Configuration</span>
                </div>
                <Form.List
                  name="pickup_postal_codes"
                  rules={[
                    {
                      validator: async (_, pickup_postal_codes) => {
                        if (!pickup_postal_codes || pickup_postal_codes.length < 1) {
                          return Promise.reject(new Error('At least 1 PickUp Post Code is Required is required'));
                        }
                      }
                    }
                  ]}
                >
                  {(fields, { add, remove: postalCodeRemove }, { errors }) => {
                    return (
                      <React.Fragment>
                        <Form.ErrorList className="text-[#ff4d4f]" errors={errors} />

                        {fields.map(({ key, name, ...restField }, index) => {
                          return (
                            <div key={index}>
                              <Row gutter={12} className="flex">
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'postcode']}
                                    rules={[
                                      { required: true, message: "This field can't be empty" },
                                      validationHelpers.getNumericValidatorForInput({})
                                    ]}
                                    label="Post Code"
                                  >
                                    <Input className="w-full" size="large" placeholder="Enter Post Code"></Input>
                                  </Form.Item>
                                </Col>
                                <Col xs={12} md={4}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'is_active']}
                                    rules={[{ required: true, message: 'Status is required' }]}
                                    label="Post Code Status"
                                    valuePropName="checked"
                                  >
                                    <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
                                  </Form.Item>
                                </Col>
                                <Col xs={12} md={6} className="mt-8">
                                  <Button type="link" className="text-red-600" onClick={() => postalCodeRemove(name)}>
                                    <DeleteOutlined className="text-red-500 text-xl" title="Delete Package" />
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                        <Form.Item>
                          <Row justify={'start'}>
                            <Col xs={24} md={4}>
                              <Button onClick={() => add({ postcode: '', is_active: true })} icon={<PlusOutlined />}>
                                <BoldButtonLabel labelText="Add Pick Up Post Code" />
                              </Button>
                            </Col>
                          </Row>
                        </Form.Item>
                      </React.Fragment>
                    );
                  }}
                </Form.List>

                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={['pickup_config', 'pickup_policy', 'is_cod_available']}
                      label="Is Pick Up COD Available?"
                      valuePropName="checked"
                    >
                      <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  noStyle
                  shouldUpdate={(prev, curr) =>
                    prev?.pickup_config?.pickup_policy?.is_cod_available !==
                    curr?.pickup_config?.pickup_policy?.is_cod_available
                  }
                >
                  {form => {
                    const { pickup_config } = form.getFieldsValue();
                    return (
                      pickup_config?.pickup_policy?.is_cod_available && (
                        <>
                          <Typography.Title level={5} className="text-[#2e2a5b]">
                            Pick Up COD Limit :
                          </Typography.Title>
                          <Row gutter={12}>
                            <Col xs={12} md={6}>
                              <Form.Item
                                label="Currency"
                                name="currency_PickUp_CODlimit"
                                rules={[{ required: true, message: "This field can't be empty" }]}
                              >
                                <Select placeholder="Select an option" size="large" options={newcurrencyList}></Select>
                              </Form.Item>
                            </Col>

                            <Col xs={12} md={6}>
                              <Form.Item
                                name={['pickUpConfigAmount']}
                                rules={[
                                  { required: true, message: "This field can't be empty" },
                                  validationHelpers.getNumericValidatorForInput({})
                                ]}
                                label="Amount"
                              >
                                <Input className="w-full" size="large" placeholder="Enter Amount"></Input>
                              </Form.Item>
                            </Col>

                            <Col xs={12} md={6}>
                              <Form.Item
                                name={['pickUpConfigFraction']}
                                rules={[
                                  { required: true, message: "This field can't be empty" },
                                  validationHelpers.getNumericValidatorForInput({})
                                ]}
                                label="Fraction"
                              >
                                <Input className="w-full" size="large" placeholder="Enter Fraction"></Input>
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )
                    );
                  }}
                </Form.Item>
              </Card>
            ) : null;
          }}
        </Form.Item>
      </section>
      <section className="my-2">
        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.is_local_delivery_available !== curr.is_local_delivery_available}
        >
          {form => {
            const { is_local_delivery_available } = form.getFieldsValue();

            return is_local_delivery_available ? (
              <Card className="mt-4">
                <div className="mb-4">
                  <span className="text-lg font-medium">Local Delivery Configuration</span>
                </div>
                <Form.List name="local_delivery_postal_codes">
                  {(fields, { add, remove }, { errors }) => {
                    return (
                      <React.Fragment>
                        {fields.map(({ key, name, ...restField }, index) => {
                          return (
                            <Row gutter={12} className="flex">
                              <Col xs={12} md={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'postcode']}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    validationHelpers.getNumericValidatorForInput({})
                                  ]}
                                  label="Post Code"
                                >
                                  <Input className="w-full" size="large" placeholder="Enter Post Code"></Input>
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={2} className="flex justify-center">
                                <Form.Item
                                  name={[name, 'is_active']}
                                  rules={[{ required: true, message: 'Post Code Status is required' }]}
                                  label="Is Active?"
                                  valuePropName="checked"
                                >
                                  <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={2}>
                                <div className="mx-4 h-full flex justify-center items-center">
                                  <DeleteOutlined
                                    className="text-red-500 text-xl cursor-pointer"
                                    role="button"
                                    title="Remove Post Code"
                                    onClick={() => remove(name)}
                                  ></DeleteOutlined>
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                        <Form.Item>
                          <Row justify={'start'}>
                            <Col xs={24} md={4}>
                              <Button onClick={() => add({ postcode: '', is_active: true })} icon={<PlusOutlined />}>
                                <BoldButtonLabel labelText="Add Local Delivery Post Code" />
                              </Button>
                            </Col>
                          </Row>
                        </Form.Item>
                      </React.Fragment>
                    );
                  }}
                </Form.List>

                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={'local_delivery_lead_time_in_minutes'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getNumericValidatorForInput({})
                      ]}
                      label="Local Delivery Lead Time (Mins)"
                    >
                      <Input className="w-full" size="large" placeholder="Local Delivery Lead Time in Minutes"></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={'is_cod_available'}
                      // rules={[{ required: true, message: 'Field is required' }]}
                      label="Is COD Available?"
                      valuePropName="checked"
                    >
                      <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.is_cod_available !== curr.is_cod_available}>
                  {form => {
                    const { is_cod_available } = form.getFieldsValue();
                    return (
                      is_cod_available && (
                        <>
                          <Typography.Title level={5} className="text-[#2e2a5b]">
                            COD Limit :
                          </Typography.Title>
                          <Row gutter={12}>
                            <Col xs={12} md={6}>
                              <Form.Item
                                label="Currency"
                                name="currency_local_CODlimit"
                                rules={[{ required: true, message: "This field can't be empty" }]}
                              >
                                <Select placeholder="Select an option" size="large" options={newcurrencyList}></Select>
                              </Form.Item>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Item
                                name={'amount'}
                                rules={[
                                  { required: true, message: "This field can't be empty" },
                                  validationHelpers.getNumericValidatorForInput({})
                                ]}
                                label="Amount"
                              >
                                <Input className="w-full" size="large" placeholder="Enter Amount"></Input>
                              </Form.Item>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Item
                                name={'fraction'}
                                rules={[
                                  { required: true, message: "This field can't be empty" },
                                  validationHelpers.getNumericValidatorForInput({})
                                ]}
                                label="Fraction"
                              >
                                <Input className="w-full" size="large" placeholder="Fraction"></Input>
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )
                    );
                  }}
                </Form.Item>

                <section>
                  <Typography.Title level={5} className="text-[#2e2a5b]">
                    Shipping Charges :
                  </Typography.Title>
                  <Row gutter={12}>
                    <Col xs={24} md={8} lg={6}>
                      <Form.Item
                        name={'name'}
                        label="Charge Name"
                        rules={[{ required: true, message: 'Field is required' }]}
                      >
                        <Input size="large" placeholder="Charge Name"></Input>
                      </Form.Item>
                    </Col>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, curr) => prev?.shipping_charge_type !== curr.shipping_charge_type}
                    >
                      {form => {
                        const { shipping_charge_type } = form.getFieldsValue();
                        return (
                          shipping_charge_type !== 'FREE' && (
                            <Col xs={12} md={6}>
                              <Form.Item
                                label="Currency"
                                name="currency_CODlimit"
                                rules={[{ required: true, message: "This field can't be empty" }]}
                              >
                                <Select placeholder="Select an option" size="large" options={newcurrencyList}></Select>
                              </Form.Item>
                            </Col>
                          )
                        );
                      }}
                    </Form.Item>
                  </Row>
                  <Row gutter={12}>
                    <Col xs={24} md={8} lg={6}>
                      <Form.Item
                        name={'shipping_charge_type'}
                        label="Shipping Charge Type"
                        rules={[{ required: true, message: 'Field is required' }]}
                      >
                        <Select
                          allowClear
                          size="large"
                          placeholder="Select type"
                          options={shippingChargeTypes}
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </section>

                <Form.Item
                  noStyle
                  shouldUpdate={(prev, curr) => prev?.shipping_charge_type !== curr.shipping_charge_type}
                >
                  {form => {
                    const { shipping_charge_type } = form.getFieldsValue();
                    return (
                      <div>
                        {shipping_charge_type === 'FLAT' && (
                          <>
                            <Typography.Title level={5} className="text-[#2e2a5b]">
                              Flat charge configuration:
                            </Typography.Title>
                            <Row gutter={12}>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[
                                    'local_delivery_policy',
                                    'local_delivery_shipping_charge',
                                    'flat_charge_config',
                                    'amount'
                                  ]}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    validationHelpers.getNumericValidatorForInput({})
                                  ]}
                                  label="Flat charge amount"
                                >
                                  <Input className="w-full" size="large" placeholder="Enter Flat charge amount"></Input>
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[
                                    'local_delivery_policy',
                                    'local_delivery_shipping_charge',
                                    'flat_charge_config',
                                    'fraction'
                                  ]}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    validationHelpers.getNumericValidatorForInput({})
                                  ]}
                                  label="Flat charge Fraction"
                                >
                                  <Input
                                    className="w-full"
                                    size="large"
                                    placeholder="Enter Flat charge fraction"
                                  ></Input>
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        )}
                        {shipping_charge_type === 'BASE_WEIGHT' && (
                          <>
                            <Typography.Title level={5} className="text-[#2e2a5b]">
                              Base weight charge configuration :
                            </Typography.Title>
                            <Row gutter={12}>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[
                                    'local_delivery_policy',
                                    'local_delivery_shipping_charge',
                                    'base_weight_charge_config',
                                    'base_weight',
                                    'base_weight_in_kg'
                                  ]}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    validationHelpers.getNumericValidatorForInput({})
                                  ]}
                                  label="Base weight in KGs"
                                >
                                  <Input className="w-full" size="large" placeholder="Enter Base weight"></Input>
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[
                                    'local_delivery_policy',
                                    'local_delivery_shipping_charge',
                                    'base_weight_charge_config',
                                    'base_weight',
                                    'cost',
                                    'amount'
                                  ]}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    validationHelpers.getNumericValidatorForInput({})
                                  ]}
                                  label="Amount"
                                >
                                  <Input className="w-full" size="large" placeholder="Enter Base weight amount"></Input>
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[
                                    'local_delivery_policy',
                                    'local_delivery_shipping_charge',
                                    'base_weight_charge_config',
                                    'base_weight',
                                    'cost',
                                    'fraction'
                                  ]}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    validationHelpers.getNumericValidatorForInput({})
                                  ]}
                                  label="Fraction"
                                >
                                  <Input className="w-full" size="large" placeholder="Enter Fraction"></Input>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={12}>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[
                                    'local_delivery_policy',
                                    'local_delivery_shipping_charge',
                                    'base_weight_charge_config',
                                    'additional_weight',
                                    'additional_weight_in_kg'
                                  ]}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    validationHelpers.getNumericValidatorForInput({})
                                  ]}
                                  label="Additional weight in KG"
                                >
                                  <Input
                                    className="w-full"
                                    size="large"
                                    placeholder="Enter Additional weight in KGt"
                                  ></Input>
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[
                                    'local_delivery_policy',
                                    'local_delivery_shipping_charge',
                                    'base_weight_charge_config',
                                    'additional_weight',
                                    'cost',
                                    'amount'
                                  ]}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    validationHelpers.getNumericValidatorForInput({})
                                  ]}
                                  label="Amount"
                                >
                                  <Input
                                    className="w-full"
                                    size="large"
                                    placeholder="Enter Base Additional weight amount"
                                  ></Input>
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[
                                    'local_delivery_policy',
                                    'local_delivery_shipping_charge',
                                    'base_weight_charge_config',
                                    'additional_weight',
                                    'cost',
                                    'fraction'
                                  ]}
                                  rules={[
                                    { required: true, message: "This field can't be empty" },
                                    validationHelpers.getNumericValidatorForInput({})
                                  ]}
                                  label="Fraction"
                                >
                                  <Input className="w-full" size="large" placeholder="Enter Fraction"></Input>
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        )}
                        {shipping_charge_type === 'SLAB_WEIGHT' && (
                          <>
                            <Typography.Title level={5} className="text-[#2e2a5b]">
                              Slab weight charge configuration :
                            </Typography.Title>
                            <Form.List name="slab_weight_slab_charge_config">
                              {(fields, { add, remove }, { errors }) => {
                                return (
                                  <React.Fragment>
                                    {fields.map(({ key, name, ...restField }, index) => {
                                      return (
                                        <Card className="mt-2">
                                          <div className="flex flex-row-reverse justify-start">
                                            <DeleteOutlined
                                              className="text-red-500 text-xl"
                                              title="Delete condition"
                                              onClick={() => remove(name)}
                                            />
                                          </div>
                                          <Row gutter={12} className="flex">
                                            <Col xs={12} md={6}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, 'min_weight_in_kg']}
                                                rules={[
                                                  { required: true, message: "This field can't be empty" },
                                                  validationHelpers.getNumericValidatorForInput({})
                                                ]}
                                                label="Minimum Weight in KGs"
                                              >
                                                <Input
                                                  className="w-full"
                                                  size="large"
                                                  placeholder="Enter Minimum Weight in KGs"
                                                ></Input>
                                              </Form.Item>
                                            </Col>
                                            <Col xs={12} md={6}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, 'max_weight_in_kg']}
                                                rules={[
                                                  { required: true, message: "This field can't be empty" },
                                                  validationHelpers.getNumericValidatorForInput({})
                                                ]}
                                                label="Maximum Weight in KGs"
                                              >
                                                <Input
                                                  className="w-full"
                                                  size="large"
                                                  placeholder="Enter Maximum Weight in KGs"
                                                ></Input>
                                              </Form.Item>
                                            </Col>
                                            <Col xs={12} md={6}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, 'cost', 'amount']}
                                                rules={[
                                                  { required: true, message: "This field can't be empty" },
                                                  validationHelpers.getNumericValidatorForInput({})
                                                ]}
                                                label="Amount Charged"
                                              >
                                                <Input
                                                  className="w-full"
                                                  size="large"
                                                  placeholder="Enter Amount Charged"
                                                ></Input>
                                              </Form.Item>
                                            </Col>
                                            <Col xs={12} md={6}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, 'cost', 'fraction']}
                                                rules={[
                                                  { required: true, message: "This field can't be empty" },
                                                  validationHelpers.getNumericValidatorForInput({})
                                                ]}
                                                label="Fraction"
                                              >
                                                <Input
                                                  className="w-full"
                                                  size="large"
                                                  placeholder="Enter Fraction"
                                                ></Input>
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                        </Card>
                                      );
                                    })}
                                    <Form.Item>
                                      <Row justify={'start'} className="mt-2">
                                        <Col xs={24} md={4}>
                                          <Button onClick={() => add()} icon={<PlusOutlined />}>
                                            <BoldButtonLabel labelText="Add Slab Details" />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Form.Item>
                                  </React.Fragment>
                                );
                              }}
                            </Form.List>
                          </>
                        )}
                        {shipping_charge_type === 'ORDER_PRICE' && (
                          <>
                            <Typography.Title level={5} className="text-[#2e2a5b]">
                              Order price charge configuration :
                            </Typography.Title>
                            <Form.List name="order_price_slab_charge_config">
                              {(fields, { add, remove }, { errors: orderDetailsError }) => {
                                return (
                                  <React.Fragment>
                                    {fields.map(({ key, name, ...restField }, index) => {
                                      return (
                                        <Card className="mb-2">
                                          <div className="flex flex-row-reverse justify-start">
                                            <DeleteOutlined
                                              className="text-red-500 text-xl"
                                              title="Delete condition"
                                              onClick={() => remove(name)}
                                            />
                                          </div>
                                          <Row gutter={12} className="flex">
                                            <Col xs={12} md={6}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, 'min_price']}
                                                rules={[
                                                  { required: true, message: "This field can't be empty" },
                                                  validationHelpers.getNumericValidatorForInput({})
                                                ]}
                                                label="Minimum Price"
                                              >
                                                <Input
                                                  className="w-full"
                                                  size="large"
                                                  placeholder="Minimum Price"
                                                ></Input>
                                              </Form.Item>
                                            </Col>
                                            <Col xs={12} md={6}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, 'max_price']}
                                                rules={[
                                                  { required: true, message: "This field can't be empty" },
                                                  validationHelpers.getNumericValidatorForInput({})
                                                ]}
                                                label="Maximum Price"
                                              >
                                                <Input
                                                  className="w-full"
                                                  size="large"
                                                  placeholder="Maximum Price"
                                                ></Input>
                                              </Form.Item>
                                            </Col>
                                            <Col xs={12} md={6}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, 'cost', 'amount']}
                                                rules={[
                                                  { required: true, message: "This field can't be empty" },
                                                  validationHelpers.getNumericValidatorForInput({})
                                                ]}
                                                label="Amount Charged"
                                              >
                                                <Input
                                                  className="w-full"
                                                  size="large"
                                                  placeholder="Amount Charged"
                                                ></Input>
                                              </Form.Item>
                                            </Col>
                                            <Col xs={12} md={6}>
                                              <Form.Item
                                                {...restField}
                                                name={[name, 'cost', 'fraction']}
                                                rules={[
                                                  { required: true, message: "This field can't be empty" },
                                                  validationHelpers.getNumericValidatorForInput({})
                                                ]}
                                                label="Enter Fraction"
                                              >
                                                <Input
                                                  className="w-full"
                                                  size="large"
                                                  placeholder="Enter Fraction"
                                                ></Input>
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                          <Form.ErrorList className="text-[#ff4d4f]" errors={orderDetailsError} />
                                        </Card>
                                      );
                                    })}
                                    <Form.Item>
                                      <Row justify={'start'} className="mt-2">
                                        <Col xs={24} md={4}>
                                          <Button onClick={() => add()} icon={<PlusOutlined />}>
                                            <BoldButtonLabel labelText="Add Order Details" />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Form.Item>
                                  </React.Fragment>
                                );
                              }}
                            </Form.List>
                          </>
                        )}
                      </div>
                    );
                  }}
                </Form.Item>

                <Typography.Title level={5} className="text-[#2e2a5b]">
                  Volumetric Constraint :
                </Typography.Title>
                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={'min_volume_in_ccm'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getNumericValidatorForInput({})
                      ]}
                      label="Min Volume in CM"
                    >
                      <Input className="w-full" size="large" placeholder="Min Volume in CM Required"></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={'max_volume_in_ccm'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getNumericValidatorForInput({})
                      ]}
                      label="Max Volume in CM"
                    >
                      <Input className="w-full" size="large" placeholder="Max Volume in CM Required"></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={'min_weight_in_kg'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getNumericValidatorForInput({})
                      ]}
                      label="Min Weight in KGs"
                    >
                      <Input className="w-full" size="large" placeholder="Min Weight in KGs Required"></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={'max_weight_in_kg'}
                      rules={[
                        { required: true, message: "This field can't be empty" },
                        validationHelpers.getNumericValidatorForInput({})
                      ]}
                      label="Max Weight in KGs"
                    >
                      <Input className="w-full" size="large" placeholder="Max Weight in KGs Required"></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            ) : null;
          }}
        </Form.Item>
      </section>
      <section className="mt-4">
        <Row>
          <Col xs={24} md={12}>
            <div className="mb-2">
              <span className="text-lg font-medium">Delivery Methods</span>
            </div>
            <Form.Item
              name="delivery_methods"
              rules={[{ required: true, message: 'Select atleast one of the options' }]}
            >
              <Checkbox.Group
                options={[
                  { label: 'Home Delivery', value: 'HOME_DELIVERY' },
                  { label: 'Click and Collect', value: 'CLICK_AND_COLLECT' },
                  { label: 'Local Delivery', value: 'LOCAL_DELIVERY' }
                ]}
              ></Checkbox.Group>
            </Form.Item>
          </Col>{' '}
          <Col xs={24} md={12}>
            <div className="mb-2">
              <span className="text-lg font-medium">Node Roles</span>
            </div>
            <Form.Item name="roles" rules={[{ required: true, message: 'Select atleast one of the options' }]}>
              <Checkbox.Group
                options={[
                  { label: 'Storage', value: 'STORAGE' },
                  { label: 'Sell', value: 'SELLS' },
                  { label: 'Purchase', value: 'PURCHASES' },
                  { label: 'Ship', value: 'SHIPS' },
                  { label: 'Pickup', value: 'PICK_UP' },
                  { label: 'Transfer', value: 'TRANSFER' }
                ]}
              ></Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
      </section>
      <section>
        <div>
          <div className="mb-4">
            <span className="text-lg font-medium">Work Week</span>
          </div>

          <Form.List
            name="work_week"
            rules={[
              {
                validator: async (_, workWeek) => {
                  if (!workWeek || workWeek.length < 1) {
                    return Promise.reject(new Error('Please enter work week info'));
                  }
                }
              }
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <React.Fragment>
                <Row gutter={[12, 12]}>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Col xs={24} xl={12} key={key}>
                      <Card className="my-2" key={key}>
                        <div className="relative">
                          <Row>
                            <Col xs={24}>
                              <Divider orientation="left" plain>
                                <span className="text-lg">Day - {index + 1} </span>
                              </Divider>
                            </Col>
                          </Row>

                          <Row gutter={12}>
                            <Col xs={12} md={8}>
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) => {
                                  return true;
                                }}
                              >
                                {form => {
                                  const selectedDays = (form.getFieldsValue().work_week || []).map((workWeek: any) => {
                                    if (
                                      workWeek?.day_of_the_week !==
                                      form.getFieldsValue().work_week[index]?.day_of_the_week
                                    )
                                      return workWeek?.day_of_the_week;
                                  });
                                  //!Remove current selected Day

                                  const filteredDays = DAYS_OF_THE_WEEK.filter(
                                    day => !selectedDays.includes(day.value)
                                  );

                                  return (
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'day_of_the_week']}
                                      label="Day of the week"
                                      rules={[{ required: true, message: 'Day is required' }]}
                                    >
                                      <Select size="large" placeholder="Select Day">
                                        {filteredDays.map(({ label, value }) => (
                                          <Select.Option value={value} key={value}>
                                            {label}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  );
                                }}
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.Item
                                {...restField}
                                name={[name, 'shift_time']}
                                label="Shift time"
                                rules={[{ required: true, message: 'Shift time is required' }]}
                              >
                                <TimePicker.RangePicker format={'H:mm A'} className="w-full" size="large" />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={8}>
                              <Form.Item
                                {...restField}
                                name={[name, 'cut_off_time']}
                                rules={[{ required: true, message: 'Shift cut off time is required' }]}
                                label="Shift Cut Off Time"
                              >
                                <TimePicker format={'H:mm A'} className="w-full" size="large" />
                              </Form.Item>
                            </Col>

                            <Col xs={24}>
                              <Form.List
                                name={[name, 'capacities']}
                                rules={[
                                  {
                                    validator: async (_, capacities) => {
                                      if (!capacities || capacities.length < 1) {
                                        return Promise.reject(new Error('At least 1 capacity info is required'));
                                      }
                                    }
                                  }
                                ]}
                              >
                                {(fields, { add: capacityAdd, remove: capacityRemove }, { errors: capacityErrors }) => (
                                  <>
                                    {fields.map(({ key: capactiyKey, name: capacityName, ...restField }, index) => (
                                      <React.Fragment key={key}>
                                        <Row gutter={6} justify={'space-between'}>
                                          <Col xs={12}>
                                            <Divider className="m-0 p-0" orientation="left" plain>
                                              <span className="text-sm">Capacity - {index + 1} </span>
                                            </Divider>
                                          </Col>
                                          <Col>
                                            <div className="mx-4 h-full flex justify-center items-center">
                                              <DeleteOutlined
                                                className="text-red-500 text-xl cursor-pointer"
                                                role="button"
                                                title="Remove capacity"
                                                onClick={() => capacityRemove(capacityName)}
                                              ></DeleteOutlined>
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row gutter={6}>
                                          <Col xs={24} md={12}>
                                            <Form.Item
                                              {...restField}
                                              name={[capacityName, 'capacity_type']}
                                              rules={[{ required: true, message: 'Capacity type is required' }]}
                                              label="Capacity Type"
                                            >
                                              <Select
                                                size="large"
                                                placeholder={'Select capacity type'}
                                                options={[
                                                  { label: 'Ship Ready', value: 'SHIP_READY' },
                                                  { label: 'Transfer Out', value: 'TRANSFER_OUT' },
                                                  { label: 'Transfer In', value: 'TRANSFER_IN' }
                                                ]}
                                              ></Select>
                                            </Form.Item>
                                          </Col>
                                          <Col xs={24} md={12}>
                                            <Form.Item
                                              {...restField}
                                              name={[capacityName, 'capacity_uom']}
                                              rules={[{ required: true, message: 'Capacity UOM is required' }]}
                                              label="Capacity UOM"
                                            >
                                              <Select
                                                size="large"
                                                showSearch
                                                placeholder="Select capacity UOM"
                                                options={[
                                                  { label: 'Line', value: 'LINE' },
                                                  { label: 'Unit', value: 'UNIT' },
                                                  { label: 'Release', value: 'RELEASE' },
                                                  { label: 'Unit Weight', value: 'UNIT_WEIGHT' }
                                                ]}
                                              />
                                            </Form.Item>
                                          </Col>

                                          <Col xs={24}>
                                            <Form.List
                                              name={[capacityName, 'slots']}
                                              rules={[
                                                {
                                                  validator: async (_, slots) => {
                                                    if (!slots || slots.length < 1) {
                                                      return Promise.reject(
                                                        new Error('At least 1 slot  info is required')
                                                      );
                                                    }
                                                  }
                                                }
                                              ]}
                                            >
                                              {(fields, { add: addSlots, remove: removeSlots }, { errors }) => (
                                                <>
                                                  {fields.map(
                                                    ({ key: slotsKey, name: slotsName, ...restField }, index) => (
                                                      <React.Fragment key={slotsKey}>
                                                        <Row justify={'space-between'}>
                                                          <Col xs={12}>
                                                            <Divider orientation="left" plain>
                                                              <span className="text-sm">Slot - {index + 1} </span>
                                                            </Divider>
                                                          </Col>
                                                          <Col>
                                                            <div className="mx-4 h-full flex justify-center items-center">
                                                              <DeleteOutlined
                                                                className="text-red-500 text-xl"
                                                                title="Delete Slot"
                                                                onClick={() => removeSlots(slotsName)}
                                                              />
                                                            </div>
                                                          </Col>
                                                        </Row>
                                                        <Form.Item
                                                          shouldUpdate={(prevValues, currentValues) => {
                                                            return (
                                                              prevValues?.work_week?.[name]?.capacities?.[capacityName]
                                                                ?.capacity_uom !==
                                                              currentValues?.work_week?.[name]?.capacities?.[
                                                                capacityName
                                                              ]?.capacity_uom
                                                            );
                                                          }}
                                                          noStyle
                                                        >
                                                          {({ getFieldValue }) => {
                                                            const capacityUOM = getFieldValue([
                                                              'work_week',
                                                              name,
                                                              'capacities',
                                                              capacityName,
                                                              'capacity_uom'
                                                            ]);

                                                            return capacityUOM === 'UNIT_WEIGHT' ? (
                                                              <Row gutter={12}>
                                                                <Col xs={24} md={12}>
                                                                  <Form.Item
                                                                    name={[slotsName, 'min_weight_in_kg']}
                                                                    label="Minimum Weight in Kg"
                                                                    rules={[
                                                                      {
                                                                        required: true,
                                                                        message: 'This field is required'
                                                                      },
                                                                      {
                                                                        type: 'number',
                                                                        min: 0,
                                                                        message: 'Minimum weight cannot be less than 0'
                                                                      }
                                                                    ]}
                                                                  >
                                                                    <InputNumber
                                                                      className="w-full"
                                                                      size="large"
                                                                      min={0}
                                                                      placeholder="Enter Minimum Weight in Kg"
                                                                    />
                                                                  </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={12}>
                                                                  <Form.Item
                                                                    name={[slotsName, 'max_weight_in_kg']}
                                                                    label="Maximum Weight in Kg"
                                                                    rules={[
                                                                      {
                                                                        required: true,
                                                                        message: 'This field is required'
                                                                      },
                                                                      {
                                                                        type: 'number',
                                                                        min: 0,
                                                                        message: 'Maximum weight cannot be less than 0'
                                                                      },
                                                                      ({ getFieldValue }) => ({
                                                                        validator(_, value) {
                                                                          const minWeight = getFieldValue([
                                                                            'work_week',
                                                                            name,
                                                                            'capacities',
                                                                            capacityName,
                                                                            'slots',
                                                                            slotsName,
                                                                            'min_weight_in_kg'
                                                                          ]);
                                                                          if (value < minWeight) {
                                                                            return Promise.reject(
                                                                              new Error(
                                                                                'Maximum weight must be greater than or equal to minimum weight'
                                                                              )
                                                                            );
                                                                          }
                                                                          return Promise.resolve();
                                                                        }
                                                                      })
                                                                    ]}
                                                                  >
                                                                    <InputNumber
                                                                      className="w-full"
                                                                      size="large"
                                                                      min={0}
                                                                      placeholder="Enter Maximum Weight in Kg"
                                                                    />
                                                                  </Form.Item>
                                                                </Col>
                                                              </Row>
                                                            ) : null;
                                                          }}
                                                        </Form.Item>

                                                        <Row gutter={12}>
                                                          <Col xs={24} md={12}>
                                                            <Form.Item
                                                              {...restField}
                                                              name={[slotsName, 'capacity']}
                                                              rules={[
                                                                { required: true, message: 'Capacity is required' }
                                                              ]}
                                                              label="Capacity"
                                                            >
                                                              <InputNumber
                                                                className="w-full"
                                                                size={'large'}
                                                                min={0}
                                                                placeholder="Enter capacity"
                                                              />
                                                            </Form.Item>
                                                          </Col>

                                                          <Col xs={24} md={12}>
                                                            <Form.Item
                                                              {...restField}
                                                              name={[
                                                                slotsName,
                                                                'default_required_capacity_for_uom_line'
                                                              ]}
                                                              // rules={[{ required: true, message: 'Capacity is required' }]}
                                                              label="Default Capacity for UOM Line"
                                                            >
                                                              <InputNumber
                                                                className="w-full"
                                                                size={'large'}
                                                                min={0}
                                                                placeholder="Enter capacity"
                                                              />
                                                            </Form.Item>
                                                          </Col>

                                                          <Col xs={24}>
                                                            <Form.Item
                                                              {...restField}
                                                              name={[slotsName, 'slot_time']}
                                                              label="Slot Time"
                                                              rules={[
                                                                { required: true, message: 'Slot time is required' }
                                                              ]}
                                                            >
                                                              <TimePicker.RangePicker
                                                                format={'H:mm A'}
                                                                className="w-full"
                                                                size="large"
                                                              />
                                                            </Form.Item>
                                                          </Col>
                                                        </Row>
                                                      </React.Fragment>
                                                    )
                                                  )}
                                                  <Col xs={24}>
                                                    <Form.Item>
                                                      <Button
                                                        onClick={() => addSlots()}
                                                        size="small"
                                                        type="link"
                                                        icon={<PlusOutlined />}
                                                      >
                                                        <BoldButtonLabel labelText="Add Slot" />
                                                      </Button>
                                                    </Form.Item>{' '}
                                                  </Col>
                                                  <Form.ErrorList className="text-[#ff4d4f]" errors={errors} />
                                                </>
                                              )}
                                            </Form.List>
                                          </Col>
                                        </Row>
                                      </React.Fragment>
                                    ))}
                                    <Form.ErrorList className="text-[#ff4d4f]" errors={capacityErrors} />
                                    <Form.Item>
                                      <Row justify={'start'}>
                                        <Col xs={24} md={4}>
                                          <Button
                                            disabled={fields.length > 1}
                                            onClick={() => capacityAdd()}
                                            icon={<PlusOutlined />}
                                            size="small"
                                          >
                                            <BoldButtonLabel labelText="Add Capacity" />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                            </Col>
                            <div className="absolute top-0 right-0  flex justify-center items-center">
                              <DeleteOutlined
                                className="text-red-500 text-xl"
                                title="Delete Work Day"
                                onClick={() => remove(name)}
                              />
                            </div>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>{' '}
                <Form.ErrorList className="text-[#ff4d4f]" errors={errors} />
                <Form.Item>
                  <Row justify={'start'}>
                    <Col xs={24} md={4}>
                      <Button disabled={fields.length > 6} onClick={() => add()} icon={<PlusOutlined />}>
                        <BoldButtonLabel labelText="Add Work Day" />
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </React.Fragment>
            )}
          </Form.List>
        </div>
        <div>
          <div className="mb-4">
            <span className="text-lg font-medium">Holidays</span>
          </div>

          <Form.List name="holidays">
            {(fields, { add, remove }, { errors }) => (
              <>
                <Row>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <React.Fragment key={index}>
                      <Col xs={24} md={12}>
                        <Row>
                          <Col xs={12}>
                            <Divider orientation="left" plain>
                              <span className="text-lg">Holiday - {index + 1} </span>
                            </Divider>
                          </Col>
                        </Row>

                        <Row gutter={12}>
                          <Col xs={12} md={10}>
                            <Form.Item
                              {...restField}
                              name={[name, 'date']}
                              rules={[{ required: true, message: 'Holiday date is required' }]}
                              label="Holiday Date"
                            >
                              <DatePicker.RangePicker className="w-full" size="large" />
                            </Form.Item>
                          </Col>
                          <Col xs={12} md={8}>
                            <Form.Item
                              {...restField}
                              name={[name, 'time']}
                              rules={[{ required: true, message: 'Holiday time is required' }]}
                              label="Time"
                            >
                              <TimePicker.RangePicker format={'H:mm A'} className="w-full" size="large" />
                            </Form.Item>
                          </Col>

                          <Col xs={12} md={8}>
                            <Form.Item
                              {...restField}
                              name={[name, 'reason']}
                              rules={[{ required: true, message: 'Reason is required' }]}
                              label="Reason"
                            >
                              <Input className="w-full" size="large" placeholder="..."></Input>
                            </Form.Item>
                          </Col>

                          <div className="ml-4 flex justify-center items-center">
                            <DeleteOutlined
                              className="text-red-500 text-xl"
                              title="Delete Holiday"
                              onClick={() => remove(name)}
                            />
                          </div>
                        </Row>
                      </Col>
                    </React.Fragment>
                  ))}{' '}
                </Row>
                <Form.Item>
                  <Row justify={'start'}>
                    <Col xs={24} md={4}>
                      <Button onClick={() => add()} icon={<PlusOutlined />}>
                        <BoldButtonLabel labelText="Add Holiday" />
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
        <div>
          <div className="mb-4">
            <span className="text-lg font-medium">Services</span>
          </div>

          <Form.List
            name="services"
            rules={[
              {
                validator: async (_, services) => {
                  if (!services || services.length < 1) {
                    return Promise.reject(new Error('At least 1 service is required'));
                  }
                }
              }
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                <Row>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <React.Fragment key={index}>
                      <Col xs={24} md={6}>
                        <Row gutter={12}>
                          <Col xs={24} md={20}>
                            <Form.Item
                              {...restField}
                              name={[name, 'service']}
                              label={`Service #${index + 1}`}
                              rules={[{ required: true, message: 'Service is required' }]}
                            >
                              <Input className="w-full" size="large" placeholder="..."></Input>
                            </Form.Item>
                          </Col>

                          <div className="flex justify-center items-center">
                            <DeleteOutlined
                              className="text-red-500 text-xl"
                              title="Delete Service"
                              onClick={() => remove(name)}
                            />
                          </div>
                        </Row>
                      </Col>
                    </React.Fragment>
                  ))}
                  <Form.ErrorList className="text-[#ff4d4f]" errors={errors} />
                </Row>
                <Form.Item>
                  <Row justify={'start'}>
                    <Col xs={24} md={4}>
                      <Button onClick={() => add()} icon={<PlusOutlined />}>
                        <BoldButtonLabel labelText="Add a Service" />
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      </section>{' '}
      <section className="my-2">
        <div className="mb-2">
          <span className="text-lg font-medium">Packages</span>
        </div>
        <Form.Item name={'is_package_provided'} valuePropName="checked">
          <Checkbox>Is Package Provided ?</Checkbox>
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prev, curr) => prev.is_package_provided !== curr.is_package_provided}>
          {form => {
            const { is_package_provided } = form.getFieldsValue();
            return is_package_provided ? (
              <Form.List name="packages">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <Row>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <React.Fragment key={index}>
                          <Col md={24}>
                            <Row>
                              <Col xs={12}>
                                <Divider orientation="left" plain>
                                  <span className="text-lg">Package - {index + 1} </span>
                                </Divider>
                              </Col>
                            </Row>

                            <Row gutter={12}>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'type']}
                                  rules={[{ required: true, message: 'Type is required' }]}
                                  label="Package Type"
                                >
                                  <Select
                                    options={[{ label: 'Envelope', value: 'ENVELOPE' }]}
                                    className="w-full"
                                    size="large"
                                    placeholder="..."
                                  ></Select>
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'name']}
                                  rules={[{ required: true, message: 'Package name is required' }]}
                                  label="Package Name"
                                >
                                  <Input className="w-full" size="large" placeholder="..."></Input>
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'description']}
                                  rules={[{ required: true, message: 'Package description is required' }]}
                                  label="Package Description"
                                >
                                  <Input.TextArea rows={1} size="large"></Input.TextArea>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Form.Item valuePropName="checked" name={[name, 'is_dimension_variable']}>
                              <Checkbox>Is Dimension Variable?</Checkbox>
                            </Form.Item>
                            <Row gutter={12}>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'length_in_mm']}
                                  label="Package Length in MM"
                                  dependencies={[['packages', name, 'is_dimension_variable']]}
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (
                                          getFieldValue(['packages', name, 'is_dimension_variable']) ||
                                          value !== undefined
                                        ) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Length is required'));
                                      }
                                    })
                                  ]}
                                >
                                  <InputNumber min={0} className="w-full" size="large" placeholder="..." />
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'width_in_mm']}
                                  label="Width Length in MM"
                                  dependencies={['packages', [name, 'is_dimension_variable']]}
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (
                                          getFieldValue(['packages', name, 'is_dimension_variable']) ||
                                          value !== undefined
                                        ) {
                                          return Promise.resolve();
                                        }
                                        console.log(nodeConfigForm.getFieldsValue());
                                        return Promise.reject(new Error('Width is required'));
                                      }
                                    })
                                  ]}
                                >
                                  <InputNumber min={0} className="w-full" size="large" placeholder="..." />
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'height_in_mm']}
                                  label="Package Height in MM"
                                  dependencies={[['packages', name, 'is_dimension_variable']]}
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (
                                          getFieldValue(['packages', name, 'is_dimension_variable']) ||
                                          value !== undefined
                                        ) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Height is required'));
                                      }
                                    })
                                  ]}
                                >
                                  <InputNumber min={0} className="w-full" size="large" placeholder="..." />
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'weight_in_gm']}
                                  label="Package Weight in GMs"
                                  dependencies={[['packages', name, 'is_dimension_variable']]}
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (
                                          getFieldValue(['packages', name, 'is_dimension_variable']) ||
                                          value !== undefined
                                        ) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Weight is required'));
                                      }
                                    })
                                  ]}
                                >
                                  <InputNumber min={0} className="w-full" size="large" placeholder="..." />
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'cost']}
                                  rules={[{ required: true, message: 'Cost is required' }]}
                                  label="Cost"
                                >
                                  <InputNumber min={0} className="w-full" size="large" placeholder="..."></InputNumber>
                                </Form.Item>
                              </Col>{' '}
                              <div className="ml-4 flex justify-center items-center">
                                <DeleteOutlined
                                  className="text-red-500 text-xl"
                                  title="Delete Package"
                                  onClick={() => remove(name)}
                                />
                              </div>
                            </Row>
                          </Col>
                        </React.Fragment>
                      ))}{' '}
                    </Row>
                    <Form.Item>
                      <Row justify={'start'}>
                        <Col xs={24} md={4}>
                          <Button onClick={() => add()} icon={<PlusOutlined />}>
                            <BoldButtonLabel labelText="Add Package" />
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            ) : null;
          }}
        </Form.Item>
      </section>
      <Row gutter={[12, 12]}>
        <Col xs={12} md={4}>
          <Button size="large" onClick={() => handleClickBack(nodeConfigForm.getFieldsValue())} block>
            <BoldButtonLabel labelText="Back"></BoldButtonLabel>
          </Button>
        </Col>
        <Col xs={12} md={4}>
          <Button
            type="primary"
            size="large"
            onClick={async () => {
              await nodeConfigForm.validateFields();
              handleClickNext(nodeConfigForm.getFieldsValue());
            }}
            block
          >
            <BoldButtonLabel labelText="Next"></BoldButtonLabel>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default NodeConfigForm;
