import { Card, Form, Row, Typography } from 'antd';
import PrimaryLayout from '../../../layouts/primary-layout';
import ManageUserForm from './manage-user-form';
import { profileService } from '../../../services';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { useLoader } from '../../../stores/use-loader';
import _ from '../../../helpers/lodash';
import { useNavigate } from 'react-router-dom';
import TenantInfo from '../../../components/TenantIdInfo';
import { useTranslation } from 'react-i18next';

interface IAddUserProps {}

const AddUser: React.FunctionComponent<IAddUserProps> = props => {
  const [createUserForm] = Form.useForm();
  const { setLoading } = useLoader();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getChannels = (channels: any) => {
    let data = channels?.map((item: any) => {
      return {
        user_group: item.user_group,
        role: item?.role,
        facility_type: item.facility_type,
        facility_code: item.facility_code
      };
    });
    return data;
  };

  const getSubscription = (subscriptions: any) => {
    let data = subscriptions.map((item: any) => {
      return {
        product: item?.product,
        group_details: item?.group_details.length > 0 ? getChannels(item.group_details) : []
      };
    });
    return data;
  };

  const handleOnSave = async () => {
    setLoading(true);
    // console.log(createUserForm.getFieldsValue())
    const {
      first_name,
      middle_name,
      last_name,
      user_name,
      email,
      email_verified,
      password,
      confirm_password,
      user_enabled,
      subscriptions
    } = createUserForm.getFieldsValue();

    const bodyData = {
      first_name: first_name,
      middle_name: middle_name,
      last_name: last_name,
      user_name: user_name,
      email: email,
      email_verified: true,
      password: password,
      confirm_password: confirm_password,
      temporary_password: false,
      user_enabled: user_enabled,
      subscriptions: getSubscription(subscriptions)
    };
    const { errors } = await profileService.addNewUser(bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('userCreatedSuccessfully') });
      // setUserListingResponse(data);
      navigate(-1);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card>
          <Row gutter={12} justify={'space-between'}>
            <Typography.Title level={3} className="text-[#2e2a5b] pb-4">
              <div className="pb-2 pt-2"> {t('addUser')}</div>
              <TenantInfo />
            </Typography.Title>
          </Row>

          <ManageUserForm formType={'create'} formInstance={createUserForm} handleOnSave={handleOnSave} />
        </Card>
      </div>
    </PrimaryLayout>
  );
};
export default AddUser;
