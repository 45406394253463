import { ASSETS, ASSETS_NEBULA, CREATE_PRICE_URI, MASTER_CATALOG, PRICE_URI } from '../../../config/envConfig';
import { objectHelpers } from '../../../helpers';
import httpClient from '../../../http/http.client';
import { useAuth } from '../../../stores/use-auth';

export const getPriceList = (params: any) => {
  const url = `${CREATE_PRICE_URI}/price-lists/v1`;
  return httpClient.get({ url, params });
};

export const getSelectedItemPriceListData = async (idemId: string) => {
  const url = `${CREATE_PRICE_URI}/price-lists/v1/${idemId}`;
  const { data, errors } = await httpClient.get({ url });
  return { data: data, errors: errors };
};

export const createPriceList = async (bodyData: any) => {
  const url = `${CREATE_PRICE_URI}/price-lists/v1`;
  const requestObj = { ...bodyData };

  const { data, errors } = await httpClient.post({ url: url, body: requestObj });
  return { data: data, errors: errors };
};

export const updateStatus = async (id: string, status: any) => {
  const changedParams = {
    status: status
  };
  const url = `${CREATE_PRICE_URI}/price-lists/v1/${id}`;
  return httpClient.patch({ url, params: changedParams });
};

export const getAllItemsOnPriceList = async (params: any, productId: any) => {
  const snakeCaseParams = objectHelpers.convertObjectKeysSnakeToCamel(params);
  const url = `${CREATE_PRICE_URI}/price-lists/v1/${productId}/products`;

  const { data, errors } = await httpClient.get({ url, params: snakeCaseParams });
  return { data: data, errors: errors };
};

export const removeItemFromPriceList = async (ItemId: any, Id: any) => {
  const url = `${CREATE_PRICE_URI}/price-lists/v1/${Id}/products/${ItemId}`;
  const { data, errors } = await httpClient.delete({ url });
  return { data: data, errors: errors };
};

export const deletePriceList = async (ItemId: any) => {
  const url = `${CREATE_PRICE_URI}/price-lists/v1/${ItemId}`;
  const { data, errors } = await httpClient.delete({ url });
  return { data: data, errors: errors };
};

export const patchPriceListRank = (id: string, rank: string) => {
  const changedParams = {
    rank: rank
  };
  const url = `${CREATE_PRICE_URI}/price-lists/v1/${id}/priority`;
  return httpClient.patch({ url, params: changedParams });
};

export const updatePriceListProduct = (bodyData: any, id: string) => {
  const url = `${CREATE_PRICE_URI}/price-lists/v1/${id}`;
  return httpClient.put({ url, body: bodyData });
};

export const getProductItemDetails = (productId: any, itemId: any) => {
  const url = `${CREATE_PRICE_URI}/price-lists/v1/${productId}/products/${itemId}`;
  return httpClient.get({ url });
};

export const updateItemOnPriceList = (productId: string, itemId: any, bodyData: any) => {
  const url = `${CREATE_PRICE_URI}/price-lists/v1/${productId}/products/${itemId}`;
  return httpClient.put({ url, body: bodyData });
};

export const createListOfItems_bulk = (priceListId: string, bodyData: any) => {
  const url = `${CREATE_PRICE_URI}/price-lists/v1/${priceListId}/products/jobs`;
  return httpClient.post({ url, body: bodyData });
};

export const getSuggestedLineData = async (params: any, productId: any) => {
  const snakeCaseParams = objectHelpers.convertObjectKeysSnakeToCamel(params);
  const url = `${PRICE_URI}/price-masters/${productId}/items/v1/all`;
  const { data, errors } = await httpClient.get({ url, params: snakeCaseParams });
  return { data: data, errors: errors };
};

export const getExcelValidation = async (productId: any, bodyData: any) => {
  const url = `${CREATE_PRICE_URI}/price-lists/v1/${productId}/products/validation`;
  return httpClient.post({ url, body: bodyData });
};

export const getDownloadData = async (productId: any) => {
  const url = `${CREATE_PRICE_URI}/price-lists/v1/${productId}/products/download`;
  return httpClient.get({ url, responseType: 'arraybuffer' });
};

export const getReport = async (productId: any, bodyData: any) => {
  const url = `${CREATE_PRICE_URI}/price-lists/v1/${productId}/products/validation-report`;
  return httpClient.post({ url, body: bodyData, responseType: 'arraybuffer' });
};

export const getMasterData = async (params: any) => {
  const url = `${MASTER_CATALOG}/price-masters/v1`;
  return httpClient.get({ url, params: params });
};

export const updateMasterData = async (bodyData: any) => {
  const url = `${MASTER_CATALOG}/price-masters/v1`;
  return httpClient.put({ url, body: bodyData });
};

export const createMasterData = async (bodyData: any) => {
  const url = `${MASTER_CATALOG}/price-masters/v1`;
  return httpClient.post({ url, body: bodyData });
};

export const getSpecificCatalog = async (catalogId: string) => {
  const url = `${MASTER_CATALOG}/price-masters/v1/${catalogId}`;
  return httpClient.get({ url });
};

export const patchPriceMasterStatus = async (catalogId: string, value: any) => {
  const changedParams = {
    active: value
  };
  const url = `${MASTER_CATALOG}/price-masters/v1/${catalogId}/status`;
  return httpClient.patch({ url, params: changedParams });
};

export const getMasterCatalogItem = async (catalogId: string, params: any) => {
  const url = `${MASTER_CATALOG}/price-masters/${catalogId}/items/v1`;
  return httpClient.get({ url, params: params });
};

export const createPriceMasterItem = async (catalogId: string, bodyData: any) => {
  const url = `${MASTER_CATALOG}/price-masters/${catalogId}/items/v1`;
  return httpClient.post({ url, body: bodyData });
};

export const deletePriceMasterItem = async (priceMasterId: any, itemId: any) => {
  const url = `${MASTER_CATALOG}/price-masters/${priceMasterId}/items/v1/${itemId}`;
  return httpClient.delete({ url });
};

export const getTemplatePriceList = async () => {
  const url = `${ASSETS}/assets/v1/view`;
  let bussinessGrpName = useAuth?.getState()?.bussinessGroupName;
  let tenentName = useAuth?.getState()?.tenentName;
  const headers = {
    bucket_name: `${ASSETS_NEBULA}`,
    file_path: `${bussinessGrpName}/${tenentName}/opus/price-discount/templates/price_entries_template.xlsx`,
    download: true
  };
  return httpClient.get({ url, headers, responseType: 'arraybuffer' });
};

export const uploadItemsValidate = async (id: string, body: any) => {
  const url = `${MASTER_CATALOG}/price-masters/${id}/items/v1/validate`;
  return httpClient.post({ url, body: body });
};

export const batchUploadConfirm = async (id: string, body: any) => {
  const url = `${MASTER_CATALOG}/price-masters/${id}/items/v1/jobs`;
  return httpClient.post({ url, body: body });
};

export const priceListService = {
  getPriceList,
  createPriceList,
  updatePriceListProduct,
  updateStatus,
  getAllItemsOnPriceList,
  removeItemFromPriceList,
  deletePriceList,
  getSelectedItemPriceListData,
  patchPriceListRank,
  getProductItemDetails,
  updateItemOnPriceList,
  createListOfItems_bulk,
  getSuggestedLineData,
  getExcelValidation,
  getDownloadData,
  getReport,
  getMasterData,
  getTemplatePriceList,
  getSpecificCatalog,
  createMasterData,
  patchPriceMasterStatus,
  getMasterCatalogItem,
  createPriceMasterItem,
  updateMasterData,
  deletePriceMasterItem,
  uploadItemsValidate,
  batchUploadConfirm
};
