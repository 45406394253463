import PromotionListingPage from './promotion-list';

interface ISubTotalDiscountPromo {
  productOf: string;
}

const SubTotalDiscountPromo: React.FunctionComponent<ISubTotalDiscountPromo> = ({ productOf }) => {
  return <PromotionListingPage promo={'SUB_TTL_DIS'} promoName="Sub Total Discount" productOf={productOf} />;
};

export default SubTotalDiscountPromo;
