import { Button, Card, Col, Form, Row, Select, Table, TableColumnsType, Typography } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../../components/ActionButton';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import TenantInfo from '../../../../components/TenantIdInfo';
import { getAccess, readableString } from '../../../../constants/common-constants';
import { convertQueryStringToObj, objectHelpers } from '../../../../helpers';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import '../../../../i18n';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useLoader } from '../../../../stores/use-loader';
import { createRuleTriggerTypeData, triggerTypeSelectOptions } from '../../data';
import { loyaltyService } from '../../services/loyalty.service';
import { IEarnRuleListingItem, IEarnRuleListingResponse } from '../../types/earn-rules';

interface IEarnRulesListingProps {
  productOf: string;
}

const EarnRulesListing: React.FunctionComponent<IEarnRulesListingProps> = ({ productOf }) => {
  const { permission } = getAccess(productOf);
  const { t } = useTranslation();
  const [searchForm] = Form.useForm();
  const navigate = useNavigate();

  const [listingResponse, setListingResponse] = React.useState({} as IEarnRuleListingResponse);
  const [expandedRowData, setExpandedRowData] = React.useState({} as Record<string, IEarnRuleListingItem[]>);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([] as string[]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '10');
    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);

  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const statusList = [
    { value: 'OPEN', label: t('open') },
    { value: 'ACTIVE', label: t('active') },
    { value: 'EXPIRED', label: t('expired') },
    { value: 'DEFFERED', label: t('deferred') },
    { value: 'ON_HOLD', label: t('onHold') },
    { value: 'CLOSED', label: t('closed') }
  ];

  const columns: TableColumnsType<IEarnRuleListingItem> = [
    {
      title: t('ruleName'),
      render(value, record, index) {
        return <>{record.name}</>;
      },
      align: 'center'
    },
    {
      title: t('version'),
      render(value, record, index) {
        return <>{record.version}</>;
      },
      align: 'center'
    },
    {
      title: t('triggerType'),
      render(value, record, index) {
        return <>{t((record.trigger_type || '').toLowerCase())}</>;
      },
      align: 'center'
    },
    {
      title: t('applicabilityType'),
      render(value, record, index) {
        let type = '';

        if (record.applicability_type === 'Sign up') {
          type = 'signUp';
        } else if (record.applicability_type === 'Order') {
          type = 'order';
        } else if (record.applicability_type === 'Referral') {
          type = 'referral';
        } else if (record.applicability_type === 'Survey') {
          type = 'survey';
        } else if (record.applicability_type === 'Review') {
          type = 'review';
        } else if (record.applicability_type === 'Sale') {
          type = 'sale';
        } else if (record.applicability_type === 'Abandon Cart') {
          type = 'abandonCart';
        } else if (record.applicability_type === 'Inactive Buyer') {
          type = 'inactiveBuyer';
        } else if (record.applicability_type === 'Scheduled') {
          type = 'scheduled';
        } else if (record.applicability_type === 'Return') {
          type = 'return';
        }
        // Add more conditions as needed

        return <>{t(type) || record?.applicability_type}</>;
      },
      align: 'center'
    },
    {
      title: t('status'),
      render(value, record, index) {
        return <>{record.status === 'ON_HOLD' ? t('onHold') : t((record.status || '')?.toLowerCase())}</>;
      },
      align: 'center'
    },
    {
      title: t('action'),
      render(value, record, index) {
        return (
          <section className="flex gap-2 justify-center">
            <ActionButton
              onClick={() => navigate(`/loyalty/config/earn-rule/${record.id}`)}
              title={t('viewRule')}
              action="VIEW"
            />
            {permission && (
              <ActionButton
                onClick={() => navigate(`/loyalty/config/earn-rule/${record.id}/new`)}
                title={t('createNewVersion')}
                action="CREATE_NEW_VERSION"
              />
            )}
            <ActionButton
              onClick={() => handleTableExpand(record)}
              title={t('viewAllVersions')}
              action="EXPAND_VERSIONS"
            />
          </section>
        );
      },
      align: 'center'
    }
  ];

  if (permission) {
    let placement = columns.length - 1;
    let actionColumn: TableColumnsType<IEarnRuleListingItem> = [
      {
        title: t('statusUpdate'),
        render(value, record, index) {
          let actions: { label: string; action: string }[] = [];

          if (record.status === 'OPEN') {
            actions.push({ label: t('activate'), action: 'ACTIVE' });
          }
          if (record.status === 'ACTIVE') {
            actions.push({ label: t('onHold'), action: 'ON_HOLD' }, { label: t('defer'), action: 'DEFFERED' });
          }
          if (record.status === 'ON_HOLD') {
            actions.push({ label: t('activate'), action: 'ACTIVE' }, { label: t('defer'), action: 'DEFFERED' });
          }
          return (
            <section className="flex justify-center">
              {actions.map(({ label, action }) => (
                <Button key={action} type="link" onClick={() => handleStatusChange(action, record.id)}>
                  {label}
                </Button>
              ))}
            </section>
          );
        },
        align: 'center'
      }
    ];
    columns.splice(placement, 0, ...actionColumn);
  }

  const loadInitialData = async () => {
    if (!_.isEmpty(queryStringObj)) {
      searchForm.setFieldsValue(queryStringObj);
    }
    await handleFilterSearch(pageControl?.currentPage - 1);
  };

  React.useEffect(() => {
    loadInitialData();
  }, [pageControl]);

  const handleClickCreate = () => {
    navigate(`/loyalty/config/earn-rule/create`);
  };

  const handleStatusChange = async (status: string, id: string) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.changeEarnRuleStatus(id, status);
    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      // displaySuccessNotification({ message: t('ruleUpdatedSuccessfully') });
      let action = readableString(data?.status);
      displaySuccessNotification({
        message: t('rewardUpdateFunction', { name: data?.name, action: action })
          .replace('{name}', data?.name)
          .replace('{action}', action)
      });
      const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;

      await Promise.allSettled([handleFilterSearch(offset), fetchEarnRuleVersions(id)]);
    }
    setLoading(false);
  };

  const fetchEarnRuleVersions = async (id: string) => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getEarnRuleVersions(id);
    if (_.isEmpty(errors)) {
      setExpandedRowData(rowData => ({ ...rowData, [id]: data }));
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleTableExpand = async (record: IEarnRuleListingItem) => {
    if (expandedRowKeys.includes(record.id)) {
      return setExpandedRowKeys([]);
    }

    if (_.isEmpty(expandedRowData[record.id])) {
      await fetchEarnRuleVersions(record.id);
    }

    setExpandedRowKeys([record.id]);
  };

  // const handlePageChange = (currentPage: number) => {
  //   const offset = (currentPage - 1) * 10;
  //   handleFilterSearch(offset);
  // };

  const handleFilterSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    const params = { ...formValues, offset };
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);

    const { data, errors } = await loyaltyService.getRuleEarnList({
      limit: pageControl?.pageSize,
      offset,
      ...formValues
    });

    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      const transformedItems = data?.data?.map((item: any) => ({ ...item, key: item.id }));
      const transformedData = { ...data, data: transformedItems };
      setListingResponse(transformedData);
    }
    setLoading(false);
  };

  const expandedRowRender = () => {
    const ExpandedRowColumns: TableColumnsType<IEarnRuleListingItem> = [
      {
        title: t('ruleName'),
        render(value, record, index) {
          return <>{record.name}</>;
        },
        align: 'center'
      },
      {
        title: t('version'),
        render(value, record, index) {
          return <>{record.version}</>;
        },
        align: 'center'
      },
      {
        title: t('triggerType'),
        render(value, record, index) {
          return <>{t((record.trigger_type || '').toLowerCase())}</>;
        },
        align: 'center'
      },
      {
        title: t('applicabilityType'),
        render(value, record, index) {
          let type = '';

          if (record.applicability_type === 'Sign up') {
            type = 'signUp';
          } else if (record.applicability_type === 'Order') {
            type = 'order';
          } else if (record.applicability_type === 'Referral') {
            type = 'referral';
          } else if (record.applicability_type === 'Survey') {
            type = 'survey';
          } else if (record.applicability_type === 'Review') {
            type = 'review';
          } else if (record.applicability_type === 'Sale') {
            type = 'sale';
          } else if (record.applicability_type === 'Abandon Cart') {
            type = 'abandonCart';
          } else if (record.applicability_type === 'Inactive Buyer') {
            type = 'inactiveBuyer';
          } else if (record.applicability_type === 'Scheduled') {
            type = 'scheduled';
          } else if (record.applicability_type === 'Return') {
            type = 'return';
          }
          // Add more conditions as needed

          return <>{t(type) || record?.applicability_type}</>;
        },
        align: 'center'
      },
      {
        title: t('status'),
        render(value, record, index) {
          return <>{record.status === 'ON_HOLD' ? t('onHold') : t((record.status || '')?.toLowerCase())}</>;
        },
        align: 'center'
      },
      {
        title: t('action'),
        render(value, { id }, index) {
          return (
            <section className="flex gap-2 justify-center">
              <ActionButton
                action="VIEW"
                onClick={() => navigate(`/loyalty/config/earn-rule/${id}`)}
                title={t('viewRule')}
              />
              {permission && (
                <ActionButton
                  title={t('testRule')}
                  action="TEST"
                  onClick={() => navigate(`/loyalty/config/earn-rule/${id}/test`)}
                />
              )}
            </section>
          );
        },
        align: 'center'
      }
    ];

    if (permission) {
      let placement = ExpandedRowColumns.length;
      let actionColumn: TableColumnsType<any> = [
        {
          title: t('statusUpdate'),
          render(value, record, index) {
            let actions: { label: string; action: string }[] = [];

            if (record.status === 'OPEN') {
              actions.push({ label: t('activate'), action: 'ACTIVE' });
            }
            if (record.status === 'ACTIVE') {
              actions.push({ label: t('onHold'), action: 'ON_HOLD' }, { label: t('defer'), action: 'DEFFERED' });
            }
            if (record.status === 'ON_HOLD') {
              actions.push({ label: t('activate'), action: 'ACTIVE' }, { label: t('defer'), action: 'DEFFERED' });
            }
            return (
              <section className="flex justify-center">
                {actions.map(({ label, action }) => (
                  <Button key={action} type="link" onClick={() => handleStatusChange(action, record.id)}>
                    {label}
                  </Button>
                ))}
              </section>
            );
          },
          align: 'center'
        }
      ];
      columns.splice(placement, 0, ...actionColumn);
    }

    return (
      <>
        <Typography.Title level={5}>{t('versions')}</Typography.Title>
        <Table
          bordered
          dataSource={expandedRowData[expandedRowKeys[0]]}
          pagination={false}
          columns={ExpandedRowColumns}
        />
      </>
    );
  };

  const handleOnClear = () => {
    searchForm.resetFields();
    handleFilterSearch();
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('earnRules')}
              </Typography.Title>
            </Col>
            {permission && (
              <Col>
                <Button type="primary" size="large" onClick={handleClickCreate}>
                  <BoldButtonLabel labelText={t('create')}></BoldButtonLabel>
                </Button>
              </Col>
            )}
          </Row>
          <TenantInfo />
          <section className="mt-4">
            <Form layout="vertical" form={searchForm}>
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item name="trigger_type" label={t('triggerType')}>
                    <Select
                      placeholder={t('selectTriggerType')}
                      size="large"
                      onChange={() => searchForm.setFieldValue('applicablity_type', null)}
                      options={triggerTypeSelectOptions}
                      allowClear
                      onClear={() => {
                        searchForm.setFieldsValue({
                          trigger_type: null
                        });
                        handleFilterSearch();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                      return prevValues.trigger_type !== curValues.trigger_type;
                    }}
                  >
                    {form => {
                      const { trigger_type } = form.getFieldsValue();
                      const options = createRuleTriggerTypeData[trigger_type]?.applicabilityTypes || [];

                      return (
                        <Form.Item name="applicablity_type" label={t('applicabilityType')}>
                          <Select
                            placeholder={t('selectApplicabilityType')}
                            notFoundContent={t('selectATriggerTypeToViewOptions')}
                            size="large"
                            options={options}
                            allowClear
                            onClear={() => {
                              searchForm.setFieldsValue({
                                applicablity_type: null
                              });
                              handleFilterSearch();
                            }}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="status" label={t('status')}>
                    <Select
                      placeholder={t('status')}
                      size="large"
                      options={statusList}
                      allowClear
                      onClear={() => {
                        searchForm.setFieldsValue({
                          status: null
                        });
                        handleFilterSearch();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={12} md={3}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    onClick={() =>
                      pageControl?.currentPage == 1
                        ? handleFilterSearch(0)
                        : setPageControl(prev => ({ ...prev, currentPage: 1 }))
                    }
                    block
                  >
                    <BoldButtonLabel labelText={t('search')}></BoldButtonLabel>
                  </Button>
                </Col>
                <Col xs={12} md={3}>
                  <Button size="large" onClick={handleOnClear} block>
                    <BoldButtonLabel labelText={t('clear')} />
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>
          <section className="mt-4">
            <Table
              loading={false}
              expandable={{
                expandedRowRender,
                expandedRowKeys,
                showExpandColumn: false
              }}
              pagination={{
                current: pageControl?.currentPage,
                total: listingResponse?.page_info?.total_pages * pageControl?.pageSize || 0,
                pageSize: pageControl?.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],

                onChange: (currentPage, pageSize) => {
                  setPageControl({ currentPage, pageSize });
                },
                locale: {
                  items_per_page: `${t('page')}`
                }
              }}
              locale={{
                emptyText: t('noData')
              }}
              bordered
              dataSource={listingResponse.data}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default EarnRulesListing;
