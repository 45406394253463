import { Button, Card, Checkbox, Form } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { useLoader } from '../../../stores/use-loader';
import { promotionService } from '../services/promotion.service';

interface IControlSettings {
  activeTab: string;
  permission: boolean;
}

const ControlSettings: React.FC<IControlSettings> = ({ activeTab, permission }) => {
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const [controlForm] = Form.useForm();
  const [controlSettings, setControlSettings] = React.useState({});
  const { t } = useTranslation();

  React.useEffect(() => {
    if (activeTab === '2') {
      getControlSettings();
    }
  }, [activeTab]);

  const getControlSettings = async () => {
    setLoading(true);
    const { data, errors } = await promotionService.getControlSettings();
    let result = {};
    if (_.isEmpty(errors)) {
      result = data.reduce((acc: any, item: any) => {
        acc[item.param_name] = item.param_value === 'true' ? true : false;
        return acc;
      }, {});
    }
    setControlSettings(result);
    controlForm.setFieldsValue(result);
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const body = [
      {
        param_name: 'enable_automatic_promotions',
        param_value: controlForm.getFieldValue('enable_automatic_promotions') ? true : false
      },
      {
        param_name: 'enable_manual_promotions',
        param_value: controlForm.getFieldValue('enable_manual_promotions') ? true : false
      }
    ];

    const { errors } = await promotionService.patchControlSettings(body);
    if (_.isEmpty(errors)) displaySuccessNotification({ message: t('controlSettingsUpdated') });
    else displayErrorNotifications(errors);
    setLoading(false);
  };

  return (
    <Card>
      <Form layout="vertical" onFinish={onFinish} form={controlForm}>
        <Form.Item name="enable_automatic_promotions" valuePropName="checked" wrapperCol={{ offset: 2, span: 16 }}>
          <Checkbox disabled={!permission}>{t('enableAutomaticPromotions')}</Checkbox>
        </Form.Item>
        <Form.Item name="enable_manual_promotions" valuePropName="checked" wrapperCol={{ offset: 2, span: 16 }}>
          <Checkbox disabled={!permission}>{t('enableManualPromotions')}</Checkbox>
        </Form.Item>
        {permission && (
          <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
            <Button type="primary" htmlType="submit">
              {t('save')}
            </Button>
          </Form.Item>
        )}
      </Form>
    </Card>
  );
};

export default ControlSettings;
