import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Spin,
  TimePicker,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import {
  appliesToClassForAll,
  appliesToClassForSubTotal,
  buyerSelection,
  countryList,
  currencyList,
  currencyListWithCode,
  months,
  promotionTypes,
  timeZones,
  timeZonesWithCode,
  tireRestraction,
  userTypeSelection,
  weeks
} from '../../../../data/intl-data';
import { convertQueryStringToObj, validationHelpers } from '../../../../helpers';
import { domHelpers } from '../../../../helpers/dom.helpers';
import { displayErrorNotifications } from '../../../../helpers/toast.helpers';
import { getFormattedNumeric2DecimalPointValidatorForInput } from '../../../../helpers/validation.helpers';
import { useAuth } from '../../../../stores/use-auth';
import { useLoader } from '../../../../stores/use-loader';
import { AnalyticsService } from '../../../loyalty-engine/services/Analytics.service';
import { loyaltyService } from '../../../loyalty-engine/services/loyalty.service';
import { useCustomers } from '../../hooks/drop-down-hooks/use-customers';
import { useTranslation } from 'react-i18next';

interface IPromotionFormProps {
  isUpdate?: boolean;
  promotionFormInitialValues?: any;
  viewOnly?: boolean;
  handleClickNext: (data: any, bodyData: any) => void;
  promotionForm: any;
  validTimeFrame?: any;
  radioValue?: any;
  discountData?: any;
  handleOnRadioVale: (data: any) => void;
  handleValidTimeFrame: (e: RadioChangeEvent) => void;
  bdyData: any;
  rewardForm?: any;
  activeStatusView: boolean;
}

const MAX_LIMIT = 999999999;

const filterRespose = [
  { label: 'Tier Level', value: 'TIER' },
  { label: 'Segments', value: 'SEGMENT' },
  { label: 'RFM Category', value: 'RFM_CATEGORY' }
];

const PromoForm: React.FunctionComponent<IPromotionFormProps> = ({
  promotionFormInitialValues,
  viewOnly,
  promotionForm,
  handleClickNext,
  validTimeFrame,
  radioValue,
  discountData,
  handleOnRadioVale,
  handleValidTimeFrame,
  bdyData,
  rewardForm,
  activeStatusView
}) => {
  //const Define
  // const [promotionForm] = Form.useForm();
  // const { id } = useParams();
  // const [searchParams] = useSearchParams();
  // const isUpdate = !_.isEmpty(promotionFormInitialValues);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // const queryString = searchParams.toString();
  // const queryStringObj = convertQueryStringToObj(queryString);
  const { RangePicker } = TimePicker;

  //States
  const [promotionTypeList, setPromotionTypeList] = React.useState(promotionTypes);
  const [timeZoneList, setTimeZoneList] = React.useState(timeZones);
  const [currencyTypeList, setCurrencyTypeList] = React.useState(currencyList);
  const [countryListOptions, setCountryListOptions] = React.useState<any>([]);
  const [ecomOptions, setEcomOptions] = React.useState<any>([]);
  const [storeOptions, setStoreOptions] = React.useState<any>([]);
  const [categoryOptions, setCategoryOptions] = React.useState<any>([]);
  // const [warningBackModal, setWarningBackModal] = React.useState<boolean>(false);

  //zusthand
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const { tenentName, bussinessGroupName } = useAuth(({ tenentName, bussinessGroupName }) => ({
    tenentName,
    bussinessGroupName
  }));

  //Debouncing
  const { debouncedFetchUserDropdownOptions, userDropdownFetching, userDropdownOptions } = useCustomers();
  // const { debouncedFetchProductDropdownOptions, productDropdownFetching, productDropdownOptions } = useProducts();

  React.useEffect(() => {
    intialLoadData();
  }, [promotionFormInitialValues]);

  const intialLoadData = async () => {
    loadCountryOptions();
    loadCategoryOptions();
    loadChannelData();
    setLoading(false);
  };

  const changeCountryHandler = (value: string) => {
    let crList = currencyListWithCode;
    let tzList = timeZonesWithCode;
    promotionForm.setFieldsValue({
      timeZone: null,
      currency: null
    });
    const currencyFilter = crList.filter((item: any) => {
      return item.code === value || item.country === value;
    });
    const timeZoneFilter = tzList.filter((item: any) => {
      return item.code === value || item.country === value;
    });
    setCurrencyTypeList(currencyFilter);
    setTimeZoneList(timeZoneFilter);
    if (value === 'Chile') {
      promotionForm.setFieldsValue({
        timeZone: 'CHILE_CONTINENTAL',
        currency: 'CLP'
      });
    }
  };

  const loadCountryOptions = () => {
    // const url = `${window.location.host}`.replace('www.', '');
    // const urlParts = url.split('.');
    // const tenantIndex = parseInt(TENANT_DOMAIN_INDEX as string);
    // const tenantId = urlParts[tenantIndex];
    // if (tenantId === 'sparta') {
    //   let spartaList = [{ label: '🇨🇱 Chile', value: 'Chile' }];
    //   let spartaListTimeZone = [
    //     { label: 'Chile Continental (UTC-04:00)', value: 'CHILE_CONTINENTAL', country: 'Chile' },
    //     { label: 'Chile Easter (UTC-06:00)', value: 'CHILE_EASTER', country: 'Chile' }
    //   ];
    //   let spartaCurrency = [{ label: '🇨🇱 CLP', value: 'CLP', country: 'Chile' }];
    //   setCountryListOptions(spartaList);
    //   setTimeZoneList(spartaListTimeZone);
    //   setCurrencyTypeList(spartaCurrency);
    //   promotionForm.setFieldsValue({ country: 'Chile' });
    // } else {
    //   setCountryListOptions(countryList);
    //   setTimeZoneList(timeZones);
    //   setCurrencyTypeList(currencyList);
    // }

    setCountryListOptions(countryList);
    setTimeZoneList(timeZones);
    setCurrencyTypeList(currencyList);
  };

  const loadChannelData = async () => {
    const { data, errors } = await loyaltyService.getAllChannelList();
    if (_.isEmpty(errors)) {
      let EcomOptions: any = [];
      let StoreOptions: any = [];
      (data?.data || []).filter((item: any) => {
        if (item.channel_type === 'DOTCOM') {
          let obj = { label: item?.channel_name, value: item?.channel_name };
          EcomOptions.push(obj);
          return obj;
        } else if (item.channel_type === 'STORE') {
          let obj = { label: item?.channel_name, value: item?.channel_name };
          StoreOptions.push(obj);
          return obj;
        }
        return null;
      });
      setEcomOptions(EcomOptions);
      setStoreOptions(StoreOptions);
    } else {
      displayErrorNotifications(errors);
      setLoading(false);
    }
  };

  // const extractCountryValue = (criteria: { criteria_list: Criteria[] }) => {
  //   const countryCriterion = criteria.criteria_list.find(
  //     criterion => criterion.type === 'COUNTRY' && criterion.values?.length > 0
  //   );

  //   return countryCriterion?.values[0] || '';
  // };

  // const getExcludedProductsFromApiCriterea = (criteria: { criteria_list: Criteria[] }): string[] => {
  //   const productExlcusionCriterea = criteria.criteria_list.find(
  //     criterion =>
  //       criterion.type === 'PRODUCTS' && criterion.operation === 'EXCLUDE' && criterion.value_type === 'SKU_ID'
  //   );

  //   return productExlcusionCriterea?.values || [];
  // };

  const loadCategoryOptions = async () => {
    const { data, errors } = await AnalyticsService.getCategoryData();
    if (_.isEmpty(errors)) {
      const outputArray = data?.value?.map((item: any) => ({ label: item.replaceAll('_', ' '), value: item }));
      setCategoryOptions(outputArray);
    } else {
      displayErrorNotifications(errors);
      setLoading(false);
    }
  };

  // const extractValuesFromCriteras = (criteria: any, key: any) => {
  //   criteria.criteria_list = criteria.criteria_list.filter((item: any) => item !== null);
  //   const criterea = criteria?.criteria_list?.find(
  //     (criterion: any) => criterion.type === key && criterion.values?.length > 0
  //   );
  //   return criterea?.values || [];
  // };

  // const RFMextractValuesFromCriteras = (criteria: any, key: any) => {
  //   let nedC = criteria.criteria_list.filter((criteria: any) => criteria !== null);
  //   let data = nedC?.filter((item: any) => item.type === 'RFM_CATEGORY');
  //   return data.length > 0 ? data[0].values : [];
  // };

  const disabledDate = (current: any) => {
    return current && current < dayjs().startOf('day');
  };

  const getDateString = (date: any, formatter: string) => {
    let reqDate = dayjs(date).format(formatter);
    return reqDate;
  };

  // const disabledNewTime = (current: Dayjs | null) => {
  //   let selectedStartDate =
  //     promotionForm?.getFieldsValue()?.dateRangeInSchedule &&
  //     getDateString(promotionForm?.getFieldsValue()?.dateRangeInSchedule[0], 'YYYY-MM-DD');
  //   let todayDate = dayjs().format('YYYY-MM-DD');

  //   if (!!selectedStartDate && selectedStartDate !== todayDate) {
  //     return {};
  //   }
  //   if (!current) {
  //     // allow all times if no value is selected
  //     return {};
  //   }

  //   const now = dayjs();
  //   const isBeforeCurrentDate = current.isBefore(now, 'day');
  //   const isSameCurrentDate = current.isSame(now, 'day');
  //   if (isBeforeCurrentDate) {
  //     // disable all times if the date is before the current date
  //     return {
  //       disabledHours: () => Array.from({ length: 24 })?.map((_, index) => index),
  //       disabledMinutes: () => Array.from({ length: 60 })?.map((_, index) => index),
  //       disabledSeconds: () => Array.from({ length: 60 })?.map((_, index) => index)
  //     };
  //   } else if (isSameCurrentDate) {
  //     // disable hours, minutes, and seconds that are before the current time
  //     let obj = {
  //       disabledHours: () => Array.from({ length: now.hour() })?.map((_, index) => index),
  //       disabledMinutes: (selectedHour: number) =>
  //         selectedHour === now.hour() ? Array.from({ length: now.minute() })?.map((_, index) => index) : [],
  //       disabledSeconds: (selectedHour: number, selectedMinute: number) =>
  //         selectedHour === now.hour() && selectedMinute === now.minute()
  //           ? Array.from({ length: now.second() })?.map((_, index) => index)
  //           : []
  //     };
  //     return obj;
  //   }

  //   // allow all times for future dates
  //   return {};
  // };

  const generateRequestBody = () => {
    let {
      seller_Selection,
      specific_buyer,
      customerRestriction,
      for_generate,
      currency,
      title,
      trigger,
      priority,
      promotionType,
      status,
      appliesTo,
      filters,
      applies_to_product_in_sale,
      combine_with_other_promo,
      ignore_price_lock,
      specific_seller,
      rewardType,
      next_order_applicable,
      no_of_next_orders
    } = promotionForm.getFieldsValue();
    // ------------------------------------------------------------------------
    let specialDayData = promotionForm.getFieldsValue().special_day
      ? {
          operation: 'INCLUDE',
          type: 'SPL_DATE',
          value_type: '',
          values: ['birthday']
        }
      : null;
    filters = filters || [];

    let TireValues = filters
      .filter((item: any) => item.filter_type === 'TIER')
      ?.map((item: any) => item.customerTireRestriction)
      .flat();

    let SegmentValues = filters
      .filter((item: any) => item.filter_type === 'SEGMENT')
      ?.map((item: any) => item.segment)
      .flat();

    let RfmValues = filters
      .filter((item: any) => item.filter_type === 'RFM_CATEGORY')
      ?.map((item: any) => item.RFM_CATEGORY)
      .flat();
    let filterData = filters?.map((item: any) => {
      if (item.filter_type === 'TIER') {
        return {
          operation: 'INCLUDE',
          type: 'CUSTOMER_TIER',
          value_type: '',
          values: TireValues
        };
      } else if (item.filter_type === 'SEGMENT' && Array.isArray(item.segment)) {
        return {
          operation: 'INCLUDE',
          type: 'CUSTOMER_SEGMENT',
          value_type: '',
          values: SegmentValues
        };
      } else if (item.filter_type === 'RFM_CATEGORY' && Array.isArray(item.RFM_CATEGORY)) {
        return {
          operation: 'INCLUDE',
          type: 'RFM_CATEGORY',
          value_type: '',
          values: RfmValues
        };
      }
      return null;
    });
    let Inclusions = [
      {
        operation: 'INCLUDE',
        type: 'COUNTRY',
        value_type: '',
        values: [promotionForm.getFieldsValue()?.country]
      },
      specialDayData,
      ...filterData
    ];
    let cretriaData = [...Inclusions].filter(item => item !== null);
    // ------------------------------------------------------------------------
    let couponUsage = promotionForm.getFieldsValue().use_max_limit
      ? MAX_LIMIT
      : Number(promotionForm.getFieldsValue().max_usage);
    // ------------------------------------------------------------------------
    const getTimeString = (time: any, format: string) => {
      let reqTime = dayjs(time).format(format);
      return reqTime;
    };

    const getSchedule = () => {
      let schedule = {};
      if (promotionForm.getFieldsValue().daily_val) {
        schedule = {
          ...schedule,
          ...{
            daily: promotionForm.getFieldsValue().daily_val,
            daily_info: {
              from_date: promotionForm.getFieldsValue().dateRangeInSchedule
                ? getDateString(promotionForm.getFieldsValue().dateRangeInSchedule[0], 'YYYY-MM-DD')
                : null,
              to_date: promotionForm.getFieldsValue().dateRangeInSchedule
                ? getDateString(promotionForm.getFieldsValue().dateRangeInSchedule[1], 'YYYY-MM-DD')
                : null,
              from_time: promotionForm.getFieldsValue().timeRangeInScheduleFilter
                ? getTimeString(promotionForm.getFieldsValue().timeRangeInScheduleFilter[0], 'HH:mm:ss')
                : null,
              to_time: promotionForm.getFieldsValue().timeRangeInScheduleFilter
                ? getTimeString(promotionForm.getFieldsValue().timeRangeInScheduleFilter[1], 'HH:mm:ss')
                : null
            }
          }
        };
      }
      if (promotionForm.getFieldsValue().week_val) {
        schedule = {
          ...schedule,
          ...{
            weekly: promotionForm.getFieldsValue().week_val,
            weekly_info: promotionForm.getFieldsValue().weekValues || []
          }
        };
      }

      if (promotionForm.getFieldsValue().month_val) {
        schedule = {
          ...schedule,
          ...{
            monthly: promotionForm.getFieldsValue().month_val,
            monthly_info: promotionForm.getFieldsValue().monthValues || []
          }
        };
      }

      if (promotionForm.getFieldsValue().special_day) {
        schedule = {
          ...schedule,
          ...{
            seasonal: promotionForm.getFieldsValue().special_day,
            seasonal_info: promotionForm.getFieldsValue().special_day || []
          }
        };
      }
      let returnScheduleData = Object.values(schedule).length > 0 ? schedule : null;
      return returnScheduleData;
    };
    // let dddd = getDateString(promotionForm.getFieldsValue().dateRangeInSchedule[0], 'YYYY-MM-DD');
    let scheduleSD = getDateString(promotionForm.getFieldsValue().scheduleStartDate, 'YYYY-MM-DD');
    let scheduleED = getDateString(promotionForm.getFieldsValue().ScheduleEndDate, 'YYYY-MM-DD');
    // let ttt = getTimeString(promotionForm.getFieldsValue().timeRangeInSchedule_startTime, 'HH:mm:ss');
    let scheduleST = getTimeString(promotionForm.getFieldsValue().ScheduleStartTime, 'HH:mm:ss');
    let scheduleET = getTimeString(promotionForm.getFieldsValue().scheduleEndTime, 'HH:mm:ss');
    // debugger;
    // ------------------------------------------------------------------------
    const request = {
      additional_config: {
        transaction_apply_count: 1,
        new_buyer: promotionForm.getFieldsValue().onlyForNewUser ? true : false,
        visible_on_popup:
          promotionForm.getFieldsValue().trigger === 'AUTOMATIC' ? true : radioValue === Number(1) ? true : false,
        ignore_price_lock: ignore_price_lock ? ignore_price_lock : false,
        applies_to_product_in_sale: applies_to_product_in_sale ? applies_to_product_in_sale : false,
        combine_with_other_promo: combine_with_other_promo ? combine_with_other_promo : false
      },
      basic_details: {
        tenant_id: tenentName,
        business_group_id: bussinessGroupName,
        title: title,
        trigger: trigger,
        rank: priority,
        promotion_type: promotionType,
        status: status,
        application_type: appliesTo,
        application: 'CART',
        currency: currency,
        visibility: for_generate ? for_generate : 'ANONYMOUS',
        buyer_selection: customerRestriction,
        buyers: specific_buyer || [],
        seller_selection: seller_Selection || 'ALL',
        staff_selection: 'NONE', //hardCoded as their is no staff selection now CONFIRMED BY BE
        sellers: specific_seller ? specific_seller : [],
        product_selection: 'ALL',
        reward_type: rewardType,
        next_order_applicable: trigger === 'MANUAL' ? next_order_applicable : null,
        no_of_next_orders: next_order_applicable === true ? no_of_next_orders : null
      },
      business_group_id: bussinessGroupName,
      channel_details: {
        is_pos: promotionForm.getFieldsValue().isAvailablePos,
        pos: promotionForm.getFieldsValue().posList ? promotionForm.getFieldsValue().posList : [],
        online: promotionForm.getFieldsValue().onlineList ? promotionForm.getFieldsValue().onlineList : [],
        is_online: promotionForm.getFieldsValue().isAvailableOnline
      },
      code_details: null,
      criterias: {
        criteria_list: cretriaData
      },
      loyalty_details: promotionForm.getFieldsValue()?.reward_name
        ? {
            reward_name: promotionForm.getFieldsValue()?.reward_name,
            reward_value: promotionForm.getFieldsValue()?.reward_value
          }
        : null,
      schedule: getSchedule(),
      tenant_id: tenentName,
      usage_details: {
        max_usage: couponUsage,
        limit_per_user: Number(promotionForm.getFieldsValue().limit_per_user)
      },
      validity: {
        start_date: scheduleSD ? scheduleSD : null,
        end_date: scheduleED ? scheduleED : null,
        start_time: scheduleST ? scheduleST : null,
        end_time: scheduleET ? scheduleET : null,
        timezone: promotionForm.getFieldsValue().timeZone,
        valid_on_given_time_frame: validTimeFrame === 1
      }
    };
    return request;
  };

  const clickOnNext = () => {
    let bodyData = generateRequestBody();
    const hasRewardDetails = 'reward_details' in bdyData;
    if (hasRewardDetails) {
      (bodyData as any).reward_details = (bdyData as any).reward_details;
    }
    handleClickNext(promotionForm.getFieldsValue(), bodyData);
  };

  const getDiscountTypeOption = () => {
    const { promotionType } = promotionForm.getFieldsValue();
    if (promotionType === 'SUB_TTL_DIS' || promotionType === 'SHIPPING_DISC') {
      return [
        { label: t('percentageDiscount'), value: 'PERCENTAGE' },
        { label: t('absoluteDiscount'), value: 'FIXED' }
      ];
    }
    return [
      { label: t('percentageDiscount'), value: 'PERCENTAGE' },
      { label: t('absoluteDiscount'), value: 'FIXED' },
      { label: t('specialPrice'), value: 'SPL_PRICE' }
    ];
  };

  const getDisableLogic = () => {
    const hasRewardDetails = 'reward_details' in bdyData;
    if (!activeStatusView) {
      return true;
    }

    if (hasRewardDetails) {
      let rewardData = bdyData?.reward_details[0]?.product_reward?.product_info[0]?.conditions;
      if (rewardData?.length > 0) {
        return true;
      }
    }

    if (
      !!promotionFormInitialValues?.discount &&
      promotionFormInitialValues?.discount[0]?.product_reward?.product_info[0]?.conditions?.length > 0
    ) {
      return true;
    }

    const { promotionType } = promotionForm.getFieldsValue();
    if (promotionType === 'BUNDLE_DISC') {
      return false;
    }

    return viewOnly;
  };

  const getStatus = () => {
    if (!activeStatusView) {
      return activeStatusView;
    }
    return viewOnly;
  };

  return (
    <>
      <Form form={promotionForm} scrollToFirstError layout="vertical" disabled={viewOnly || !activeStatusView}>
        <Card
          style={{
            height: 'fit-content',
            width: '100%',
            // background: '#F1F1E4',
            // padding: '20px',
            borderRadius: '20px',
            marginBottom: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
          }}
        >
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('title')}
                name="title"
                rules={[
                  validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') }),
                  validationHelpers.getTitleValidation({
                    maxLength: 40,
                    maxLengthErrorMessage: t('titleValidator40'),
                    specialCharacterErrorMessage: t('titleNameValidator')
                  })
                ]}
              >
                <Input size="large" placeholder={t('title')} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('status')}
                name="status"
                rules={[validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') })]}
              >
                <Input size="large" defaultValue={'Open'} placeholder={t('status')} disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('trigger')}
                name="trigger"
                rules={[validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') })]}
              >
                <Select
                  placeholder={t('select')}
                  size="large"
                  disabled={true}
                  options={[
                    { label: t('automatic'), value: 'AUTOMATIC' },
                    { label: t('manual'), value: 'MANUAL' }
                  ]}
                ></Select>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('promotionType')}
                name="promotionType"
                rules={[validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') })]}
              >
                <Select
                  disabled={true}
                  placeholder={t('select')}
                  size="large"
                  optionFilterProp="value"
                  options={promotionTypeList}
                ></Select>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('country')}
                name="country"
                rules={[validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') })]}
              >
                <Select
                  onChange={changeCountryHandler}
                  placeholder={t('select')}
                  size="large"
                  options={countryListOptions}
                ></Select>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('timeZone')}
                name="timeZone"
                rules={[validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') })]}
              >
                <Select placeholder={t('select')} size="large" options={timeZoneList}></Select>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('currency')}
                name="currency"
                rules={[validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') })]}
              >
                <Select placeholder={t('select')} size="large" options={currencyTypeList}></Select>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('priority')}
                name="priority"
                rules={[
                  validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') }),
                  validationHelpers.getNumericValidatorForInput({})
                ]}
              >
                <Input size="large" placeholder={t('priority')} />
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('appliesToClass')}
                name="appliesTo"
                rules={[validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') })]}
              >
                <Select
                  placeholder={t('select')}
                  size="large"
                  allowClear
                  disabled={
                    promotionForm.getFieldsValue().promotionType === 'VOLUME_DISC' ||
                    promotionForm.getFieldsValue().promotionType === 'BUNDLE_DISC' ||
                    promotionForm.getFieldsValue().promotionType === 'BXGY' ||
                    promotionForm.getFieldsValue().promotionType === 'SHIPPING_DISC' ||
                    promotionForm.getFieldsValue().promotionType === 'LOYT_REWARD' ||
                    getDisableLogic()
                  }
                  onChange={option => {
                    if (option === 'LOYT_POINTS') {
                      promotionForm.setFieldsValue({ rewardType: 'POINTS' });
                    } else {
                      promotionForm.setFieldsValue({ rewardType: 'DISCOUNT' });
                    }
                  }}
                  options={
                    promotionForm.getFieldsValue().promotionType === 'SUB_TTL_DIS'
                      ? appliesToClassForSubTotal
                      : appliesToClassForAll
                  }
                ></Select>
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate>
              {form => {
                const { appliesTo } = form.getFieldsValue();
                return (
                  appliesTo === 'LOYT_POINTS' && (
                    <>
                      <Col xs={12} md={6}>
                        <Form.Item
                          label={t('rewardLoyalityName')}
                          name="reward_name"
                          rules={[validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') })]}
                        >
                          <Input placeholder={t('rewardLoyalityName')} size="large"></Input>
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Item
                          label={t('rewardLoyaltyPoints')}
                          name="reward_value"
                          rules={[
                            validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') }),
                            validationHelpers.twoDecimalValidator({
                              message: t('pleaseEnterAValidNumericValueWithUpTo2DecimalPlaces')
                            })
                          ]}
                        >
                          <InputNumber
                            className="w-full"
                            min={1}
                            placeholder={t('rewardLoyaltyPoints')}
                            size="large"
                          ></InputNumber>
                        </Form.Item>
                      </Col>
                    </>
                  )
                );
              }}
            </Form.Item>
            {/* Comes only for Loyality Rewards Promo*/}
            <Col xs={12} md={6}>
              <Form.Item noStyle shouldUpdate>
                {form => {
                  const { appliesTo } = form.getFieldsValue();
                  const isDisabled = ['LOYT_POINTS', 'ORDER_TOTAL', 'LINE_ITEMS', 'SHIPPING', 'PAYMENTS'].includes(
                    appliesTo
                  );
                  return (
                    <Form.Item
                      label={t('rewardType')}
                      name="rewardType"
                      rules={[validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') })]}
                    >
                      <Select
                        disabled={isDisabled}
                        placeholder={t('select')}
                        size="large"
                        options={[
                          { label: t('discount'), value: 'DISCOUNT' },
                          { label: t('loyaltyPoints'), value: 'POINTS' },
                          { label: t('products'), value: 'PRODUCT' }, //NO_OTHER_PROMO_USE
                          { label: t('volumeDiscount'), value: 'VOLUME_DISCOUNT' }, //NO_OTHER_PROMO_USE
                          { label: t('bundleDiscount'), value: 'BUNDLE_DISCOUNT' } //NO_OTHER_PROMO_USE
                        ]}
                      ></Select>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('discountType')}
                name={'discount_type'}
                rules={[{ required: true, message: t('thisFieldIsRequired') }]}
              >
                <Select
                  placeholder={t('select')}
                  size="large"
                  options={getDiscountTypeOption()}
                  disabled={getDisableLogic()}
                ></Select>
              </Form.Item>
            </Col>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return prevValues.appliesTo !== curValues.appliesTo;
              }}
            >
              {({ getFieldValue, setFieldsValue }) => {
                const appliesTo = getFieldValue('appliesTo');
                const promotionType = getFieldValue('promotionType');
                return (
                  appliesTo !== 'ORDER_TOTAL' &&
                  (promotionType === 'SUB_TTL_DIS' || promotionType === 'BXGY' || promotionType === 'VOLUME_DISC') && (
                    <Col xs={12} md={6}>
                      <Form.Item
                        label={t('applyDiscountOn')}
                        name={'apply_discount_on'}
                        rules={[{ required: true, message: t('thisFieldIsRequired') }]}
                      >
                        <Select
                          placeholder={t('select')}
                          disabled={getDisableLogic()}
                          size="large"
                          options={[
                            { label: t('products'), value: 'PRODUCTS' },
                            { label: t('category'), value: 'CATEGORY' }
                          ]}
                        ></Select>
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
          </Row>
          <Row gutter={12}>
            <Form.Item noStyle shouldUpdate>
              {form => {
                const { trigger, promotionType } = form.getFieldsValue();
                return (
                  trigger === 'MANUAL' &&
                  promotionType !== 'LOYT_REWARD' && (
                    <Col xs={24} md={6} className="mt-8">
                      <Form.Item name="next_order_applicable" valuePropName="checked">
                        <Checkbox value={'order_applicable'}>{t('nextOrderApplicable')} ?</Checkbox>
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {form => {
                const { trigger, next_order_applicable } = form.getFieldsValue();
                return (
                  trigger === 'MANUAL' &&
                  next_order_applicable && (
                    <Col xs={24} md={6}>
                      <Form.Item
                        name="no_of_next_orders"
                        label={t('numberOfNextOrders')}
                        rules={[
                          validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') }),
                          getFormattedNumeric2DecimalPointValidatorForInput({
                            message: t('pleaseEnterAValidNumericValueWithUpTo2DecimalPlaces')
                          })
                        ]}
                      >
                        <InputNumber
                          min={1}
                          className="w-full"
                          size="large"
                          placeholder={t('numberOfNextOrders')}
                        ></InputNumber>
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
          </Row>
        </Card>

        <Card
          style={{
            height: 'fit-content',
            width: '100%',
            // background: '#F1F1E4',
            // padding: '20px',
            borderRadius: '20px',
            marginBottom: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
          }}
        >
          <Typography.Title level={4}>{t('qualifyingConditions')}</Typography.Title>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return prevValues.appliesTo !== curValues.appliesTo;
            }}
          >
            {form => {
              const { appliesTo } = form.getFieldsValue();
              return (
                <>
                  <Row gutter={12}>
                    {appliesTo !== 'ORDER_TOTAL' && (
                      <Col xs={12} md={8}>
                        <Form.Item name="applies_to_product_in_sale" valuePropName="checked">
                          <Checkbox>{t('appliesToProductsInSale')} ?</Checkbox>
                        </Form.Item>
                      </Col>
                    )}

                    <Col xs={12} md={8}>
                      <Form.Item name="combine_with_other_promo" valuePropName="checked">
                        <Checkbox>{t('combineWithOtherPromotions')} ?</Checkbox>
                      </Form.Item>
                    </Col>

                    {appliesTo !== 'ORDER_TOTAL' && (
                      <Col xs={12} md={8}>
                        <Form.Item name="ignore_price_lock" valuePropName="checked">
                          <Checkbox>{t('ignorePriceLock')} ?</Checkbox>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>

                  <Row gutter={12} className="mb-4">
                    {promotionForm.getFieldsValue().trigger !== 'AUTOMATIC' && (
                      <Col xs={12} md={8}>
                        <Typography.Title level={5}>{t('visibleOnPopUp')}</Typography.Title>
                        <div className="flex">
                          <Radio.Group onChange={(e: any) => handleOnRadioVale(e.target.value)} value={radioValue}>
                            <Radio value={1}>{t('yes')}</Radio>
                            <Radio value={2}>{t('no')}</Radio>
                          </Radio.Group>
                        </div>
                      </Col>
                    )}
                  </Row>
                </>
              );
            }}
          </Form.Item>
          <Typography.Title level={5}>{t('redeemableChannel')} :</Typography.Title>
          <Typography.Text className="font-semibold text-sm">
            <span className="text-red-400">*</span> {t('availableOn')}
          </Typography.Text>
          <div style={{ marginTop: '-30px' }}>
            <Form.Item
              name="availabilityCheck"
              dependencies={['isAvailablePos', 'isAvailableOnline']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const isAvailablePos = getFieldValue('isAvailablePos');
                    const isAvailableOnline = getFieldValue('isAvailableOnline');
                    if (!isAvailablePos && !isAvailableOnline) {
                      return Promise.reject(new Error('Select at least one option (E Com or STORE).'));
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            ></Form.Item>
          </div>
          <Row gutter={12} className="mt-2">
            <Col xs={12} md={2}>
              <Form.Item name="isAvailablePos" valuePropName="checked">
                <Checkbox>{t('eCom')}</Checkbox>
              </Form.Item>
            </Col>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return prevValues.isAvailablePos !== curValues.isAvailablePos;
              }}
            >
              {form => {
                const { isAvailablePos } = form.getFieldsValue();
                return (
                  isAvailablePos && (
                    <Col xs={12} md={6}>
                      <Form.Item
                        label=""
                        name="chooseEcomType"
                        rules={[validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') })]}
                      >
                        <Select
                          placeholder={t('select')}
                          size="large"
                          options={[
                            { label: t('all'), value: 'All' },
                            { label: t('specific'), value: 'Specific' }
                          ]}
                        ></Select>
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return (
                  prevValues.isAvailablePos !== curValues.isAvailablePos ||
                  prevValues.chooseEcomType !== curValues.chooseEcomType
                );
              }}
            >
              {form => {
                const { isAvailablePos } = form.getFieldsValue();
                const { chooseEcomType } = form.getFieldsValue();
                return isAvailablePos && chooseEcomType === 'Specific' ? (
                  <Col xs={24} md={6}>
                    <Form.Item label="" name={'posList'}>
                      <Select
                        mode="multiple"
                        allowClear
                        size="large"
                        placeholder={t('chooseSpecificChannelName')}
                        filterOption={false}
                        options={ecomOptions}
                      ></Select>
                    </Form.Item>
                  </Col>
                ) : null;
              }}
            </Form.Item>
          </Row>
          <Row gutter={12} className="mt-2">
            <Col xs={12} md={2}>
              <Form.Item name="isAvailableOnline" valuePropName="checked">
                <Checkbox>{t('Store')}</Checkbox>
              </Form.Item>
            </Col>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return prevValues.isAvailableOnline !== curValues.isAvailableOnline;
              }}
            >
              {form => {
                const { isAvailableOnline } = form.getFieldsValue();
                return (
                  isAvailableOnline && (
                    <Col xs={12} md={6}>
                      <Form.Item
                        label=""
                        name="chooseStoreType"
                        rules={[
                          {
                            required: false,
                            message: t('thisFieldIsRequired')
                          }
                        ]}
                      >
                        <Select
                          placeholder={t('select')}
                          size="large"
                          options={[
                            { label: t('all'), value: 'All' },
                            { label: t('specific'), value: 'Specific' }
                          ]}
                        ></Select>
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return (
                  prevValues.isAvailableOnline !== curValues.isAvailableOnline ||
                  prevValues.chooseStoreType !== curValues.chooseStoreType
                );
              }}
            >
              {form => {
                const { isAvailableOnline, chooseStoreType } = form.getFieldsValue();
                return isAvailableOnline && chooseStoreType === 'Specific' ? (
                  <Col xs={24} md={6}>
                    <Form.Item label="" name={'onlineList'}>
                      <Select
                        mode="multiple"
                        allowClear
                        size="large"
                        placeholder={t('chooseSpecificStoreName')}
                        filterOption={false}
                        options={storeOptions}
                      ></Select>
                    </Form.Item>
                  </Col>
                ) : null;
              }}
            </Form.Item>
          </Row>

          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('userTypeSelection')}
                name="for_generate"
                rules={[validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') })]}
              >
                <Select
                  placeholder={t('select')}
                  size="large"
                  onChange={() => {
                    //!Reset all fields depended on this field
                    promotionForm.setFieldsValue({
                      customerRestriction: null,
                      filters: [],
                      userSelection: null,
                      specific_buyer: []
                    });
                  }}
                  options={userTypeSelection}
                ></Select>
              </Form.Item>
            </Col>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return prevValues.for_generate !== curValues.for_generate;
              }}
            >
              {form => {
                const { for_generate } = form.getFieldsValue();
                return for_generate === 'REGISTERED_USER' ? (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label={t('buyerSelection')}
                      name="customerRestriction"
                      rules={[validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') })]}
                    >
                      <Select
                        placeholder={t('select')}
                        onChange={() => {
                          //!Reset all fields depended on this field
                          promotionForm.setFieldsValue({
                            userSelection: null,
                            specific_buyer: [],
                            filters: []
                          });
                        }}
                        size="large"
                        options={buyerSelection}
                      ></Select>
                    </Form.Item>
                  </Col>
                ) : null;
              }}
            </Form.Item>
          </Row>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return (
                prevValues.for_generate !== curValues.for_generate ||
                prevValues.customerRestriction !== curValues.customerRestriction
              );
            }}
          >
            {form => {
              const { for_generate, customerRestriction } = form.getFieldsValue();
              return for_generate === 'REGISTERED_USER' && customerRestriction === 'ALL' ? (
                <>
                  <span className="text-lg font-medium">{t('filters')} :</span>
                  <div className="my-4">
                    <Form.List name="filters">
                      {(fields, { add, remove }) => {
                        const filterOptionsAvailable = filterRespose.some(option => {
                          const filters = form.getFieldValue('filters') || [];
                          const selectedTypes = filters?.map((f: any) => f?.filter_type);
                          return !selectedTypes.includes(option.value);
                        });

                        // Function to validate all fields before adding a new filter
                        const handleAddFilter = async () => {
                          try {
                            // Get all the current filters from the form
                            const filters = form.getFieldValue('filters') || [];

                            // Create a list of fields to validate based on current filters
                            const fieldsToValidate = filters.flatMap((_: any, index: any) => [
                              ['filters', index, 'filter_type'],
                              ['filters', index, 'customerTireRestriction'], // Assuming this is for Tier Restriction
                              ['filters', index, 'segment'], // For Segment
                              ['filters', index, 'RFM_CATEGORY'] // For RFM Category
                            ]);

                            // Validate all fields dynamically
                            await form.validateFields(fieldsToValidate);
                            add();
                          } catch (error) {
                            console.error('Validation Failed:', error);
                          }
                        };

                        return (
                          <>
                            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                              <React.Fragment key={key}>
                                <Row gutter={12}>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'filter_type']}
                                      label={t('filterType')}
                                      rules={[
                                        validationHelpers.getRequiredValidator({
                                          message: t('thisFieldIsRequired')
                                        })
                                      ]}
                                    >
                                      <Select
                                        size="large"
                                        allowClear
                                        placeholder={t('select')}
                                        options={filterRespose.filter(option => {
                                          const filters = form.getFieldValue('filters') || [];
                                          const selectedTypes = filters?.map((f: any) => f?.filter_type);
                                          return !selectedTypes.includes(option.value);
                                        })}
                                        onChange={(value: any) => {
                                          form.setFieldsValue({
                                            filters: fields.map((field, i) => ({
                                              ...form.getFieldValue(['filters', i]),
                                              filter_type:
                                                i === index ? value : form.getFieldValue(['filters', i])?.filter_type
                                            }))
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Form.Item noStyle shouldUpdate>
                                    {form => {
                                      const filters: any[] = form.getFieldsValue()?.filters || [];
                                      const attributeType: any = filters[index]?.filter_type;

                                      const inputMap: Record<string, any> = {
                                        TIER: {
                                          name: 'customerTireRestriction',
                                          label: t('tierRestriction'),
                                          component: (
                                            <Select
                                              mode="multiple"
                                              allowClear
                                              placeholder={t('select')}
                                              filterOption={false}
                                              size="large"
                                              options={tireRestraction}
                                            />
                                          )
                                        },
                                        SEGMENT: {
                                          name: 'segment',
                                          label: t('segment'),
                                          component: (
                                            <Select
                                              placeholder={t('selectOrEnter')}
                                              mode="tags"
                                              size="large"
                                              options={[]}
                                            />
                                          )
                                        },
                                        RFM_CATEGORY: {
                                          name: 'RFM_CATEGORY',
                                          label: t('RFMCategory'),
                                          component: (
                                            <Select
                                              placeholder={t('selectOrEnter')}
                                              allowClear
                                              mode="multiple"
                                              size="large"
                                              options={categoryOptions}
                                            />
                                          )
                                        }
                                      };

                                      const componentInfo = inputMap[attributeType];

                                      return (
                                        componentInfo && (
                                          <Col xs={12} md={6}>
                                            <Form.Item
                                              {...restField}
                                              name={[name, componentInfo.name]}
                                              label={componentInfo.label}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: `${componentInfo.label} is required`
                                                }
                                              ]}
                                            >
                                              {componentInfo.component}
                                            </Form.Item>
                                          </Col>
                                        )
                                      );
                                    }}
                                  </Form.Item>
                                  {fields.length > 1 && activeStatusView && !viewOnly && (
                                    <div className="w-full md:w-[50px] md:mx-8 flex justify-center items-center">
                                      <DeleteOutlined
                                        className="text-red-500 text-xl"
                                        title={`Delete Filter ${index + 1}`}
                                        onClick={() => remove(name)}
                                      />
                                    </div>
                                  )}
                                </Row>
                              </React.Fragment>
                            ))}

                            <Form.Item>
                              <Row justify={'start'}>
                                <Col xs={24} md={4}>
                                  <Button
                                    onClick={handleAddFilter} // Call the validation and add logic
                                    icon={<PlusOutlined />}
                                    disabled={!filterOptionsAvailable || viewOnly || !activeStatusView}
                                  >
                                    <BoldButtonLabel labelText={t('addFilter')} />
                                  </Button>
                                </Col>
                              </Row>
                            </Form.Item>
                          </>
                        );
                      }}
                    </Form.List>
                  </div>
                </>
              ) : null;
            }}
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return (
                prevValues.for_generate !== curValues.for_generate ||
                prevValues.customerRestriction !== curValues.customerRestriction
              );
            }}
          >
            {form => {
              const { for_generate, customerRestriction } = form.getFieldsValue();
              return for_generate === 'REGISTERED_USER' && customerRestriction === 'SPECIFIC' ? (
                <Row gutter={12}>
                  <Col xs={12} md={6}>
                    <Form.Item
                      label={t('user')}
                      name={'specific_buyer'}
                      rules={[validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') })]}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        size="large"
                        filterOption={false}
                        placeholder={t('select')}
                        notFoundContent={userDropdownFetching ? <Spin size="small" /> : <>{t('searchUser')}</>}
                        onSearch={async searchTerm => {
                          if (searchTerm) await debouncedFetchUserDropdownOptions(searchTerm);
                        }}
                        options={userDropdownOptions}
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
              ) : null;
            }}
          </Form.Item>
        </Card>

        <Card
          style={{
            height: 'fit-content',
            width: '100%',
            // background: '#F1F1E4',
            // padding: '20px',
            borderRadius: '20px',
            marginBottom: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
          }}
        >
          <Typography.Title level={5}>{t('usage')} :</Typography.Title>
          <Row gutter={12}>
            <Col xs={12} md={6}>
              <Form.Item
                label={t('limitPerUser')}
                name="limit_per_user"
                rules={[
                  validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') }),
                  validationHelpers.getNumericValidatorForInput({}),
                  validationHelpers.validateLimitPerUser(promotionForm.getFieldValue)
                ]}
              >
                <Input size="large" placeholder={t('limitPerUser')} />
              </Form.Item>
            </Col>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return (
                  prevValues.use_max_limit !== curValues.use_max_limit ||
                  prevValues.maxUsage !== curValues.maxUsage ||
                  prevValues.limitPerUser !== curValues.limitPerUser
                );
              }}
            >
              {({ getFieldValue }) => {
                const MaxLimit = getFieldValue('use_max_limit');
                if (MaxLimit) {
                  return null;
                }
                return (
                  <Col xs={12} md={6}>
                    <Form.Item
                      label={t('maxUsage')}
                      name="max_usage"
                      rules={[
                        validationHelpers.getRequiredValidator({ message: t('thisFieldIsRequired') }),
                        validationHelpers.getNumericValidatorForInput({}),
                        validationHelpers.validateMaxUsage(promotionForm.getFieldValue)
                      ]}
                    >
                      <Input size="large" placeholder={t('maxUsage')} disabled={MaxLimit || viewOnly} />
                    </Form.Item>
                  </Col>
                );
              }}
            </Form.Item>

            <Col xs={12} md={3} className="mt-8">
              <Form.Item valuePropName="checked" name={'use_max_limit'}>
                <Checkbox
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      promotionForm.setFieldsValue({ maxUsage: MAX_LIMIT });
                    } else {
                      promotionForm.setFieldsValue({ maxUsage: null });
                    }
                  }}
                >
                  {t('useMaxLimit')}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card
          style={{
            height: 'fit-content',
            width: '100%',
            // background: '#F1F1E4',
            // padding: '20px',
            borderRadius: '20px',
            marginBottom: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
          }}
        >
          <Typography.Title level={5}>Schedule :</Typography.Title>
          <Row gutter={12}>
            <Col xs={12} md={8}>
              <Form.Item
                label={t('startDate')}
                name="scheduleStartDate"
                rules={[
                  { required: true, message: t('thisFieldIsRequired') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const endDate = getFieldValue('endDate');
                      if (value && endDate && dayjs(value).isAfter(dayjs(endDate), 'day')) {
                        return Promise.reject(new Error('Start date cannot be after the end date.'));
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <DatePicker
                  size="large"
                  className="w-[100%]"
                  placeholder={t('startDate')}
                  // disabled={!isformVisible || formVisiblityBasedOnStatus}
                  style={{ borderRadius: '0' }}
                  disabledDate={disabledDate}
                  onChange={date => {
                    const endDate = promotionForm.getFieldValue('endDate');
                    if (!date) {
                      promotionForm.setFieldsValue({ endDate: null });
                    } else if (endDate && dayjs(date).isAfter(dayjs(endDate), 'day')) {
                      promotionForm.setFieldsValue({ endDate: null });
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={8}>
              <Form.Item
                label={t('startTime')}
                name="ScheduleStartTime"
                rules={[{ required: true, message: t('thisFieldIsRequired') }]}
              >
                <TimePicker
                  size="large"
                  className="w-[100%]"
                  placeholder={t('startTime')}
                  style={{ borderRadius: '0' }}
                  onBlur={e => {
                    let startingTime = e.target.value.length > 1 ? dayjs(e.target.value, 'HH:mm:ss') : null;
                    promotionForm.setFieldsValue({ startTime: startingTime });
                  }}
                  // disabled={!isformVisible || formVisiblityBasedOnStatus}
                  format={'HH:mm:ss'}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={12} md={8}>
              <Form.Item
                label={t('endDate')}
                name="ScheduleEndDate"
                rules={[
                  { required: true, message: t('thisFieldIsRequired') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const startDate = getFieldValue('startDate');
                      if (startDate && value && dayjs(value).isBefore(dayjs(startDate), 'day')) {
                        return Promise.reject(new Error('End date cannot be before start date.'));
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <DatePicker
                  size="large"
                  className="w-[100%]"
                  style={{ borderRadius: '0' }}
                  placeholder={t('endDate')}
                  disabled={getStatus()}
                  disabledDate={disabledDate}
                  onChange={date => {
                    const startDate = promotionForm.getFieldValue('startDate');
                    if (startDate && date && dayjs(startDate).isSame(dayjs(date), 'day')) {
                      promotionForm.setFieldsValue({
                        endTime: null
                      });
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={8}>
              <Form.Item
                label={t('endTime')}
                name="scheduleEndTime"
                rules={[
                  { required: true, message: t('thisFieldIsRequired') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const startDate = getFieldValue('startDate');
                      const endDate = getFieldValue('endDate');
                      const startTime = getFieldValue('startTime');
                      const now = dayjs();
                      const isToday = endDate && dayjs(endDate).isSame(now, 'day');

                      if (startDate && endDate && startTime && value) {
                        // If the dates are the same, check the time
                        if (dayjs(startDate).isSame(dayjs(endDate), 'day')) {
                          if (dayjs(value).isBefore(dayjs(startTime))) {
                            return Promise.reject(new Error('End time cannot be before start time.'));
                          }
                        }
                      }

                      // If the endDate is today, check if the endTime is before the current time
                      if (isToday && value && dayjs(value).isBefore(now, 'minute')) {
                        return Promise.reject(new Error('End time cannot be before the current time.'));
                      }

                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <TimePicker
                  size="large"
                  className="w-[100%]"
                  format={'HH:mm:ss'}
                  style={{ borderRadius: '0' }}
                  placeholder={t('endTime')}
                  disabled={getStatus()}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <div style={{ marginBottom: '15px' }}>
              <Radio.Group onChange={handleValidTimeFrame} value={validTimeFrame}>
                <Radio value={1}>{t('validInThisTimeFrameOnly')}</Radio>
                <Radio value={2}>{t('validOnParticularDaysOnly')}</Radio>
              </Radio.Group>
            </div>
          </Row>
          {validTimeFrame === 2 && (
            <Card
              style={{
                height: 'fit-content',
                width: '100%',
                // background: '#9b9b9b29',
                // padding: '20px',
                borderRadius: '20px',
                marginBottom: '15px',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
              }}
            >
              <Typography.Title level={5}>Schedule Filter :</Typography.Title>
              <Row gutter={12} className="mt-6">
                <Col xs={12} md={6}>
                  <Form.Item name="daily_val" valuePropName="checked">
                    <Checkbox value={'daily_val'}>{t('daily')}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    return prevValues.daily_val !== curValues.daily_val;
                  }}
                >
                  {form => {
                    const { daily_val } = form.getFieldsValue();
                    return daily_val ? (
                      <Col xs={12} md={6}>
                        <Form.Item
                          label={t('time')}
                          name="timeRangeInScheduleFilter"
                          rules={[
                            { required: false, message: t('thisFieldIsRequired') },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const startTime = getFieldValue('timeRangeInSchedule_startTime');
                                const endTime = getFieldValue('timeRangeInSchedule_endTime');

                                if (!value || !startTime || !endTime) {
                                  return Promise.resolve(); // Skip validation if no values are present
                                }

                                // Convert to Day.js objects to handle time comparison
                                const rangeStart = dayjs(value[0], 'HH:mm');
                                const rangeEnd = dayjs(value[1], 'HH:mm');
                                const start = dayjs(startTime, 'HH:mm');
                                const end = dayjs(endTime, 'HH:mm');

                                if (rangeStart.isBefore(start) || rangeEnd.isAfter(end)) {
                                  return Promise.reject(new Error(t('timeRangeError')));
                                }

                                return Promise.resolve();
                              }
                            })
                          ]}
                        >
                          <RangePicker size="large" format={'HH:mm'} />
                        </Form.Item>
                      </Col>
                    ) : null;
                  }}
                </Form.Item>
              </Row>
              <Row gutter={12}>
                <Form.Item name="week_val" valuePropName="checked">
                  <Checkbox value={'week_val'}>{t('weekly')}</Checkbox>
                </Form.Item>
              </Row>
              <Row gutter={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    return prevValues.week_val !== curValues.week_val;
                  }}
                >
                  {form => {
                    const { week_val } = form.getFieldsValue();
                    return week_val ? (
                      <Form.Item name="weekValues">
                        <Checkbox.Group>
                          {weeks?.map((item: any) => {
                            return (
                              <Col>
                                <Checkbox value={item.value}>{item.label}</Checkbox>
                              </Col>
                            );
                          })}
                        </Checkbox.Group>
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
              </Row>
              <Row gutter={12}>
                <Form.Item name="month_val" valuePropName="checked">
                  <Checkbox value={'month_val'}>{t('monthly')}</Checkbox>
                </Form.Item>
              </Row>
              <Row gutter={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    return prevValues.month_val !== curValues.month_val;
                  }}
                >
                  {form => {
                    const { month_val } = form.getFieldsValue();
                    return month_val ? (
                      <Form.Item name={'monthValues'}>
                        <Checkbox.Group>
                          {months?.map((item: any) => {
                            return <Checkbox value={item.value}>{item.label}</Checkbox>;
                          })}
                        </Checkbox.Group>
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
              </Row>
            </Card>
          )}
        </Card>
        <Row gutter={[12, 12]} className="mt-4">
          <Col xs={12} md={6}>
            <Button
              size="large"
              onClick={() => {
                navigate(-1);
              }}
              type="default"
              block
              disabled={false}
            >
              <BoldButtonLabel labelText={t('back')}></BoldButtonLabel>
            </Button>
          </Col>
          <Col xs={12} md={6}>
            <Button
              type="primary"
              size="large"
              disabled={false}
              onClick={async () => {
                try {
                  await promotionForm.validateFields();
                  clickOnNext();
                } catch (error) {
                  console.log('valatiateSaveHandler', error);
                  domHelpers.scrollMainContentToTop();
                }
              }}
              block
            >
              <BoldButtonLabel labelText={t('nextPage')}></BoldButtonLabel>
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default PromoForm;
