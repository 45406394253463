import * as React from 'react';

import { Button, Card, Col, Form, Row, Select, Spin, Table, TableColumnsType, Typography } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../../../components/ActionButton';
import BoldButtonLabel from '../../../../../components/BoldButtonLabel';
import CustomPagination from '../../../../../components/custom-pagination';
import FieldDisplay from '../../../../../components/FieldDisplay';
import { getAccess } from '../../../../../constants/common-constants';
import { convertQueryStringToObj, objectHelpers, urlHelpers } from '../../../../../helpers';
import _ from '../../../../../helpers/lodash';
import { displayErrorNotifications } from '../../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../../layouts/primary-layout';
import { useLoader } from '../../../../../stores/use-loader';
import { useDnn } from '../../../hooks/drop-down-hooks/use-Dnn';
import { logisticService } from '../../../services';

interface IServiceNetworkListingProps {
  productOf: string;
}

const serviceCategories = [
  { label: 'Global', value: 'GLOBAL' },
  { label: 'National', value: 'NATIONAL' },
  { label: 'Regional', value: 'REGIONAL' },
  { label: 'Local', value: 'LOCAL' }
];

const ServiceNetworkListing: React.FunctionComponent<IServiceNetworkListingProps> = ({ productOf }) => {
  const { ssnName, ssnId, shippingName, shippingId } = useParams();
  const { permission } = getAccess(productOf);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [serviceNetworkResponse, setServiceNetworkResponse] = React.useState({} as any);

  const [searchForm] = Form.useForm();

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const columns: TableColumnsType<any> = [
    {
      title: 'Service Category',
      render(value, record, index) {
        return <>{record.service_category}</>;
      },
      align: 'center'
    },
    {
      title: 'DNN',
      render(value, record, index) {
        return <>{record.dnn_name}</>;
      },
      align: 'center'
    },
    {
      title: 'Shipping Profile',
      render(value, record, index) {
        return <>{record.shipping_profile_name}</>;
      },
      align: 'center'
    }
  ];

  if (permission) {
    let placement = columns.length - 1;
    let actionColumn: TableColumnsType<any> = [
      {
        title: 'Action',
        render(value, record, index) {
          return (
            <section className="flex justify-center gap-4">
              <ActionButton
                action="VIEW"
                onClick={() => {
                  navigate(`/logistics/service-network/${record.id}?${backUrlParamString}`);
                }}
                title="View Service Network"
              />

              <ActionButton
                action="CREATE_NEW_VERSION"
                onClick={() => {
                  navigate(`/logistics/service-network/${record.id}/edit?${backUrlParamString}`);
                }}
                title="Edit Service Network"
              />

              <ActionButton
                action="DELETE"
                title={'Delete Service Network'}
                onClick={() => handleSnnDelete(record)}
              ></ActionButton>
            </section>
          );
        },
        align: 'center'
      }
    ];
    columns.splice(placement, 0, ...actionColumn);
  }

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    const queryString = searchParams.toString();
    const queryStringObj = convertQueryStringToObj(queryString);
    if (!_.isEmpty(queryStringObj)) {
      searchForm.setFieldsValue(queryStringObj);
    }
    const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
    await handleFilterSearch(offset);
  };

  const handleFilterSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    const queryString = searchParams.toString();
    const queryStringObj = convertQueryStringToObj(queryString);
    const fulfillment_type = queryStringObj['fulfilment-type'];
    let formValue = {
      'dnn-id': formValues.dnn_id,
      'service-category': formValues.service_category,
      'shipping-profile-id': shippingId,
      'fulfilment-type': fulfillment_type
    };
    const params = { ...formValue, offset };

    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);

    const { data, errors } = await logisticService.getServiceNetwork(filteredParams);
    if (_.isEmpty(errors)) {
      setServiceNetworkResponse(data);
    } else {
      displayErrorNotifications(errors);
    }

    setLoading(false);
  };

  const backUrlParamString = urlHelpers.getBackUrlParamString(searchParams);

  // const handleStatusChange = async (action: boolean, record: any) => {};

  const handleSnnDelete = async (record: any) => {
    setLoading(true);
    const { errors } = await logisticService.removeServiceNetwork(record.id);
    if (_.isEmpty(errors)) {
      handleFilterSearch(0);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handlePageChange = (currentPage: number) => {};

  const { debouncedFetchDnnDropdownOptions, dnnDropDownFetching, dnnDropDownOptions } = useDnn();
  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Service Networks
              </Typography.Title>
            </Col>

            {permission && (
              <Col>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    navigate(
                      `/logistics/service-network/create/${shippingId}?${backUrlParamString}&fulfilment-type=${searchParams.get(
                        'fulfilment-type'
                      )}`
                    );
                  }}
                >
                  <BoldButtonLabel labelText="Create" />
                </Button>
              </Col>
            )}
          </Row>

          <Row gutter={12}>
            <section className="flex flex-wrap flex-col gap-2 mb-4">
              <FieldDisplay label="Source Node Network Name" value={ssnName} />
              {/* <FieldDisplay label="Source Node Network Id" value={ssnId} /> */}
              <FieldDisplay label="Shipping Profile Name" value={shippingName} />
            </section>
          </Row>
          <div className="my-4">
            <Form onFinish={() => handleFilterSearch(0)} form={searchForm} layout="vertical">
              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item name={'service_category'} label="Service Category">
                    <Select
                      options={serviceCategories}
                      placeholder="Service Category"
                      size="large"
                      allowClear
                      showSearch
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item name={'dnn_id'} label="DNN">
                    <Select
                      allowClear
                      showSearch
                      size="large"
                      filterOption={false}
                      placeholder="Search operators"
                      notFoundContent={dnnDropDownFetching ? <Spin size="small" /> : null}
                      onSearch={async searchTerm => {
                        if (searchTerm) await debouncedFetchDnnDropdownOptions(searchTerm);
                      }}
                      options={dnnDropDownOptions}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <div className="h-full flex justify-center items-center">
                    <Button type="primary" htmlType="submit" size="large" block>
                      <BoldButtonLabel labelText="Search"></BoldButtonLabel>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>

          <section className="mt-4">
            {!_.isEmpty(serviceNetworkResponse?.service_networks) && (
              <div className="flex justify-end my-2">
                <CustomPagination
                  currentPage={1}
                  totalPages={1}
                  handleNext={handlePageChange}
                  handlePageChange={handlePageChange}
                  handlePrevious={handlePageChange}
                />
              </div>
            )}{' '}
            <Table
              loading={false}
              pagination={false}
              bordered
              dataSource={serviceNetworkResponse?.service_networks || []}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
          <Row gutter={[12, 12]} className="mt-4">
            <Col xs={24} md={8} lg={6}>
              <Button
                size="large"
                disabled={false}
                block
                onClick={() => {
                  navigate(`/logistics/shipping-profile/${ssnName}?snn_id=${ssnId}`);
                }}
              >
                <BoldButtonLabel labelText="Go Back" />
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default ServiceNetworkListing;
