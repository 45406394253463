import { Card, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { getAccess } from '../../../constants/common-constants';
import PrimaryLayout from '../../../layouts/primary-layout';
import CombinedPromotionConfiguration from './combined-promotion-configuration';
import ControlSettings from './set-priority-control-settings';
import PromotionSettings from './set-priority-promotion-settings';

interface ISetPriorityProps {
  productOf: string;
}

const SetPriority: React.FunctionComponent<ISetPriorityProps> = ({ productOf }) => {
  const { permission } = getAccess(productOf);
  const [activeTab, setActiveTab] = React.useState('1');
  const { t } = useTranslation();

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card>
          <Tabs activeKey={activeTab} onChange={handleTabChange}>
            <TabPane tab={<BoldButtonLabel labelText={t('promotionPriority')} />} key="1">
              <PromotionSettings activeTab={activeTab} permission={permission} />
            </TabPane>
            <TabPane tab={<BoldButtonLabel labelText={t('controlSettings')} />} key="2">
              <ControlSettings activeTab={activeTab} permission={permission} />
            </TabPane>
            <TabPane tab={<BoldButtonLabel labelText={t('combinedPromotionConfiguration')} />} key="3">
              <CombinedPromotionConfiguration activeTab={activeTab} permission={permission} />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default SetPriority;
