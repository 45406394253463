import { Button, Card, Col, Divider, Form, Input, Modal, Row, Switch, Table, TableColumnsType, Typography } from 'antd';
import * as React from 'react';
import BoldButtonLabel from '../../../../../components/BoldButtonLabel';
import FieldDisplay from '../../../../../components/FieldDisplay';
import PrimaryLayout from '../../../../../layouts/primary-layout';

import { CloudUploadOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { getAccess } from '../../../../../constants/common-constants';
import { fileHelpers, objectHelpers } from '../../../../../helpers';
import _ from '../../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../../helpers/toast.helpers';
import { useLoader } from '../../../../../stores/use-loader';
import { logisticService } from '../../../services';

interface IManagePostCodeProps {
  productOf: string;
}

const DnnManagePostCode: React.FunctionComponent<IManagePostCodeProps> = ({ productOf }) => {
  const { permission } = getAccess(productOf);
  const { id } = useParams();
  const navigate = useNavigate();
  const [postCodeForm] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dnnDetailResponse, setDnnDetailResponse] = React.useState({} as any);
  const [excelBodyData, setExcelBodyData] = React.useState({});
  const [excelResult, setExcelResult] = React.useState({} as any);
  // const [excelResultResp, setExcelResultResp] = React.useState({} as any);
  const [showManualPostcodeModal, setShowManualPostcodeModal] = React.useState(false);
  const [showUploadPostcodeModal, setShowUploadPostcodeModal] = React.useState(false);
  const [postcodeFiles, setPostcodeFiles] = React.useState([] as RcFile[]);
  const [manualPostcodeForm] = Form.useForm();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const [dnnData, setDnnData] = React.useState({} as any);

  const coloumns: TableColumnsType<any> = [
    {
      title: 'Postcode',
      dataIndex: 'postcode',
      align: 'center',
      sorter: (a: any, b: any) => a.postcode.localeCompare(b.postcode)
    },
    // {
    //   title: 'Post Code ID',
    //   dataIndex: 'postcode_id',
    //   align: 'center',
    //   sorter: (a: any, b: any) => a.postcode_id.localeCompare(b.postcode_id)
    // },
    {
      title: 'Active',
      align: 'center',
      render(value: any, record: any, index: any) {
        return <Typography>{record.is_active.toString().toUpperCase()}</Typography>;
      }
    }
  ];

  React.useEffect(() => {
    getPostCodeList(0, 10);
    loadDnnDetails();
  }, []);

  // const handleSearchPostcode = () => {
  //   getPostCodeList(0);
  // };

  // const handleStatusChange = async (value: any, record: any) => {
  //   console.log(record);
  //   const bodyData = {
  //     is_active: value
  //   };
  //   const { errors } = await logisticService.updateStatusOfPostCode(record.postcode_id, bodyData);
  //   if (_.isEmpty(errors)) {
  //     displaySuccessNotification({ message: `Postal code status updated !!` });
  //     getPostCodeList();
  //   } else {
  //     displayErrorNotifications(errors);
  //   }
  // };

  // const handleOnDeletePostCode = async (record: any) => {
  //   setLoading(true);
  //   const { errors } = await logisticService.removePostCode(record.id);
  //   if (_.isEmpty(errors)) {
  //     displaySuccessNotification({ message: 'Post Code Removed Successfully!!' });
  //   } else {
  //     displayErrorNotifications(errors);
  //   }
  //   setLoading(false);
  // };

  const getReportData = async () => {
    setLoading(true);
    console.log(excelResult);
    // let parentPriceListId = productData?.parent_price_list_id;
    const { data, errors } = await logisticService.downloadReport(excelResult);
    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'report_excel', extension: '.xlsx' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const getPostCodeList = async (offset = 0, limit = 10) => {
    setLoading(true);
    const formValues = postCodeForm.getFieldsValue();
    const filteredFormValues = {
      ...objectHelpers.deleteUndefinedValuesFromObject(formValues),
      offset: offset + '',
      limit: limit + ''
    };
    setSearchParams(filteredFormValues);
    const params = {
      ...formValues,
      offset,
      limit
    };
    const { data, errors } = await logisticService.getPostCodeListDNN(id as string, params);
    if (_.isEmpty(errors)) {
      setDnnDetailResponse(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const loadDnnDetails = async () => {
    setLoading(true);
    const { data, errors } = await logisticService.getDNNbyDNNId(id as string);
    if (_.isEmpty(errors)) {
      setDnnData(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  // const handleClickAddPostcodes = () => {
  //   setShowManualPostcodeModal(true);
  // };

  const handleCLickUploadPostcodes = () => {
    setShowUploadPostcodeModal(true);
  };

  const handlePostcodeFileUpload = () => {
    if (_.isEmpty(postcodeFiles)) return;
    const formData = new FormData();
    formData.set('file', postcodeFiles[0] as RcFile);
    if (postcodeFiles) {
      const fileReader = new FileReader();
      fileReader.onload = event => {
        const data = event?.target?.result as ArrayBuffer;
        const workbook = XLSX.read(data, {
          type: 'binary'
        });
        let excelDataParsed: any[] = [];
        workbook.SheetNames.forEach(sheet => {
          if (excelDataParsed.length === 0) {
            const rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            const jsonObject = JSON.stringify(rowObject);
            const getExceldata = JSON.parse(jsonObject);
            excelDataParsed.push(getExceldata);
            getValidData(excelDataParsed[0]);
          }
        });
      };
      let selectedFile = postcodeFiles[0];
      fileReader.readAsBinaryString(selectedFile);
    }
  };

  const getValidData = async (excelData: any) => {
    setLoading(true);
    if (excelData.length > 10000) {
      displayErrorNotifications([{ message: 'Data cannot exced more than ten thousand' }]);
      setLoading(false);
      return;
    } else {
      let bodyData = loadExcelBodyData(excelData);
      setExcelBodyData(bodyData);
      const { data, errors } = await logisticService.batchUploadValidation(id as string, bodyData);
      if (_.isEmpty(errors)) {
        setExcelResult(data);
      } else {
        displayErrorNotifications(errors);
      }
    }
    setLoading(false);
  };

  const loadExcelBodyData = (excelData: any) => {
    const postcodeData = excelData.map((data: any) => {
      return {
        is_active: data.is_active,
        operation: data.operation,
        postcode: data.postcode
      };
    });

    const bodyData = {
      country_code: dnnData?.country_code || 'IN',
      postcodes: postcodeData
    };

    return bodyData;
  };

  const handleOnPostCodeEntry = async () => {
    const postCodeDetails = manualPostcodeForm.getFieldsValue();

    const AllbodyData = postCodeDetails?.postcodes?.map((details: any) => {
      return {
        country_code: dnnData?.country_code || 'IN',
        postcode: details.postcode,
        is_active: details.is_active
      };
    });

    const bodyData = AllbodyData[0];

    const { errors } = await logisticService.createPostalCode(bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Code Added successfully' });
      closePostCodeModal();
    } else {
      displayErrorNotifications(errors);
    }
  };

  const closePostCodeModal = () => {
    setShowUploadPostcodeModal(false);
    setExcelBodyData([]);
    setPostcodeFiles([]);
    setShowManualPostcodeModal(false);
    manualPostcodeForm.setFieldsValue({ postcodes: [] });
    setExcelResult({} as any);
  };

  const handleOnUploadExcelData = async () => {
    setLoading(true);
    const { errors } = await logisticService.batchUploadConfirm(id as string, excelBodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'uploaded Successfully !' });
      closePostCodeModal();
      getPostCodeList(0, parseInt(searchParams.get('limit') || '10'));
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handlePageChange = (currentPage: number | null, pageSize: number | null) => {
    const offset = currentPage ? currentPage - 1 : parseInt(searchParams.get('offset') || '0');
    const limit = pageSize ? pageSize : parseInt(searchParams.get('limit') || '10');
    getPostCodeList(offset, limit);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Form
            form={postCodeForm}
            layout="vertical"
            onFinish={() => getPostCodeList(0, parseInt(searchParams.get('limit') || '10'))}
          >
            <Row gutter={[24, 12]} align={'middle'} className="mb-4">
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  Distribution Node Network &gt; Manage Postcodes
                </Typography.Title>
              </Col>
            </Row>
            <section className="flex flex-wrap flex-col gap-2">
              <FieldDisplay label="Distribution Node Network Name" value={dnnData?.dnn_name || ''} />
              {dnnData?.description && <FieldDisplay label="Description" value={dnnData?.description || ''} />}
              {/* <FieldDisplay label="Operator Name" value="Sample Operator" /> */}
              <FieldDisplay label="Country" value={dnnData?.country_code || ''} />
            </section>
            <section>
              <Row className="my-4" gutter={[12, 12]}>
                <Col xs={24} md={6}>
                  <Form.Item name={'postcode'} label="">
                    <Input.Search
                      placeholder="Search Postcode"
                      size="large"
                      enterButton
                      onSearch={() => getPostCodeList(0, parseInt(searchParams.get('limit') || '10'))}
                    ></Input.Search>
                  </Form.Item>
                </Col>
                {permission && (
                  <Col xs={24} md={6}>
                    <Button type="primary" size="large" onClick={handleCLickUploadPostcodes} block>
                      <BoldButtonLabel labelText="Upload Postcodes" />
                    </Button>
                  </Col>
                )}
              </Row>
              {/* {dnnDetailResponse?.dnn_postcodes?.length > 0 && (
                <div className="flex justify-end my-2">
                  <CustomPagination
                    currentPage={dnnDetailResponse?.current_page + 1 || 0}
                    totalPages={dnnDetailResponse.total_pages || 0}
                    handleNext={handlePageChange}
                    handlePageChange={handlePageChange}
                    handlePrevious={handlePageChange}
                  />
                </div>
              )} */}
              <Table
                loading={false}
                pagination={{
                  total: dnnDetailResponse?.total_pages
                    ? dnnDetailResponse.total_pages * parseInt(searchParams.get('limit') as string) || 10
                    : 0,
                  showSizeChanger: true,
                  pageSizeOptions: ['1', '10', '20', '50', '100'],
                  pageSize: parseInt(searchParams.get('limit') as string) || 10,
                  current: parseInt(searchParams.get('offset') as string) + 1 || 1,
                  onChange: (current, pageSize) => {
                    handlePageChange(current, pageSize);
                  }
                }}
                bordered
                dataSource={dnnDetailResponse?.dnn_postcodes || []}
                columns={coloumns}
                scroll={{ x: 1000 }}
              ></Table>
            </section>
            <Row gutter={[12, 12]} className="mt-4">
              <Col xs={24} md={8} lg={6}>
                <Button
                  block
                  size="large"
                  onClick={() => {
                    if (searchParams.get('backUrl')) {
                      navigate(searchParams.get('backUrl') as string);
                    } else {
                      navigate('/logistics/dnn');
                    }
                  }}
                >
                  <BoldButtonLabel labelText="Go Back" />
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>

      <Modal
        centered
        open={showUploadPostcodeModal}
        onCancel={() => {
          setShowUploadPostcodeModal(false);
          closePostCodeModal();
        }}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Upload Postcodes</Typography.Title>
          </div>
        }
      >
        <section className="flex flex-col justify-center">
          <Button type="link" className="mb-4" onClick={() => {}}>
            Download Sample File
          </Button>
          <Dragger
            beforeUpload={file => {
              setPostcodeFiles([file]);
              return false;
            }}
            onRemove={() => {
              setPostcodeFiles([]);
            }}
            fileList={postcodeFiles}
            name="file"
            accept={'.csv,.xlsx'}
          >
            <p>
              <CloudUploadOutlined className="text-9xl text-[#5885AF]" />
            </p>
            <p>Click or Drag Postcodes Excel File to upload</p>
          </Dragger>

          <Button
            disabled={_.isEmpty(postcodeFiles)}
            block
            size="large"
            type="primary"
            onClick={handlePostcodeFileUpload}
            className="mt-4"
          >
            <BoldButtonLabel labelText="Validate File" />
          </Button>
        </section>

        {Object.keys(excelResult).length > 1 && (
          <div className="flex flex-col mt-4">
            <Typography.Title level={5} className="text-red-500">
              File has been processed and below are the results
            </Typography.Title>
            <Typography.Text className="text-red-500">
              Uploaded File duplicate Entries : {excelResult.total_duplicate}
            </Typography.Text>
            <Typography.Text className="text-red-500">Total Failed: {excelResult.total_failed}</Typography.Text>
            <Typography.Text className="text-blue-500">
              Total uploaded postcodes: {excelResult.total_line_items}
            </Typography.Text>
            <Typography.Text className="text-green-500">Total Success: {excelResult.total_success}</Typography.Text>
            <Row className="mt-4" gutter={[12, 12]}>
              <Col xs={12}>
                <Button
                  block
                  size="large"
                  type="primary"
                  onClick={() => {
                    handleOnUploadExcelData();
                  }}
                  disabled={excelResult.total_success < 1}
                >
                  <BoldButtonLabel labelText="Upload File" />
                </Button>
              </Col>{' '}
              <Col xs={12}>
                <Button size="large" block type="primary" onClick={getReportData}>
                  <BoldButtonLabel labelText="Download Report" />
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Modal>

      <Modal
        width={900}
        centered
        open={showManualPostcodeModal}
        onCancel={closePostCodeModal}
        footer={false}
        title={
          <div className="flex justify-between gap-4">
            <Typography.Title level={4}>Add Postcodes</Typography.Title>
          </div>
        }
      >
        <Form layout="vertical" form={manualPostcodeForm} onFinish={handleOnPostCodeEntry}>
          {/* <div style={{ height: 'calc(100vh - 420px)', overflowX: 'hidden', overflowY: 'auto' }}> */}
          <Form.List name="postcodes">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <React.Fragment key={key}>
                      <Row>
                        <Col xs={24}>
                          <Divider orientation="left" plain>
                            <span className="text-lg">Postcode - {index + 1} </span>
                          </Divider>
                        </Col>
                      </Row>

                      <Row gutter={12}>
                        <Col xs={24} md={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'postcode']}
                            rules={[{ required: true, message: 'Postcode is required' }]}
                            label="Postcode"
                          >
                            <Input className="w-full" size="large" placeholder="Enter postcode"></Input>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item {...restField} name={[name, 'is_active']} label="Is Active?">
                            <Switch checkedChildren="Yes" unCheckedChildren="No"></Switch>
                          </Form.Item>
                        </Col>

                        <div className="ml-2 my-2 flex justify-center items-center">
                          <DeleteOutlined
                            className="text-red-500 text-xl"
                            title="Delete postcode"
                            onClick={() => remove(name)}
                          />
                        </div>
                      </Row>
                    </React.Fragment>
                  );
                })}

                <Form.Item>
                  <Row justify={'start'}>
                    <Col xs={24} md={4}>
                      <Button onClick={() => add()} icon={<PlusOutlined />}>
                        <BoldButtonLabel labelText="Add postcode" />
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </>
            )}
          </Form.List>
          {/* </div> */}
          <Row>
            <Col xs={24} md={6}>
              <Button type="primary" size="large" block htmlType="submit">
                <BoldButtonLabel labelText="Submit" />
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </PrimaryLayout>
  );
};

export default DnnManagePostCode;
