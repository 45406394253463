import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, Tooltip } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import { useLoader } from '../../../stores/use-loader';
import { promotionService } from '../services/promotion.service';

interface IDiscountTypePriorityProps {
  applicationMethod: string;
  cpcActiveTab: string;
  permission: boolean;
}

type PromotionType = {
  label: string;
  key: string;
  value: string;
  priority: number;
};

const PromotionSettings: React.FunctionComponent<IDiscountTypePriorityProps> = ({
  applicationMethod,
  cpcActiveTab,
  permission
}) => {
  const [promotionPriorities, setPromotionPriorities] = React.useState<any>([]);
  const [originalData, setOriginalData] = React.useState([] as PromotionType[]);
  // const [isUpdate, setIsUpdate] = React.useState(false);
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const { t } = useTranslation();

  const defaultPrio = [
    { label: t('specialPrice'), key: 'SPL_PRICE', value: 'SPL_PRICE', priority: 0 },
    { label: t('absoluteDiscount'), key: 'FIXED', value: 'FIXED', priority: 1 },
    { label: t('percentageDiscount'), key: 'PERCENTAGE', value: 'PERCENTAGE', priority: 2 }
  ];

  const stackingPriority = [
    { label: t('percentageDiscount'), key: 'PERCENTAGE', value: 'PERCENTAGE', priority: 0 },
    { label: t('absoluteDiscount'), key: 'FIXED', value: 'FIXED', priority: 1 }
  ];
  React.useEffect(() => {
    fetchPromotionPriorities();
  }, [applicationMethod, cpcActiveTab]);

  const fetchPromotionPriorities = async () => {
    setLoading(true);
    await getControlSettings();
    setLoading(false);
  };

  const getControlSettings = async () => {
    setLoading(true);
    const { data, errors } = await promotionService.getControlSettings();
    if (_.isEmpty(errors)) {
      let pickData = data?.find((item: any) => item?.param_name === 'combine_promo_strategy');
      let getPriority = data?.filter((item: any) => item?.param_category === 'DISCOUNT_TYPE_PRIORITY');
      let activePromotion = pickData?.param_value;
      let arr_data: any = null;
      if (activePromotion === 'SEQUENTIAL') {
        let newStackingPriority = defaultPrio?.map(sp => {
          const matchingPriority = getPriority?.find((gp: any) => gp?.param_name === sp?.key);
          if (matchingPriority) {
            sp.priority = Number(matchingPriority?.param_value);
          }
          return sp;
        });
        newStackingPriority = newStackingPriority?.sort((a, b) => a?.priority - b?.priority);
        arr_data = [...newStackingPriority];
      } else {
        let newStackingPriority = stackingPriority?.map(sp => {
          const matchingPriority = getPriority?.find((gp: any) => gp?.param_name === sp?.key);
          if (matchingPriority) {
            sp.priority = Number(matchingPriority?.param_value);
          }
          return sp;
        });
        newStackingPriority = newStackingPriority?.sort((a, b) => a?.priority - b?.priority);
        arr_data = [...newStackingPriority];
      }
      setOriginalData([...arr_data]);
      setPromotionPriorities([...arr_data]);
    }
    setLoading(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = React.useState([] as string[]);

  const rowSelection: TableRowSelection<PromotionType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: (keys: any) => {
      setSelectedRowKeys(keys);
    }
  };

  const moveRow = (value: string, direction: 'up' | 'down') => {
    setPromotionPriorities((promotionPriorities: any) => {
      const newData = [...promotionPriorities];
      const index = newData.findIndex(item => item.value === value);

      if (index === -1) {
        return promotionPriorities;
      }
      const [movedItem] = newData.splice(index, 1);
      const targetIndex = direction === 'up' ? index - 1 : index + 1;
      newData.splice(targetIndex, 0, movedItem);
      const transformedData = newData.map((data, index) => ({ ...data, priority: index }));

      return transformedData;
    });
    setSelectedRowKeys([value]);
  };

  const findRowIndexByType = (value: string): number => {
    return promotionPriorities.findIndex((item: any) => item.value === value);
  };

  const indexOfSelectedRadio = findRowIndexByType(selectedRowKeys[0]);

  const isUpDisabled = indexOfSelectedRadio === 0;
  const isDownDisabled = indexOfSelectedRadio === promotionPriorities.length - 1;

  const handleReset = () => {
    setSelectedRowKeys([]);
    getControlSettings();
  };

  const handleSave = async () => {
    setLoading(true);
    let bodyData = promotionPriorities.map((item: any) => {
      return {
        param_category: 'DISCOUNT_TYPE_PRIORITY',
        param_name: item?.key,
        param_value: item?.priority
      };
    });
    const { errors } = await promotionService.patchControlSettings(bodyData);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('discountTypePrioritiesUpdated') });
      setSelectedRowKeys([]);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <div>
      <Row gutter={[12, 12]} className="mt-4">
        <Col xs={22} md={12}>
          <Table
            bordered
            rowSelection={permission ? rowSelection : undefined}
            columns={[
              {
                title: t('discountType'),
                render(value, record) {
                  return <>{record.label}</>;
                }
              },
              {
                title: t('priority'),
                render(value, record) {
                  return <>{record.priority}</>;
                }
              }
            ]}
            dataSource={promotionPriorities}
            pagination={false}
          />
        </Col>
        {!_.isEmpty(selectedRowKeys) && (
          <Col xs={2} md={12}>
            <section className="flex flex-col gap-4 h-full justify-center items-start">
              <div>
                <Tooltip title={t('moveUp')}>
                  <Button
                    disabled={isUpDisabled}
                    type="ghost"
                    onClick={() => moveRow(selectedRowKeys[0], 'up')}
                    icon={<CaretUpOutlined style={{ fontSize: '32px' }} />}
                  />
                </Tooltip>
              </div>
              <div>
                <Tooltip title={t('moveDown')}>
                  <Button
                    disabled={isDownDisabled}
                    type="ghost"
                    onClick={() => moveRow(selectedRowKeys[0], 'down')}
                    icon={<CaretDownOutlined style={{ fontSize: '32px' }} />}
                  />
                </Tooltip>
              </div>
            </section>
          </Col>
        )}
      </Row>
      {permission && (
        <Row className="mt-8" gutter={[12, 12]}>
          <Col xs={12} md={4}>
            <Button type="primary" onClick={handleSave} block size="large" disabled={selectedRowKeys.length === 0}>
              <BoldButtonLabel labelText={t('save')} />
            </Button>
          </Col>
          <Col xs={12} md={4}>
            <Button block size="large" onClick={handleReset}>
              <BoldButtonLabel labelText={t('reset')} />
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PromotionSettings;
