import * as React from 'react';
import { sideMenuConfig } from '../config';

import { FileProtectOutlined, LeftOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import { IconHelp, IconLogout, IconMenu, IconUser } from '@tabler/icons';
import {
  Button,
  Card,
  Drawer,
  Dropdown,
  Layout,
  Menu,
  MenuProps,
  Modal,
  Select,
  Spin,
  Table,
  Tooltip,
  Typography
} from 'antd';
import { Footer } from 'antd/es/layout/layout';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SideMenuIcon from '../components/SideMenuIcon';
import { findUrlForSideBarSelection, ISideMenuItemType, routeToMenuKeyMap } from '../config/sideMenuConfig';
import { localeEjjeList, localeList } from '../data/intl-data';
import { useAuth, useTenant } from '../stores/use-auth';
import { useLoader } from '../stores/use-loader';
import { useLocale } from '../stores/use-locale';
import { useSideMenu } from '../stores/use-sidemenu';

import { useTranslation } from 'react-i18next';
import { ENV, TENANT_DOMAIN_INDEX } from '../config/envConfig';
import { FEATURE_FLAGS } from '../feature-flags';
import { urlHelpers } from '../helpers';
import _ from '../helpers/lodash';
import i18n from '../i18n';
import { loggingIn } from '../services/login.services';
import { images } from '../utils/images';

const { Header, Content, Sider } = Layout;

interface IPrimaryLayoutProps {
  children: React.ReactNode;
}

const PrimaryLayout: React.FunctionComponent<IPrimaryLayoutProps> = ({ children }) => {
  const location = useLocation();
  const urlPart: string = location.pathname || '';
  //!First element is "/"
  const url: string = findUrlForSideBarSelection(urlPart);
  const { t } = useTranslation();

  //!Need to refactor Demo specific

  const selectedKey = routeToMenuKeyMap[url as keyof typeof routeToMenuKeyMap]?.menuKey;
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const [helpModal, setHelpModal] = React.useState(false);
  const [useRole, setUserRole] = React.useState(false);
  const [roleData, setRoleData] = React.useState<any>([]);

  const { locale, setUserLocale } = useLocale(({ locale, setUserLocale }) => ({ locale, setUserLocale }));
  const { loading, setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const { sideMenuCollapsedDesktop, toggleDesktopSideMenuExpanded } = useSideMenu();
  const { isLoggedIn, resetAuthStates } = useAuth(({ isLoggedIn, resetAuthStates }) => ({
    isLoggedIn,
    resetAuthStates
  }));
  const navigate = useNavigate();

  const { tenentName, userName, roleAccess } = useAuth(({ tenentName, userName, roleAccess }) => ({
    tenentName,
    userName,
    roleAccess
  }));

  const { userRoles, member_of, resourceAccess } = useTenant(
    ({ userRoles, setUserRole, member_of, resourceAccess }) => ({
      userRoles,
      setUserRole,
      member_of,
      resourceAccess
    })
  );

  const detailedUserRole = (userRoles || []).filter((cv: string) => {
    return !(cv?.includes('default') || cv?.includes('offline') || cv?.includes('uma'));
  });

  React.useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
      navigate(0);
    }
  }, [isLoggedIn]);
  React.useEffect(() => {
    setUserLocale(locale);
  }, []);
  // const translateMenuLabels = (menuList: any) => {
  //   menuList.forEach((item: any) => {
  //     item.label = t(item.label);
  //     if (item.children) {
  //       translateMenuLabels(item.children);
  //     }
  //   });
  // };

  // i18n.on('languageChanged', () => translateMenuLabels(sideMenuConfig?.sideMenu?.sideMenuList));

  const getAccessKeysFromMemberOf = (memberOf: string[]): string[] => {
    if (_.isEmpty(memberOf)) return [];
    let accessKeys: string[] = ['home'];
    memberOf.forEach(str => {
      const parts = str.split('/');
      if (Array.isArray(parts) && parts[2]) {
        accessKeys.push(parts[2].toUpperCase());
      } // Assuming it is  present in second part
    });

    const { tenantId } = urlHelpers.getTenantIdAndBusinessGroupIdFromUrl();
    if (resourceAccess && (resourceAccess?.[tenantId]?.roles || [])?.includes('EO:DATA-MANAGEMENT')) {
      accessKeys.push('data-management');
    }

    return [...accessKeys];
  };

  const accessKeys = [...getAccessKeysFromMemberOf(member_of), ...(userRoles || [])];
  const { activeKeys, setActiveKeys } = useSideMenu(({ activeKeys, setActiveKeys }) => ({ activeKeys, setActiveKeys }));
  const onOpenChange: MenuProps['onOpenChange'] = keys => {
    setActiveKeys(keys);
  };

  const recursivelyRenderMenuItem = (
    { label, id, route, children, icon, hidden }: ISideMenuItemType,
    level = 0,
    forMobile = false
  ): ItemType => {
    //!Check for children -->
    if (hidden) return null;
    let childMenuItems: ItemType[] = [];

    if (children?.length) {
      children.forEach(({ id, label, route, children, icon, hidden }) => {
        childMenuItems.push(
          recursivelyRenderMenuItem({ label: t(label), id, route, children, icon, hidden }, level + 1, forMobile)
        );
      });
    }

    const baseComponent = (
      <div className="h-full flex items-center ">
        {route && (
          <Link to={route}>
            <Typography
              className={`text-${
                sideMenuCollapsedDesktop && !children?.length && level === 0 && !forMobile ? 'white' : 'black'
              } font-[Inter] text-[14px] font-[400] leading-[22px] tracking-[0em] text-[left] text-white`}
            >
              {label}
            </Typography>
          </Link>
        )}

        {!route && (
          <Typography className="font-[Inter] text-[14px] font-[400] leading-[22px] tracking-[0em] text-[left] text-white">
            {label}
          </Typography>
        )}
      </div>
    );

    return {
      key: id,

      icon: icon ? (
        <Tooltip placement="top" overlayInnerStyle={{ fontWeight: 'bolder' }} title={label}>
          <div>
            <SideMenuIcon iconKey={icon} />
          </div>
        </Tooltip>
      ) : null,
      label: !sideMenuCollapsedDesktop ? (
        <Tooltip placement="right" title={label}>
          {baseComponent}
        </Tooltip>
      ) : (
        <>{baseComponent}</>
      ),
      ...(childMenuItems.length && { children: childMenuItems })
    };
  };

  const sideMenuItems: MenuProps['items'] = sideMenuConfig.sideMenu.sideMenuList.map(
    ({ label, id, route, children, icon, accessKey, hidden }) => {
      if (hidden) return null;

      const url = `${window.location.host}`.replace('www.', '');
      const urlParts = url.split('.');
      const tenantIndex = parseInt(TENANT_DOMAIN_INDEX as string);
      const tenantId = urlParts[tenantIndex];
      if (tenantId === 'ejje') {
        children?.forEach((data: any) => {
          if (resourceAccess && (resourceAccess?.ejje?.roles || [])?.includes(data?.id)) {
            data.hidden = false;
          } else {
            data.hidden = true;
          }
        });
      } else if (tenantId === 'rmg' || urlParts[0] === 'localhost:3000') {
        children?.forEach((data: any) => {
          if (
            resourceAccess &&
            ((resourceAccess?.rmg?.roles || [])?.includes(data?.id) ||
              (resourceAccess?.['rmg-uat']?.roles || [])?.includes(data?.id))
          ) {
            data.hidden = false;
          } else {
            data.hidden = true;
          }
        });
      } else if (tenantId === 'bumblegifts') {
        //|| tenantId === 'localhost:3000'
        children?.forEach((data: any) => {
          if (resourceAccess && (resourceAccess?.bumblegifts?.roles || [])?.includes(data?.id)) {
            data.hidden = false;
          } else {
            data.hidden = true;
          }
        });
      } else if (tenantId === 'sparta') {
        children?.forEach((data: any) => {
          if (resourceAccess && (resourceAccess?.sparta?.roles || [])?.includes(data?.id)) {
            data.hidden = false;
          } else {
            data.hidden = true;
          }
        });
      } else if (tenantId === 'unicomer') {
        children?.forEach((data: any) => {
          if (resourceAccess && (resourceAccess?.unicomer?.roles || [])?.includes(data?.id)) {
            data.hidden = false;
          } else {
            data.hidden = true;
          }
        });
      } else if (tenantId === 'qalara') {
        children?.forEach((data: any) => {
          if (resourceAccess && (resourceAccess?.qalara?.roles || [])?.includes(data?.id)) {
            data.hidden = false;
          } else {
            data.hidden = true;
          }
        });
      }

      if (ENV === 'PRODUCTION') {
        let accessData = (accessKeys || []).includes(accessKey || ('' as string));
        if (!FEATURE_FLAGS.ENABLE_SIDE_MENU_AUTHORIZATION && accessData) {
          return recursivelyRenderMenuItem({ id, label: t(label), route, children, icon, hidden });
        } else {
          return null;
        }
      }
      if (!FEATURE_FLAGS.ENABLE_SIDE_MENU_AUTHORIZATION || accessKeys.includes(accessKey as string) || !accessKey)
        return recursivelyRenderMenuItem({ id, label: t(label), route, children, icon, hidden });
      else return null;
    }
  );

  const sideMenuItemsForMobile = sideMenuConfig.sideMenu.sideMenuList.map(
    ({ label, id, route, children, icon, accessKey, hidden }) => {
      if (hidden) return null;

      if (ENV === 'PRODUCTION') {
        let accessData = (accessKeys || []).includes(accessKey || ('' as string));
        if (!FEATURE_FLAGS.ENABLE_SIDE_MENU_AUTHORIZATION && accessData) {
          return recursivelyRenderMenuItem({ id, label: t(label), route, children, icon, hidden });
        } else {
          return null;
        }
      }
      if (!FEATURE_FLAGS.ENABLE_SIDE_MENU_AUTHORIZATION || accessKeys.includes(accessKey as string) || !accessKey)
        return recursivelyRenderMenuItem({ id, label: t(label), route, children, icon, hidden });
      else return null;
    }
  );
  const handleOnLogOut = async () => {
    setLoading(true);
    const { errors } = await loggingIn.handleLogOut(userName);
    if (_.isEmpty(errors)) {
      resetAuthStates();
    }
    setLoading(false);
  };

  const { tenantId } = urlHelpers.getTenantIdAndBusinessGroupIdFromUrl();

  const profileComponent = (
    <Dropdown
      className="profile-dropdown"
      trigger={['click']}
      menu={{
        items: [
          {
            key: '33',
            label: (
              <>
                {' '}
                {t('retailer')}- {tenentName}
              </>
            )
          },

          {
            key: '332',
            label: (
              <div
                className="flex items-center gap-2"
                onClick={() => {
                  const transformedDataSource =
                    roleAccess &&
                    Object.entries(roleAccess).map(([key, value]) => {
                      return { product: Object.keys(value)[0], access: Object.values(value)[0] };
                    });
                  setRoleData(transformedDataSource);
                  setUserRole(true);
                }}
              >
                {/* {t('userRole')} */}
                <div className="mt-[5px]">
                  <FileProtectOutlined size={14} />
                </div>{' '}
                <div> {t('userRole')}</div>
              </div>
            )
          },
          {
            key: '1',
            label: (
              <div
                className="flex items-center gap-2"
                onClick={() => {
                  navigate('/My-Account');
                }}
              >
                <div className="mt-[5px]">
                  <IconUser size={14} />
                </div>{' '}
                <div> {t('myAccount')}</div>
              </div>
            )
          },
          {
            key: '2',
            label: (
              <div className="flex items-center gap-2" onClick={() => setHelpModal(true)}>
                <div className="mt-[5px]">
                  <IconHelp size={14} />
                </div>{' '}
                <div>{t('help')}</div>
              </div>
            )
          },
          {
            key: '3',
            label: (
              <div className="flex items-center gap-2" role="button" onClick={() => handleOnLogOut()}>
                <div className="mt-[5px]">
                  <IconLogout size={14} />
                </div>{' '}
                <div>{t('logout')}</div>
              </div>
            )
          }
        ]
      }}
    >
      <div>
        <span className="text-base font-semibold">
          {' '}
          <Button type="ghost">
            <IconUser color="white" />
          </Button>
        </span>{' '}
      </div>
    </Dropdown>
  );

  const selectLocale = (
    <div className="flex items-center gap-2">
      <Select
        // value={locale}
        value={locale}
        onChange={value => {
          i18n.changeLanguage(value);
          setUserLocale(value);
        }}
        className="w-[90px] bg-[#002626] text-white region-selector"
        style={{ color: 'white!important', width: 'fit-content' }}
        options={localeEjjeList}
        // suffixIcon={<GlobalOutlined style={{ fontSize: '23px', color: 'white' }} />}
      />
    </div>
  );

  return (
    <>
      <Header className="header-nebula ">
        <section className="hidden lg:flex header__wrapper bg-red">
          <div className="flex items-center gap-5">
            <div className="header__logo">
              <Link to="/" style={{ textDecoration: 'none' }}>
                <span className="text-lg text-white font-[Inter] font-medium flex align-center">
                  {' '}
                  <img src={images?.newcbtlogo} alt="logo" className="w-[30px] object-contain" />
                  &nbsp;Clover Bay Labs
                </span>
              </Link>
            </div>
          </div>
          <div className="header__items">
            {selectLocale}
            {profileComponent}
          </div>
        </section>
        <section className="lg:hidden w-full ">
          <div className="h-full flex items-center justify-between w-full bg-[#002626]">
            <div className="my-auto ml-4" role={'button'} onClick={() => setMobileDrawerOpen(true)}>
              <IconMenu color="white" size={32} />
            </div>
            <div className="flex items-center gap-5">
              <img src={images?.newcbtlogo} alt="logo" style={{ height: '30px' }} />
              <div className="header__logo">
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <span className="text-lg text-white font-[Inter] font-medium">Clover Bay Labs</span>
                </Link>
              </div>
            </div>
            <div className="mr-4">{profileComponent}</div>
          </div>

          <Drawer
            width={380}
            className=""
            style={{
              backgroundColor: '#0b3d3d'
            }}
            title={
              <div className="flex justify-between ">
                <div>
                  <h2 className="text-white text-lg font-medium">Clover Bay Labs</h2>
                </div>
                {selectLocale}
              </div>
            }
            placement="left"
            onClose={() => setMobileDrawerOpen(false)}
            open={mobileDrawerOpen}
          >
            <Menu
              defaultSelectedKeys={[selectedKey]}
              style={{
                backgroundColor: '#0b3d3d'
              }}
              mode="inline"
              selectedKeys={[routeToMenuKeyMap[url as keyof typeof routeToMenuKeyMap]?.menuKey]}
              items={sideMenuItemsForMobile}
            />
          </Drawer>
        </section>
      </Header>
      <div className="flex">
        <Sider
          width={250}
          collapsible
          trigger={
            <section className="bg-[#0B3D3D] h-[50px]">
              <Button
                block
                onClick={() => {
                  toggleDesktopSideMenuExpanded();
                }}
                className="w-full rounded-none py-4"
                type="ghost"
                title={sideMenuCollapsedDesktop ? t('maximize') : t('minimize')}
                icon={
                  sideMenuCollapsedDesktop ? (
                    <RightOutlined style={{ color: 'white', fontSize: '16px' }} />
                  ) : (
                    <LeftOutlined style={{ color: 'white', fontSize: '16px' }} />
                  )
                }
              ></Button>
            </section>
          }
          collapsed={sideMenuCollapsedDesktop}
          style={{ height: `calc(100vh - 3rem)` }}
          className="hidden overflow-y-auto overflow-x-hidden lg:flex flex-col justify-between relative bg-[#0B3D3D]"
        >
          <div className="h-[calc(100vh-140px)] overflow-auto">
            <Menu
              className="py-2 bg-[#0B3D3D] border-r-0"
              style={{
                borderInlineEnd: '0px'
              }}
              onOpenChange={onOpenChange}
              mode="inline"
              selectedKeys={[routeToMenuKeyMap[url as keyof typeof routeToMenuKeyMap]?.menuKey]}
              openKeys={activeKeys}
              theme="light"
              items={sideMenuItems}
            />
          </div>
        </Sider>

        <Content
          id="main-content"
          style={{
            height: `calc(100vh - 3rem)`,
            background: '#f0f2f5',
            overflowY: 'auto',
            overflowX: 'hidden',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '1rem'
          }}
        >
          {children}
          {loading && (
            <div
              className="absolute top-0 left-0 h-full flex justify-center items-center w-full bg-black opacity-40"
              style={{ zIndex: 1001 }}
            >
              <Spin size="large" spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />} />
            </div>
          )}
          <Footer style={{ textAlign: 'center', padding: '2rem 0 1rem 0', textAlignLast: 'end', marginTop: 'auto' }}>
            <div className="mr-4">
              {' '}
              <img src={images?.newcbtlogo} alt="logo" style={{ height: '25px', objectFit: 'contain' }} />
              <span className="mix-blend-difference text-[#7d7d7d] font-[Inter] font-medium text-[16px] ml-2 pt-2">
                {t('poweredByCloverBayLabs')}
              </span>
            </div>
          </Footer>
        </Content>
        <Modal
          centered
          open={helpModal}
          onCancel={() => {
            setHelpModal(false);
          }}
          width={600}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>{t('needHelp')}</Typography.Title>
            </div>
          }
        >
          <Card>
            <Typography.Text className="text-[#000]">{t('forSupportOrAssistancePleaseContactUs')}</Typography.Text>
            <div className={'mt-4'}>
              <div>
                <Typography.Text className="text-[#000] font-bold">{t('email')} :</Typography.Text>
                <Typography.Text className="text-[#000]">{'manjunath@cloverbaytechnologies.com'}</Typography.Text>
              </div>
              <div>
                <Typography.Text className="text-[#000] font-bold">{t('phone')} :</Typography.Text>
                <Typography.Text className="text-[#000]">{'(+91) 9448000108'}</Typography.Text>
              </div>
            </div>
            <div className="mt-4">
              <Typography.Text className="text-[#5e5f66]">{t('ourTeamIsReadyToAssistYou')}</Typography.Text>
            </div>
          </Card>
        </Modal>
        <Modal
          centered
          open={useRole}
          onCancel={() => {
            setUserRole(false);
          }}
          width={900}
          footer={false}
          title={
            <div className="flex justify-between gap-4">
              <Typography.Title level={4}>{t('userRole')}</Typography.Title>
            </div>
          }
        >
          {/* <Card> */}
          {/* {roleAccess &&
              Object.entries(roleAccess).map(([key, value]) => {
                debugger;
                return (
                  <div key={key}>
                    <strong>{Object.keys(value)[0]}</strong>: {Object.values(value)[0]}
                  </div>
                );
              })} */}
          <section className="mt-2">
            {' '}
            <Table
              loading={false}
              pagination={false}
              bordered
              dataSource={roleData}
              columns={[
                {
                  title: t('product'),
                  align: 'center',
                  width: 300,
                  render(value, record, index) {
                    return <>{record?.product}</>;
                  }
                },
                {
                  title: t('access'),
                  align: 'center',
                  render(value, record, index) {
                    return <>{record?.access}</>;
                  }
                }
              ]}
              scroll={{ x: 300 }}
            ></Table>
          </section>
          {/* </Card> */}
        </Modal>
      </div>
    </>
  );
};

export default PrimaryLayout;
