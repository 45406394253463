import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
  TableColumnsType,
  Typography
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/ActionButton';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import { profileService } from '../../../../services';
import { useLoader } from '../../../../stores/use-loader';
import { IProfileDetailsResponse } from '../../../../types/profile.types';
import { encryptData, readableString } from '../../../../constants/common-constants';
import { promotionService } from '../../../../modules/promotion-engine/services/promotion.service';

interface IChannelSettings {
  active: boolean;
  profileDetails: IProfileDetailsResponse;
}

interface Channel {
  channel_id: string;
  country_code: string;
  org_code: string;
  channel_type: string;
  channel_name: string;
  logo_url: string;
  currencies: string[];
  languages: string[];
  timezones: string[];
  is_active: boolean;
}

const ChannelSettings: React.FunctionComponent<IChannelSettings> = ({ active, profileDetails }) => {
  const [channelForm] = useForm();
  const [editChannelForm] = useForm();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [addConfiguration, setAddConfiguration] = React.useState(false);
  const [tenentDetails, setTenentDetails] = React.useState<any>([]);
  const [countryCode, setCountryCode] = React.useState<any>([]);
  const [channelDataFromCountry, setChannelDataFromCountry] = React.useState<any>([]);
  const [channelTypeOptions, setChannelTypeOptions] = React.useState<any>([]);
  const [channelNameOptions, setChannelNameOptions] = React.useState<any>([]);
  const [channelCurrencyOptions, setChannelCurrencyOptions] = React.useState<any>([]);
  const [languageOptions, setLanguageOptions] = React.useState<any>([]);
  const [editModal, setEditModal] = React.useState<any>(false);
  const [listOfCountryOptions, setListOfCountryOptions] = React.useState<any>([]);
  const [listOfStateOptions, setListOfStateOptions] = React.useState<any>([]);
  const [listOfPinCodeOptions, setListOfPinCodeOptions] = React.useState<any>([]);
  const [pagination, setPagination] = React.useState({
    pageSize: 10,
    showSizeChanger: false,
    showQuickJumper: false
  });
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const channel_settings: TableColumnsType<any> = [
    // {
    //   title: t('channelId'),
    //   align: 'center',
    //   render(value: any, record: any, index: any) {
    //     return <>{record?.channel_id}</>;
    //   }
    // },
    {
      title: t('channelName'),
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = readableString(record?.channel_name);
        return <>{data}</>;
      }
    },
    {
      title: t('channelType'),
      align: 'center',
      render(value: any, record: any, index: any) {
        let data = readableString(record?.channel_type);
        return <>{data}</>;
      }
    },
    {
      title: t('countryCode'),
      align: 'center',
      render(value: any, record: any, index: any) {
        return <>{record?.country_code}</>;
      }
    },
    {
      title: t('createdAt'),
      align: 'center',
      render(value: any, record: any, index: any) {
        const formattedStartDateTime = dayjs(`${record?.creation_at}`).format('DD-MMM-YYYY hh:mm:ss a');
        return <div>{formattedStartDateTime}</div>;
      }
    },
    {
      title: t('updatedAt'),
      align: 'center',
      render(value: any, record: any, index: any) {
        const formattedStartDateTime = dayjs(`${record?.updated_at}`).format('DD-MMM-YYYY hh:mm:ss a');
        return <div>{formattedStartDateTime}</div>;
      }
    },
    {
      title: t('isActive'),
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <Popconfirm
              title={t(`areYouSureYouWantTo${record.is_active ? t('deactivate') : t('activate')}This`)}
              okText={t('ok')}
              cancelText={t('cancel')}
              onConfirm={() => handleStatusChange(record.is_active, record, record?.channel_name)}
            >
              <Switch
                size="default"
                checked={record?.is_active ? true : false}
                checkedChildren={t('yes')}
                unCheckedChildren={t('no')}
              />
            </Popconfirm>
          </>
        );
      }
    },
    {
      title: t('action'),
      align: 'center',
      render(value: any, record: any, index: any) {
        return (
          <>
            <section className="flex justify-center">
              <ActionButton
                action="CREATE_NEW_VERSION"
                title={t('edit')}
                onClick={() => {
                  handleOnEdit(record);
                }}
              ></ActionButton>
            </section>
          </>
        );
      }
    }
  ];

  React.useEffect(() => {
    if (active) {
      loadIntialData();
    }
  }, [active]);

  const loadIntialData = () => {
    setLoading(true);
    let countryCodeData = profileDetails?.tenant_details?.country_details?.map((details: any) => {
      return { label: details?.country_code, value: details?.country_code };
    });
    setCountryCode(countryCodeData);
    const currencySet = new Set();
    profileDetails?.tenant_details?.country_details?.forEach(country => {
      currencySet.add(country?.base_currency);
      country?.other_supported_currencies?.forEach(supported => {
        currencySet.add(supported?.supported_currency);
      });
    });
    const uniqueCurrencies = Array.from(currencySet).map(currency => ({
      label: currency,
      value: currency
    }));
    setChannelCurrencyOptions(uniqueCurrencies);
    channelForm.setFieldsValue({
      same_address: true,
      channel_status: false
    });
    loadChannelData();
    getAllCountries();
    setLoading(false);
  };

  const getAllCountries = async () => {
    const { data, errors } = await profileService.getAllCountry();
    if (_.isEmpty(errors)) {
      let loadData = data?.map((item: any) => {
        return { label: item?.country_name, value: item?.country_name, countryCode: item?.country_code };
      });
      setListOfCountryOptions(loadData);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getSelectedStateOfCountry = async (countryCode: any) => {
    setLoading(true);
    const { data, errors } = await profileService.getStateBasedOnCountry(countryCode);
    if (_.isEmpty(errors)) {
      let loadData = data?.map((item: any) => {
        return { label: item?.state_name, value: item?.state_name, stateCode: item?.state_code };
      });
      setListOfStateOptions(loadData);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const getListOfPincode = async (countryCode: any, stateCode: any) => {
    setLoading(true);
    const { data, errors } = await profileService.getPinCode(countryCode, stateCode);
    if (_.isEmpty(errors)) {
      let loadData = data?.map((item: any) => {
        return { label: item?.postcode, value: item?.postcode };
      });
      setListOfPinCodeOptions(loadData);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const updateTenantDetails = (newData: any) => {
    const updatedTenentDetails = tenentDetails.map((tenant: any) =>
      tenant.channel_id === newData.channel_id ? newData : tenant
    );
    return updatedTenentDetails;
  };

  const handleStatusChange = async (isActive: boolean, record: any, channelName: string) => {
    setLoading(true);
    const bodyData = { channel_id: record.channel_id, is_active: !isActive };
    const { data, errors } = await profileService.updateStatus(bodyData);
    if (_.isEmpty(errors)) {
      let cName = readableString(channelName);
      let statusAction = data?.is_active ? 'Activated' : 'Inactivated';
      displaySuccessNotification({
        message: t('rewardUpdateFunction', { name: cName, action: statusAction })
          .replace('{name}', cName)
          .replace('{action}', statusAction)
      });
      let patchTenentDetails = updateTenantDetails(data);
      setTenentDetails(patchTenentDetails);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnDecrypt = async (data: any) => {
    if (data.integration_settings) {
      try {
        // Wait for the decryption operations to complete
        const decryptMID = await handleDecrypt(data.integration_settings.merchant_id);
        const decryptApiKey = await handleDecrypt(data.integration_settings.api_key);
        const decryptApiSecreateKey = await handleDecrypt(data.integration_settings.api_secret_key);
        setEditFields(data, decryptMID, decryptApiKey, decryptApiSecreateKey);
      } catch (error) {
        console.error('Error during decryption:', error);
      }
    } else {
      setEditFields(data, '', '', '');
    }
    setEditModal(true);
  };

  const handleDecrypt = (encryptedText: any) => {
    return promotionService
      .promoEncryption({ operation: 'decrypt' }, `${encryptedText}`)
      .then(({ data, errors }) => {
        if (_.isEmpty(errors)) {
          return data;
        } else {
          console.error('Decryption API errors:', errors);
          throw new Error('Decryption failed due to API errors');
        }
      })
      .catch(error => {
        console.error('Decryption failed:', error);
        throw error;
      });
  };

  const handleOnEdit = async (record: any) => {
    setLoading(true);
    const { data, errors } = await profileService.getChannelById(record.channel_id);
    if (_.isEmpty(errors)) {
      handleOnDecrypt(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnSaveEdit = async () => {
    setLoading(true);
    const bodyData = getBodyEditData();
    const { data, errors } = await profileService.editChannel(bodyData);
    if (_.isEmpty(errors)) {
      setTenentDetails(data?.channels);
      setEditModal(false);
      displaySuccessNotification({ message: 'Channel Updated Successfully!' });
      editChannelForm.resetFields();
      channelForm.resetFields();
      channelForm.setFieldsValue({
        same_address: true,
        channel_status: false
      });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const setEditFields = (data: any, decryptMID: any, decryptApiKey: any, decryptApiSecreateKey: any) => {
    let formValues = {
      fulfillment_settings: data.fulfilment_settings?.courier_provider,
      channel_id: data.channel_id,
      channel_name: data.channel_name,
      channel_type: data.channel_type,
      is_active: data.is_active,
      publish_from_online_node: data?.inventory_settings?.publish_from_online_node,
      online_node_id: data?.inventory_settings?.online_node_id,
      admin_url: data?.integration_settings?.admin_url,
      merchant_Id: decryptMID,
      api_key: decryptApiKey,
      api_secreate: decryptApiSecreateKey,
      first_name: data?.user_details?.first_name,
      middle_name: data?.user_details?.middle_name,
      last_name: data?.user_details?.last_name,
      same_address: data.user_details.is_channel_contact_same_as_profile,
      contact_type: data?.user_details?.contact_details?.contact_type,
      other_contact_type: data?.user_details?.contact_details?.other_contact_type,
      address_type: data?.user_details?.contact_details?.address_detail?.address_type,
      address_line_1: data?.user_details?.contact_details?.address_detail?.address_line1,
      address_line_2: data?.user_details?.contact_details?.address_detail?.address_line2,
      contact_country: data?.user_details?.contact_details?.address_detail?.country,
      contact_country_code: data?.user_details?.contact_details?.address_detail?.country_code,
      contact_city: data?.user_details?.contact_details?.address_detail?.city,
      contact_state: data?.user_details?.contact_details?.address_detail?.state,
      contact_state_code: data?.user_details?.contact_details?.address_detail?.state_code,
      contact_pincode: data?.user_details?.contact_details?.address_detail?.pincode,
      time_Zone: data?.user_details?.contact_details?.address_detail?.timezone,
      additional_attributes: data?.user_details?.contact_details?.additional_attributes || []
    };
    editChannelForm.setFieldsValue(formValues);
  };

  const getBodyEditData = () => {
    const {
      channel_id,
      is_active,
      api_key,
      merchant_Id,
      api_secreate,
      admin_url,
      publish_from_online_node,
      online_node_id,
      first_name,
      last_name,
      contact_type,
      address_type,
      address_line_1,
      address_line_2,
      contact_country,
      contact_country_code,
      contact_city,
      contact_state,
      contact_state_code,
      contact_pincode,
      time_Zone,
      same_address,
      additional_attributes,
      middle_name
    } = editChannelForm.getFieldsValue();

    // -----------------------------------------------------------------
    let encryptMerchant = merchant_Id?.length > 0 ? encryptData(merchant_Id) : null;
    let encryptApiKey = api_key?.length > 0 ? encryptData(api_key) : null;
    let encryptSecreate = api_secreate?.length > 0 ? encryptData(api_secreate) : null;
    // -----------------------------------------------------------------
    let bodyData = {
      channel_id: channel_id,
      is_active: is_active,
      integration_settings: is_active
        ? {
            api_key: encryptApiKey,
            merchant_id: encryptMerchant,
            api_secret_key: encryptSecreate,
            admin_url: admin_url?.length > 0 ? admin_url : null
          }
        : null,
      inventory_settings: {
        online_node_id: online_node_id,
        publish_from_online_node: publish_from_online_node
      },
      user_details: {
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        is_channel_contact_same_as_profile: same_address,
        contact_details: !same_address
          ? {
              contact_type: contact_type,
              address_detail: {
                address_type: address_type,
                address_line1: address_line_1,
                address_line2: address_line_2,
                country: contact_country,
                country_code: contact_country_code,
                location: '',
                coordinates: {
                  latitude: '',
                  longitude: ''
                },
                city: contact_city,
                state: contact_state,
                state_code: contact_state_code,
                pincode: contact_pincode,
                timezone: time_Zone
              },
              additional_attributes: !!additional_attributes ? additional_attributes : null
            }
          : null
      }
    };

    return bodyData;
  };

  const loadChannelData = () => {
    let profileData = profileDetails?.tenant_details?.channels;
    setTenentDetails(profileData);
  };

  const closeWarningModal = () => {
    setModalOpen(false);
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  const handleOnSaveChannel = async () => {
    // channelForm
    const fieldsToValidate: (string | (string | number)[])[] = [
      'country_code',
      'channel_type',
      'channel_name',
      'Channel_Id',
      'store_name',
      'languages',
      'currency',
      'fulfillment_settings',
      'online_node_id',
      'first_name',
      'middle_name',
      'last_name'
    ];
    if (!channelForm.getFieldsValue().same_address) {
      fieldsToValidate.push(
        'contact_type',
        'other_contact_type',
        'address_type',
        'address_line_1',
        'contact_country',
        'contact_country_code',
        'contact_city',
        'contact_state',
        'contact_state_code',
        'contact_pincode',
        'time_Zone'
      );
      channelForm.getFieldsValue()?.additional_attributes?.forEach((_: any, index: number) => {
        fieldsToValidate.push(['additional_attributes', index, 'attribute_name']);
        fieldsToValidate.push(['additional_attributes', index, 'attribute_value']);
        fieldsToValidate.push(['additional_attributes', index, 'attribute_type']);
      });
    }
    await channelForm.validateFields(fieldsToValidate);
    setModalOpen(true);
  };

  const handleOnConfirm = () => {
    handleOnSave();
    setModalOpen(false);
  };

  const handleOnAddConfiguration = () => {
    channelForm.setFieldsValue({
      channel_status: true
    });
    setAddConfiguration(true);
    setModalOpen(false);
  };

  const loadBodyData = () => {
    const {
      Channel_Id,
      channel_status,
      country_code,
      languages,
      currency,
      fulfillment_settings,
      merchant_Id,
      api_key,
      api_secreate,
      admin_url,
      same_address,
      first_name,
      last_name,
      contact_type,
      address_type,
      address_line_1,
      address_line_2,
      contact_country,
      contact_country_code,
      contact_city,
      contact_state,
      contact_state_code,
      contact_pincode,
      time_Zone,
      other_contact_type,
      additional_attributes,
      publish_from_online_node,
      online_node_id,
      middle_name
    } = channelForm.getFieldsValue();
    // -----------------------------------------------------------------
    let encryptMerchant = encryptData(merchant_Id);
    let encryptApiKey = encryptData(api_key);
    let encryptSecreate = encryptData(api_secreate);
    // -----------------------------------------------------------------
    let bodyData = {
      channel_id: Channel_Id,
      is_active: channel_status,
      country_code: country_code,
      currency_codes: currency,
      languages: languages,
      fulfilment_settings: {
        courier_provider: fulfillment_settings
      },
      integration_settings:
        merchant_Id?.length > 0
          ? {
              api_key: encryptApiKey, //api_key,
              merchant_id: encryptMerchant, // merchant_Id,
              api_secret_key: encryptSecreate, //api_secreate,
              admin_url: admin_url
            }
          : null,
      inventory_settings: {
        online_node_id: publish_from_online_node ? online_node_id : null,
        publish_from_online_node: publish_from_online_node ? true : false
      },
      user_details: {
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        is_channel_contact_same_as_profile: same_address,
        contact_details: !same_address
          ? {
              contact_type: contact_type,
              contact_type_other_name: contact_type === 'OTHER' ? other_contact_type : null,
              address_detail: {
                address_type: address_type,
                address_line1: address_line_1,
                address_line2: address_line_2,
                country: contact_country,
                country_code: contact_country_code,
                location: '',
                coordinates: {
                  latitude: '',
                  longitude: ''
                },
                city: contact_city,
                state: contact_state,
                state_code: contact_state_code,
                pincode: Number(contact_pincode),
                timezone: time_Zone
              },
              additional_attributes: !!additional_attributes ? additional_attributes : null
            }
          : null
      }
    };
    return bodyData;
  };

  const handleOnSave = async () => {
    setLoading(true);
    let bodyData = loadBodyData();
    const { data, errors } = await profileService.createChannel(bodyData);
    if (_.isEmpty(errors)) {
      setTenentDetails(data?.channels);
      displaySuccessNotification({ message: 'Channel Created Successfully' });
      channelForm.resetFields();
      channelForm.setFieldsValue({
        same_address: true,
        channel_status: false
      });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const OnSelectCountryCode = async () => {
    setLoading(true);
    const { country_code } = channelForm.getFieldsValue();
    let params = {
      'country-codes': country_code
    };
    const { data, errors } = await profileService.getChannel(params);
    if (_.isEmpty(errors)) {
      const activeChannels = data?.channels?.filter((item: any) => {
        return item.is_active;
      });
      setChannelDataFromCountry(activeChannels); //only which are Active
      loadChannelTypeData(activeChannels);
      const uniqueLanguage = Array.from(new Set(data.channels.flatMap((channel: Channel) => channel.languages)));
      let language_data = uniqueLanguage?.map((item: any) => {
        return { label: item, value: item };
      });
      setLanguageOptions(language_data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const loadChannelTypeData = (data: any) => {
    const uniqueChannelType = Array.from(new Set(data?.flatMap((channel: Channel) => channel.channel_type)));
    let ChannelName = uniqueChannelType?.map((item: any) => {
      return { label: item, value: item };
    });
    setChannelTypeOptions(ChannelName);
  };

  const loadChannelName = (searchString: any) => {
    const filteredData = channelDataFromCountry.filter((item: any) => item.channel_type === searchString);
    let ChannelName = filteredData?.map((item: any) => {
      return { label: item?.channel_name, value: item?.channel_name };
    });
    channelForm.setFieldsValue({
      channel_name: undefined,
      store_name: undefined,
      Channel_Id: undefined
    });
    setChannelNameOptions(ChannelName);
  };

  const loadChannelId = (selectedString: any) => {
    const filteredData = channelDataFromCountry.filter((item: any) => item.channel_name === selectedString);
    const channelId = filteredData[0]?.channel_id;
    channelForm.setFieldsValue({ Channel_Id: channelId });
  };

  return (
    <Form form={channelForm} layout="vertical" className="nebula-config" onFinish={handleOnSave}>
      <Card className="mt-6 p-0">
        <div className="pt-6 pr-6 flex justify-between">
          <h2 className="text-base text-gray-800 p-4 pl-6 country-details">{t('channelSettings')}</h2>
          <Row gutter={12}>
            <Col>
              <Form.Item name="channel_status" valuePropName="checked" label={t('channelStatus')}>
                <Switch
                  checkedChildren={t('active')}
                  unCheckedChildren={t('inactive')}
                  onChange={(status: any) => {
                    if (status) {
                      setAddConfiguration(true);
                    } else {
                      setAddConfiguration(false);
                    }
                  }}
                ></Switch>
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Row gutter={12} className="pt-0 px-8 pb-0">
          <Col xs={12} md={6}>
            <Form.Item
              label={t('countryCode')}
              name={'country_code'}
              rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
            >
              <Select
                size="large"
                placeholder={t('countryCode')}
                allowClear
                options={countryCode}
                onSelect={OnSelectCountryCode}
                onClear={() => {
                  channelForm.setFieldsValue({
                    channel_type: undefined,
                    channel_name: undefined,
                    store_name: undefined,
                    Channel_Id: undefined
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label={t('channelType')}
              name={'channel_type'}
              rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
            >
              <Select
                size="large"
                placeholder={t('channelType')}
                allowClear
                options={channelTypeOptions}
                onSelect={loadChannelName}
                onClear={() => {
                  channelForm.setFieldsValue({
                    channel_name: undefined,
                    store_name: undefined,
                    Channel_Id: undefined
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label={t('channelName')}
              name={'channel_name'}
              rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
            >
              <Select
                size="large"
                placeholder={t('channelName')}
                allowClear
                options={channelNameOptions}
                onClear={() => {
                  channelForm.setFieldsValue({ Channel_Id: undefined });
                }}
                onSelect={loadChannelId}
              />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label={t('channelId')}
              name={'Channel_Id'}
              rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
            >
              <Input size="large" placeholder={t('channelId')} disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12} className="pt-0 px-8 pb-0">
          <Col xs={12} md={6}>
            <Form.Item
              label={t('storeName')}
              name={'store_name'}
              rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
            >
              <Input placeholder={t('storeName')} size="large" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label={t('languages')}
              name={'languages'}
              rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
            >
              <Select mode="multiple" placeholder={t('languages')} allowClear options={languageOptions} size="large" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label={t('currency')}
              name={'currency'}
              rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
            >
              <Select
                mode="multiple"
                placeholder={t('currency')}
                allowClear
                options={channelCurrencyOptions}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Typography.Title level={5} className="pt-0 px-8 pb-0">
          {t('fulfillmentSettings')} :
        </Typography.Title>
        <Row gutter={12} className="pt-2 px-8 pb-0">
          <Col xs={12} md={6}>
            <Form.Item
              label={t('courierProvider')}
              name="fulfillment_settings"
              rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
            >
              <Select
                placeholder={t('courierProvider')}
                allowClear
                options={[
                  { label: t('own'), value: 'OWN' },
                  { label: t('Channel'), value: 'CHANNEL' }
                ]}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12} className="pt-2 px-8 pb-0">
          <Col xs={12} md={6}>
            <Form.Item name="publish_from_online_node" valuePropName="checked" label={t('publishOnlineNode')}>
              <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')}></Switch>
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.publish_from_online_node !== currentValues.publish_from_online_node
            }
          >
            {({ getFieldValue }) => {
              const publishFromOnlineNode = getFieldValue('publish_from_online_node');
              if (!publishFromOnlineNode) {
                return null;
              }
              return (
                <Col xs={12} md={6}>
                  <Form.Item
                    label={t('onlineNodeId')}
                    name="online_node_id"
                    rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                  >
                    <Input placeholder={t('onlineNodeId')} size="large" />
                  </Form.Item>
                </Col>
              );
            }}
          </Form.Item>
        </Row>

        <Typography.Title level={4} className="pt-0 px-8 pb-0">
          {t('contactDetails')} :
        </Typography.Title>
        <Row gutter={12} className="pl-6">
          <Col xs={12} md={6}>
            <Form.Item
              label={t('firstName')}
              name="first_name"
              rules={[
                { required: true, message: t('thisFieldCantBeEmpty') },
                {
                  pattern: /^[A-Za-z.]+$/,
                  message: t('fieldIsNotValid')
                },
                {
                  validator: (_, value) =>
                    value && value.length > 20 ? Promise.reject(new Error(t('max20Limit'))) : Promise.resolve()
                }
              ]}
            >
              <Input placeholder={t('firstName')} size="large" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label={t('middleName')}
              name="middle_name"
              rules={[
                { required: false, message: t('thisFieldCantBeEmpty') },
                {
                  pattern: /^[A-Za-z.]+$/,
                  message: t('fieldIsNotValid')
                },
                {
                  validator: (_, value) =>
                    value && value.length > 10 ? Promise.reject(new Error(t('max10Limit'))) : Promise.resolve()
                }
              ]}
            >
              <Input placeholder={t('middleName')} size="large" />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item
              label={t('lastName')}
              name="last_name"
              rules={[
                { required: true, message: t('thisFieldCantBeEmpty') },
                {
                  pattern: /^[A-Za-z.]+$/,
                  message: t('fieldIsNotValid')
                },
                {
                  validator: (_, value) =>
                    value && value.length > 20 ? Promise.reject(new Error(t('max20Limit'))) : Promise.resolve()
                }
              ]}
            >
              <Input placeholder={t('lastName')} size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12} className="pl-6">
          <Col>
            <Form.Item valuePropName="checked" name="same_address">
              <Checkbox>{t('useSameAddressAsProfile')}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.same_address !== currentValues.same_address}
        >
          {({ getFieldValue }) => {
            const sameAddressChecked = getFieldValue('same_address');
            if (!sameAddressChecked) {
              return (
                <>
                  <Row gutter={12} className="pt-0 px-8 pb-0">
                    <Col xs={12} md={6}>
                      <Form.Item
                        label={t('contactType')}
                        name="contact_type"
                        rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                      >
                        <Select
                          placeholder={t('contactType')}
                          allowClear
                          options={[
                            { label: t('primary'), value: 'PRIMARY' },
                            { label: t('secondary'), value: 'SECONDARY' },
                            { label: t('other'), value: 'OTHER' }
                          ]}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.contact_type !== currentValues.contact_type
                      }
                    >
                      {({ getFieldValue }) => {
                        const contactTypeData = getFieldValue('contact_type');
                        if (contactTypeData === 'OTHER') {
                          return (
                            <Col xs={12} md={6}>
                              <Form.Item
                                label={t('otherContactType')}
                                name="other_contact_type"
                                rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                              >
                                <Input placeholder={t('otherContactType')} size="large" />
                              </Form.Item>
                            </Col>
                          );
                        }
                      }}
                    </Form.Item>
                    <Col xs={12} md={6}>
                      <Form.Item
                        label={t('addressType')}
                        name="address_type"
                        rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                      >
                        <Select
                          placeholder={t('addressType')}
                          allowClear
                          options={[
                            { label: t('temporary'), value: 'TEMPORARY' },
                            { label: t('registred'), value: 'REGISTERED' },
                            { label: t('other'), value: 'OTHER' }
                          ]}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12} className="pt-0 px-8 pb-0">
                    <Col xs={12} md={12}>
                      <Form.Item
                        label={t('address1')}
                        name="address_line_1"
                        rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                      >
                        <Input placeholder={t('address1')} size="large" />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={12}>
                      <Form.Item
                        label={t('address2')}
                        name="address_line_2"
                        rules={[{ required: false, message: t('thisFieldCantBeEmpty') }]}
                      >
                        <Input placeholder={t('address2')} size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12} className="pt-0 px-8 pb-0">
                    <Col xs={12} md={6}>
                      <Form.Item
                        label={t('country')}
                        name="contact_country"
                        rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                      >
                        <Select
                          placeholder="Select"
                          allowClear
                          options={listOfCountryOptions || []}
                          onSelect={(selectItem: any, data: any) => {
                            channelForm.setFieldsValue({
                              contact_state: undefined,
                              contact_state_code: undefined,
                              contact_city: undefined,
                              contact_pincode: undefined
                            });
                            channelForm.setFieldsValue({ contact_country_code: data.countryCode });
                            getSelectedStateOfCountry(data.countryCode);
                          }}
                          onClear={() => {
                            channelForm.setFieldsValue({
                              contact_country_code: undefined,
                              contact_state: undefined,
                              contact_state_code: undefined,
                              contact_city: undefined,
                              contact_pincode: undefined
                            });
                          }}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Item
                        label={t('countryCode')}
                        name="contact_country_code"
                        rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                      >
                        <Input placeholder={t('countryCode')} disabled={true} size="large" />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Item
                        label={t('state')}
                        name="contact_state"
                        rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                      >
                        <Select
                          placeholder="Select"
                          allowClear
                          options={listOfStateOptions || []}
                          onSelect={(selectItem: any, data: any) => {
                            channelForm.setFieldsValue({
                              contact_city: undefined,
                              contact_pincode: undefined
                            });
                            channelForm.setFieldsValue({ contact_state_code: data.stateCode });
                            getListOfPincode(channelForm.getFieldsValue().contact_country_code, data.stateCode);
                          }}
                          onClear={() => {
                            channelForm.setFieldsValue({
                              contact_state_code: undefined,
                              contact_city: undefined,
                              contact_pincode: undefined
                            });
                          }}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Item
                        label={t('state_code')}
                        name="contact_state_code"
                        rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                      >
                        <Input placeholder={t('state_code')} disabled={true} size="large" />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Item
                        label={t('city')}
                        name="contact_city"
                        rules={[
                          { required: true, message: t('thisFieldCantBeEmpty') },
                          {
                            pattern: /^[A-Za-z.]+$/,
                            message: t('fieldIsNotValid')
                          },
                          {
                            validator: (_, value) =>
                              value && value.length > 20
                                ? Promise.reject(new Error(t('max20Limit')))
                                : Promise.resolve()
                          }
                        ]}
                      >
                        <Input placeholder={t('city')} size="large" />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Item
                        label={t('pincode')}
                        name="contact_pincode"
                        rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                      >
                        <Select placeholder="Select" allowClear options={listOfPinCodeOptions || []} size="large" />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Item
                        label={t('timeZone')}
                        name="time_Zone"
                        rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                      >
                        <Select
                          placeholder={t('selectAmOption')}
                          className="w-[100%]"
                          options={[
                            { label: 'Indian Standard Time (UTC+05:30)', value: 'IST', country: 'India' },
                            { label: 'America (UTC-08:00)', value: 'AMERICA', country: 'USA' },
                            { label: 'Chile Continental (UTC-04:00)', value: 'CHILE_CONTINENTAL', country: 'Chile' },
                            { label: 'Chile Easter (UTC-06:00)', value: 'CHILE_EASTER', country: 'Chile' },
                            { label: 'Europe (UTC+01:00)', value: 'EUROPE', country: 'Europe' }
                          ]}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Typography.Title level={4} className="pt-0 px-8 pb-0">
                    {t('additionalAttributes')}
                  </Typography.Title>
                  <Form.List name="additional_attributes">
                    {(fields, { add, remove }) => (
                      <div>
                        {fields.map(({ key, name, ...restField }) => (
                          <div key={key} className="pt-0 px-8 pb-0">
                            <Row gutter={12}>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'attribute_name']}
                                  label={t('attributeName')}
                                  rules={[{ required: true, message: 'Please input attribute name!' }]}
                                >
                                  <Input placeholder={t('attributeName')} size="large" />
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'attribute_value']}
                                  label={t('attributeValue')}
                                  rules={[{ required: true, message: 'Please input attribute value!' }]}
                                >
                                  <Input placeholder={t('attributeValue')} size="large" />
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'attribute_type']}
                                  label={t('attributeType')}
                                  rules={[{ required: true, message: 'Please select attribute type!' }]}
                                >
                                  <Select
                                    placeholder={t('attributeType')}
                                    options={[
                                      { label: t('identity'), value: 'IDENTITY' },
                                      { label: t('contact'), value: 'CONTACT' },
                                      { label: t('document'), value: 'DOCUMENT' }
                                    ]}
                                    size="large"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={4} className="mt-10">
                                <DeleteOutlined
                                  className="text-red-500 text-xl cursor-pointer"
                                  onClick={() => remove(name)}
                                />
                              </Col>
                            </Row>
                          </div>
                        ))}
                        <div className="pt-0 px-8 pb-0">
                          <Form.Item>
                            <Button
                              onClick={async () => {
                                const fieldsToValidate: (string | (string | number)[])[] = [];
                                channelForm
                                  .getFieldsValue()
                                  ?.additional_attributes?.forEach((_: any, index: number) => {
                                    fieldsToValidate.push(['additional_attributes', index, 'attribute_name']);
                                    fieldsToValidate.push(['additional_attributes', index, 'attribute_value']);
                                    fieldsToValidate.push(['additional_attributes', index, 'attribute_type']);
                                  });
                                await channelForm.validateFields(fieldsToValidate);
                                add({ attribute_name: '', attribute_value: '', attribute_type: '' });
                              }}
                              icon={<PlusOutlined />}
                            >
                              {t('addAttribute')}
                            </Button>
                          </Form.Item>
                        </div>
                      </div>
                    )}
                  </Form.List>
                </>
              );
            }
            return null;
          }}
        </Form.Item>

        {!addConfiguration && (
          <Row gutter={[12, 12]} className="pt-0 px-8 pb-6">
            <Col xs={12} md={4}>
              <Button
                onClick={() => {
                  handleOnSaveChannel();
                }}
                block
                type="primary"
              >
                <BoldButtonLabel labelText={t('save')}></BoldButtonLabel>
              </Button>
            </Col>
          </Row>
        )}
        {addConfiguration && (
          <div>
            <Typography.Title level={5} className="pt-0 px-8 pb-0">
              Integration Details :
            </Typography.Title>
            <Row gutter={12} className="p-6">
              <Col xs={12} md={6}>
                <Form.Item
                  label={t('Admin URL')}
                  name={'admin_url'}
                  rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                >
                  <Input placeholder={t('Admin URL')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label={t('merchantId')}
                  name={'merchant_Id'}
                  rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                >
                  <Input placeholder={t('merchantId')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label={t('apiKey')}
                  name={'api_key'}
                  rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                >
                  <Input.Password placeholder={t('apiKey')} type="password" size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label={t('apiSecreateKey')}
                  name={'api_secreate'}
                  rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                >
                  <Input.Password placeholder={t('apiSecreateKey')} type="password" size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]} className="pt-0 px-8 pb-6">
              <Col xs={12} md={4}>
                <Button block type="primary" htmlType="submit">
                  <BoldButtonLabel labelText={t('save')}></BoldButtonLabel>
                </Button>
              </Col>
              <Col xs={12} md={4}>
                <Button
                  onClick={() => {
                    channelForm.setFieldsValue({ channel_status: false });
                    setAddConfiguration(false);
                  }}
                  block
                >
                  <BoldButtonLabel labelText="Cancel"></BoldButtonLabel>
                </Button>
              </Col>
            </Row>
          </div>
        )}
        <Card className="mt-6 p-4">
          <section className="mt-4">
            <Table
              bordered
              className="mt-4"
              dataSource={tenentDetails}
              columns={channel_settings}
              scroll={{ x: 1000 }}
              pagination={{ ...pagination, position: ['bottomRight'] }}
            />
          </section>
        </Card>
      </Card>
      <Modal
        centered
        open={modalOpen}
        onCancel={() => {
          closeWarningModal();
        }}
        footer={false}
        title={
          <div className="flex">
            <Typography.Title level={3} className={'mt-3'}>
              {t('save')}
            </Typography.Title>
          </div>
        }
      >
        <div className="flex flex-col gap-2">
          <Typography.Text className="text-base p-4">{t('saveChannelWithoutConfig')}</Typography.Text>
          <div className="flex flex-row gap-2">
            <Button
              onClick={() => {
                handleOnAddConfiguration();
              }}
              type="primary"
            >
              <BoldButtonLabel labelText={t('addConfiguration')} />
            </Button>
            <Button
              onClick={() => {
                handleOnConfirm();
              }}
            >
              <BoldButtonLabel labelText={t('confirm')} />
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={editModal}
        onCancel={() => {
          closeEditModal();
        }}
        width={1000}
        style={{
          height: 800,
          overflow: 'auto'
        }}
        footer={false}
        title={
          <div className="flex">
            <Typography.Title level={3} className={'mt-3'}>
              {t('editChannel')}
            </Typography.Title>
          </div>
        }
      >
        <Form form={editChannelForm} layout="vertical" onFinish={handleOnSaveEdit}>
          <Card>
            <Row gutter={12} className="mt-4">
              <Col xs={12} md={6}>
                <Form.Item
                  label={t('channelId')}
                  name={'channel_id'}
                  rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                >
                  <Input placeholder={t('channelId')} disabled={true} size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label={t('channelName')}
                  name={'channel_name'}
                  rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                >
                  <Input placeholder={t('channelName')} disabled={true} size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label={t('channelType')}
                  name={'channel_type'}
                  rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                >
                  <Input placeholder={t('channelType')} disabled={true} size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item name="is_active" valuePropName="checked" label={t('channelStatus')}>
                  <Switch checkedChildren="Active" unCheckedChildren="In Active"></Switch>
                </Form.Item>
              </Col>
            </Row>
            <Typography.Title level={5}>{t('fulfillmentSettings')} :</Typography.Title>
            <Row gutter={12} className="pt-2 pb-0">
              <Col xs={12} md={6}>
                <Form.Item
                  label={t('courierProvider')}
                  name="fulfillment_settings"
                  rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                >
                  <Select
                    placeholder={t('courierProvider')}
                    allowClear
                    options={[
                      { label: t('own'), value: 'OWN' },
                      { label: t('Channel'), value: 'CHANNEL' }
                    ]}
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={12} md={6}>
                <Form.Item name="publish_from_online_node" valuePropName="checked" label={t('publishOnlineNode')}>
                  <Switch checkedChildren={t('yes')} unCheckedChildren={t('no')}></Switch>
                </Form.Item>
              </Col>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.publish_from_online_node !== currentValues.publish_from_online_node
                }
              >
                {({ getFieldValue }) => {
                  const publishFromOnlineNode = getFieldValue('publish_from_online_node');
                  if (!publishFromOnlineNode) {
                    return null;
                  }
                  return (
                    <Col xs={12} md={6}>
                      <Form.Item
                        label={t('onlineNodeId')}
                        name="online_node_id"
                        rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                      >
                        <Input placeholder={t('onlineNodeId')} size="large" />
                      </Form.Item>
                    </Col>
                  );
                }}
              </Form.Item>
            </Row>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.is_active !== currentValues.is_active}
            >
              {({ getFieldValue }) => {
                const publishFromOnlineNode = getFieldValue('is_active');
                if (!publishFromOnlineNode) {
                  return null;
                }
                return (
                  <>
                    <Typography.Title level={5}>Integration Details :</Typography.Title>
                    <Row gutter={12}>
                      <Col xs={12} md={6}>
                        <Form.Item
                          label={t('Admin URL')}
                          name={'admin_url'}
                          rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                        >
                          <Input placeholder={t('Admin URL')} size="large" />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Item
                          label={t('merchantId')}
                          name={'merchant_Id'}
                          rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                        >
                          <Input placeholder={t('merchantId')} size="large" />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Item
                          label={t('apiKey')}
                          name={'api_key'}
                          rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                        >
                          <Input.Password placeholder={t('apiKey')} type="password" size="large" />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Item
                          label={t('apiSecreateKey')}
                          name={'api_secreate'}
                          rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                        >
                          <Input.Password placeholder={t('apiSecreateKey')} type="password" size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                );
              }}
            </Form.Item>

            <Typography.Title level={5}>{t('contactDetails')} :</Typography.Title>
            <Row gutter={12}>
              <Col xs={12} md={6}>
                <Form.Item
                  label={t('firstName')}
                  name="first_name"
                  rules={[
                    { required: true, message: t('thisFieldCantBeEmpty') },
                    {
                      pattern: /^[A-Za-z.]+$/,
                      message: t('fieldIsNotValid')
                    },
                    {
                      validator: (_, value) =>
                        value && value.length > 20 ? Promise.reject(new Error(t('max20Limit'))) : Promise.resolve()
                    }
                  ]}
                >
                  <Input placeholder={t('firstName')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label={t('middleName')}
                  name="middle_name"
                  rules={[
                    { required: false, message: t('thisFieldCantBeEmpty') },
                    {
                      pattern: /^[A-Za-z.]+$/,
                      message: t('fieldIsNotValid')
                    },
                    {
                      validator: (_, value) =>
                        value && value.length > 10 ? Promise.reject(new Error(t('max10Limit'))) : Promise.resolve()
                    }
                  ]}
                >
                  <Input placeholder={t('middleName')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item
                  label={t('lastName')}
                  name="last_name"
                  rules={[
                    { required: true, message: t('thisFieldCantBeEmpty') },
                    {
                      pattern: /^[A-Za-z.]+$/,
                      message: t('fieldIsNotValid')
                    },
                    {
                      validator: (_, value) =>
                        value && value.length > 20 ? Promise.reject(new Error(t('max20Limit'))) : Promise.resolve()
                    }
                  ]}
                >
                  <Input placeholder={t('lastName')} size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col>
                <Form.Item valuePropName="checked" name="same_address">
                  <Checkbox>{t('useSameAddressAsProfile')}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.same_address !== currentValues.same_address}
            >
              {({ getFieldValue }) => {
                const sameAddressChecked = getFieldValue('same_address');
                if (!sameAddressChecked) {
                  return (
                    <div
                      style={{
                        height: 'fit-content',
                        width: '100%',
                        background: '#F1F1E4',
                        padding: '20px',
                        marginBottom: '15px'
                      }}
                    >
                      <Row gutter={12} className="pt-2 px-8 pb-0">
                        <Col xs={12} md={6}>
                          <Form.Item
                            label={t('contactType')}
                            name="contact_type"
                            rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                          >
                            <Select
                              placeholder={t('contactType')}
                              allowClear
                              options={[
                                { label: t('primary'), value: 'PRIMARY' },
                                { label: t('secondary'), value: 'SECONDARY' },
                                { label: t('other'), value: 'OTHER' }
                              ]}
                              size="large"
                            />
                          </Form.Item>
                        </Col>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.contact_type !== currentValues.contact_type
                          }
                        >
                          {({ getFieldValue }) => {
                            const contactTypeData = getFieldValue('contact_type');
                            if (contactTypeData === 'OTHER') {
                              return (
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    label={t('otherContactType')}
                                    name="other_contact_type"
                                    rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                                  >
                                    <Input placeholder={t('otherContactType')} size="large" />
                                  </Form.Item>
                                </Col>
                              );
                            }
                          }}
                        </Form.Item>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label={t('addressType')}
                            name="address_type"
                            rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                          >
                            <Select
                              placeholder={t('addressType')}
                              allowClear
                              options={[
                                { label: t('temporary'), value: 'TEMPORARY' },
                                { label: t('registred'), value: 'REGISTERED' },
                                { label: t('other'), value: 'OTHER' }
                              ]}
                              size="large"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={12} className="pt-0 px-8 pb-0">
                        <Col xs={12} md={12}>
                          <Form.Item
                            label={t('address1')}
                            name="address_line_1"
                            rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                          >
                            <Input placeholder={t('address1')} size="large" />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={12}>
                          <Form.Item
                            label={t('address2')}
                            name="address_line_2"
                            rules={[{ required: false, message: t('thisFieldCantBeEmpty') }]}
                          >
                            <Input placeholder={t('address2')} size="large" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={12} className="pt-0 px-8 pb-0">
                        <Col xs={12} md={6}>
                          <Form.Item
                            label={t('country')}
                            name="contact_country"
                            rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                          >
                            <Select
                              placeholder="Select"
                              allowClear
                              options={listOfCountryOptions || []}
                              onSelect={(selectItem: any, data: any) => {
                                editChannelForm.setFieldsValue({
                                  contact_state: undefined,
                                  contact_state_code: undefined,
                                  contact_city: undefined,
                                  contact_pincode: undefined
                                });
                                editChannelForm.setFieldsValue({ contact_country_code: data.countryCode });
                                getSelectedStateOfCountry(data.countryCode);
                              }}
                              onClear={() => {
                                editChannelForm.setFieldsValue({
                                  contact_country_code: undefined,
                                  contact_state: undefined,
                                  contact_state_code: undefined,
                                  contact_city: undefined,
                                  contact_pincode: undefined
                                });
                              }}
                              size="large"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label={t('countryCode')}
                            name="contact_country_code"
                            rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                          >
                            <Input placeholder={t('countryCode')} disabled={true} size="large" />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label={t('state')}
                            name="contact_state"
                            rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                          >
                            <Select
                              placeholder="Select"
                              allowClear
                              options={listOfStateOptions || []}
                              onSelect={(selectItem: any, data: any) => {
                                editChannelForm.setFieldsValue({
                                  contact_city: undefined,
                                  contact_pincode: undefined
                                });
                                editChannelForm.setFieldsValue({ contact_state_code: data.stateCode });
                                getListOfPincode(editChannelForm.getFieldsValue().contact_country_code, data.stateCode);
                              }}
                              onClear={() => {
                                editChannelForm.setFieldsValue({
                                  contact_state_code: undefined,
                                  contact_city: undefined,
                                  contact_pincode: undefined
                                });
                              }}
                              size="large"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label={t('state_code')}
                            name="contact_state_code"
                            rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                          >
                            <Input placeholder={t('state_code')} disabled={true} size="large" />
                          </Form.Item>
                        </Col>

                        <Col xs={12} md={6}>
                          <Form.Item
                            label={t('city')}
                            name="contact_city"
                            rules={[
                              { required: true, message: t('thisFieldCantBeEmpty') },
                              {
                                pattern: /^[A-Za-z.]+$/,
                                message: t('fieldIsNotValid')
                              },
                              {
                                validator: (_, value) =>
                                  value && value.length > 20
                                    ? Promise.reject(new Error(t('max20Limit')))
                                    : Promise.resolve()
                              }
                            ]}
                          >
                            <Input placeholder={t('city')} size="large" />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label={t('pincode')}
                            name="contact_pincode"
                            rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                          >
                            <Select placeholder="Select" allowClear options={listOfPinCodeOptions || []} size="large" />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Item
                            label={t('timeZone')}
                            name="time_Zone"
                            rules={[{ required: true, message: t('thisFieldCantBeEmpty') }]}
                          >
                            <Select
                              placeholder={t('selectAmOption')}
                              className="w-[100%]"
                              options={[
                                { label: 'Indian Standard Time (UTC+05:30)', value: 'IST', country: 'India' },
                                { label: 'America (UTC-08:00)', value: 'AMERICA', country: 'USA' },
                                {
                                  label: 'Chile Continental (UTC-04:00)',
                                  value: 'CHILE_CONTINENTAL',
                                  country: 'Chile'
                                },
                                { label: 'Chile Easter (UTC-06:00)', value: 'CHILE_EASTER', country: 'Chile' },
                                { label: 'Europe (UTC+01:00)', value: 'EUROPE', country: 'Europe' }
                              ]}
                              size="large"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Typography.Title level={4} className="pt-0 px-8 pb-0">
                        {t('additionalAttributes')}
                      </Typography.Title>
                      <Form.List name="additional_attributes">
                        {(fields, { add, remove }) => (
                          <div>
                            {fields.map(({ key, name, ...restField }) => (
                              <div key={key} className="pt-0 px-8 pb-0">
                                <Row gutter={12}>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'attribute_name']}
                                      label={t('attributeName')}
                                      rules={[{ required: true, message: 'Please input attribute name!' }]}
                                    >
                                      <Input placeholder={t('attributeName')} size="large" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'attribute_value']}
                                      label={t('attributeValue')}
                                      rules={[{ required: true, message: 'Please input attribute value!' }]}
                                    >
                                      <Input placeholder={t('attributeValue')} size="large" />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'attribute_type']}
                                      label={t('attributeType')}
                                      rules={[{ required: true, message: 'Please select attribute type!' }]}
                                    >
                                      <Select
                                        placeholder={t('attributeType')}
                                        options={[
                                          { label: t('identity'), value: 'IDENTITY' },
                                          { label: t('contact'), value: 'CONTACT' },
                                          { label: t('document'), value: 'DOCUMENT' }
                                        ]}
                                        size="large"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={4} className="mt-10">
                                    <DeleteOutlined
                                      className="text-red-500 text-xl cursor-pointer"
                                      onClick={() => remove(name)}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            ))}
                            <div className="pt-0 px-8 pb-0">
                              <Form.Item>
                                <Button
                                  onClick={async () => {
                                    const fieldsToValidate: (string | (string | number)[])[] = [];
                                    channelForm
                                      .getFieldsValue()
                                      ?.additional_attributes?.forEach((_: any, index: number) => {
                                        fieldsToValidate.push(['additional_attributes', index, 'attribute_name']);
                                        fieldsToValidate.push(['additional_attributes', index, 'attribute_value']);
                                        fieldsToValidate.push(['additional_attributes', index, 'attribute_type']);
                                      });
                                    await channelForm.validateFields(fieldsToValidate);
                                    add({ attribute_name: '', attribute_value: '', attribute_type: '' });
                                  }}
                                  icon={<PlusOutlined />}
                                >
                                  {t('addAttribute')}
                                </Button>
                              </Form.Item>
                            </div>
                          </div>
                        )}
                      </Form.List>
                    </div>
                  );
                }
                return null;
              }}
            </Form.Item>
            <Row gutter={12} className="mt-4">
              <Button type="primary" htmlType="submit">
                <BoldButtonLabel labelText={t('save')} />
              </Button>
            </Row>
          </Card>
        </Form>
      </Modal>
    </Form>
  );
};

export default ChannelSettings;
