import { Card, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { useProfile } from '../../../hooks/use-profile';
import '../../../i18n';
import PrimaryLayout from '../../../layouts/primary-layout';
import OrganizationInfo from './organization-info';
import TenantConfiguration from './tenant-configuration';

interface ITenentProfileProps {}

const TenentProfile: React.FunctionComponent<ITenentProfileProps> = props => {
  const { TabPane } = Tabs;
  const { profileDetails, fetchProfileInfo } = useProfile();
  const { t } = useTranslation();
  const isNebulaEnabled = (tenantDetails: any): boolean => {
    return tenantDetails?.subscriptions?.subscription_info?.some(
      (subscription: any) => subscription?.product_id === 'NEBULA' && subscription?.is_enabled
    );
  };
  //Direct Check in BETA Once
  const nebulaEnabled = isNebulaEnabled(profileDetails?.tenant_details);
  return (
    <PrimaryLayout>
      <div className="w-full mx-auto px-4">
        <Card className="bg-[#EEF1F1]">
          <>
            <p className="text-[20px]">{t('tenantManagement')}</p>
            <Tabs defaultActiveKey="1">
              <TabPane tab={<BoldButtonLabel labelText={t('organizationDetails')} />} key="1">
                <OrganizationInfo profileDetails={profileDetails} fetchProfileInfo={fetchProfileInfo} />
              </TabPane>
              {nebulaEnabled && (
                <TabPane tab={<BoldButtonLabel labelText={t('configuration')} />} key="3">
                  <TenantConfiguration profileDetails={profileDetails} />
                </TabPane>
              )}
            </Tabs>
          </>
        </Card>
      </div>
    </PrimaryLayout>
  );
};
export default TenentProfile;
