import { Button, Card, Col, Input, Modal, Popconfirm, Row, Switch, Table, TableColumnsType, Typography } from 'antd';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CustomPagination from '../../components/custom-pagination';
import { getAccess } from '../../constants/common-constants';
import { convertQueryStringToObj } from '../../helpers';
import _ from '../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import { generateUUIDV4 } from '../../helpers/uuid.helpers';
import PrimaryLayout from '../../layouts/primary-layout';
import { inventoryService } from '../../services';
import {
  IInventoryDetailsResponseType,
  ISupply,
  ItemVariant,
  ManualStockUpdateRequest
} from '../../types/inventory.types';

interface IInventoryDetailsProps {
  productOf: string;
}

const productIdKey: string = 'productId';

const InventoryDetailsPage: React.FunctionComponent<IInventoryDetailsProps> = ({ productOf }) => {
  const { permission } = getAccess(productOf);
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [editableInputs, setEditableInputs] = React.useState<Record<string, any>>({});

  const columns: TableColumnsType<any> = [
    {
      title: 'Variant / SKU',
      align: 'center',
      sorter: (a: any, b: any) => a.sku - b.sku,
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => {
        return <>{record.sku}</>;
      }
    },
    {
      title: 'Node',
      align: 'center',
      sorter: (a: any, b: any) => a.node_name - b.node_name,
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => <>{record.node_name}</>
    },
    {
      title: 'Is Freezed',
      align: 'center',
      render: (_, record) => (
        <>
          <Popconfirm
            title="Are you sure?"
            disabled={!permission}
            onConfirm={() => {
              handleOnStatusChange(record);
            }}
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" checked={record?.freeze} disabled={!permission} />
          </Popconfirm>
        </>
      )
    },
    {
      title: <Typography className="text-[#ffc000]">Tag Id</Typography>,
      align: 'center',
      render: (_, record) => <>{record.tag_id}</>
    },
    {
      title: <Typography className="text-[#ffc000]">Tag Type</Typography>,
      align: 'center',
      render: (_, record) => <>{record.tag_type}</>
    },
    // { title: 'Product Id', align: 'center', render: (_, record) => <>{record.product_id}</> },
    {
      title: <Typography className="text-[#800080]">Supply</Typography>,
      align: 'center',
      children: [
        {
          title: <Typography className="text-[#800080]">On Order Stock</Typography>,
          align: 'center',
          children: [
            {
              title: <Typography className="text-[#800080] min-w-[70px]">Purchase Order</Typography>,
              align: 'center',
              render(value, record, index) {
                return <Typography.Text className="text-[#800080]">{record.po_on_order}</Typography.Text>;
              }
            },
            {
              title: <Typography className="text-[#800080] min-w-[60px]">Transfer Order</Typography>,
              render(value, record, index) {
                return <Typography.Text className="text-[#800080]">{record?.to_on_order}</Typography.Text>;
              },
              align: 'center'
            }
          ]
        },
        {
          title: <Typography className="text-[#800080]">In Transit</Typography>,
          align: 'center',
          children: [
            {
              title: <Typography className="text-[#800080] min-w-[70px]">Purchase Order</Typography>,
              align: 'center',
              render(value, record, index) {
                return <Typography.Text className="text-[#800080]">{record.po_in_transit}</Typography.Text>;
              }
            },
            {
              title: <Typography className="text-[#800080] min-w-[60px]">Transfer Order</Typography>,
              render(value, record, index) {
                return <Typography.Text className="text-[#800080]">{record?.to_in_transit}</Typography.Text>;
              },
              align: 'center'
            }
          ]
        },
        {
          title: <Typography className="text-[#800080] min-w-[65px]">Damaged</Typography>,
          render: (_, record) => <>{record?.damaged}</>,
          align: 'center'
        }
      ]
    },
    {
      title: <Typography className="text-[#FF5733] min-w-[60px]">Demand</Typography>,
      align: 'center',
      children: [
        {
          title: <Typography className="text-[#FF5733] min-w-[70px]">Reserved</Typography>,
          render: (_, record) => <>{record?.demand?.reserved}</>,
          align: 'center'
        },
        {
          title: <Typography className="text-[#FF5733] min-w-[70px]">Demand Open Order</Typography>,
          render: (_, record) => <>{record?.demand?.demand_open_order}</>,
          align: 'center'
        },
        {
          title: <Typography className="text-[#FF5733] min-w-[75px]">Demand Scheduled</Typography>,
          render: (_, record) => <>{record?.demand?.demand_scheduled}</>,
          align: 'center'
        },
        {
          title: <Typography className="text-[#FF5733] min-w-[70px]">Demand Allocated</Typography>,
          render: (_, record) => <>{record?.demand?.demand_allocated}</>,
          align: 'center'
        }
      ]
    },
    {
      title: <Typography className="text-[#5600ff] min-w-[60px]">Transfer Order</Typography>,
      align: 'center',
      children: [
        {
          title: <Typography className="text-[#5600ff] min-w-[70px]">Transfer Order Reserve</Typography>,
          render(value, record, index) {
            return <Typography.Text className="text-[#5600ff]">{record?.to_demand?.reserved}</Typography.Text>;
          },
          align: 'center'
        },
        {
          title: <Typography className="text-[#5600ff] min-w-[60px]">Transfer Order Demand</Typography>,
          render(value, record, index) {
            return <Typography.Text className="text-[#5600ff]">{record?.to_demand?.demand_open_order}</Typography.Text>;
          },
          align: 'center'
        },
        {
          title: <Typography className="text-[#5600ff] min-w-[60px]">Transfer Order Scheduled</Typography>,
          render(value, record, index) {
            return <Typography.Text className="text-[#5600ff]">{record?.to_demand?.demand_scheduled}</Typography.Text>;
          },
          align: 'center'
        },
        {
          title: <Typography className="text-[#5600ff] min-w-[60px]">Transfer Order Allocated</Typography>,
          render(value, record, index) {
            return <Typography.Text className="text-[#5600ff]">{record?.to_demand?.demand_allocated}</Typography.Text>;
          },
          align: 'center'
        }
      ]
    },
    {
      title: 'On Hand',
      align: 'center',
      sorter: (a, b) => a.available - b.available,
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => {
        let renderElement;

        if (record?.manual_editable_available) {
          renderElement = (
            <div className="flex justify-center gap-4">
              <Typography.Text delete className="text-red-500">
                <span className="text-black">{record.available}</span>
              </Typography.Text>
              <Typography.Text>{record?.manual_editable_available}</Typography.Text>
            </div>
          );
        } else {
          renderElement = <Typography.Text>{record.available} </Typography.Text>;
        }

        return <>{renderElement}</>;
      }
    }
  ];

  if (permission) {
    columns.push({
      title: '',
      width: 300,
      align: 'center',
      render: (_, record) => (
        <div className="w-[300px]">
          <Row justify={'start'}>
            {' '}
            <Col>
              <Button
                size="large"
                type="primary"
                style={{ borderRadius: 0 }}
                onClick={() =>
                  handleSetQuantity(
                    record.sku,
                    record.node_name,
                    record.tag_id,
                    record.tag_type,
                    editableInputs[`${record.sku}${record.node_name}${record.tag_id}${record.tag_type}`]
                  )
                }
              >
                Set
              </Button>
            </Col>
            <Col className="w-[30%]">
              <Input
                type="number"
                value={editableInputs[`${record.sku}${record.node_name}${record.tag_id}${record.tag_type}`]}
                onChange={e => {
                  const changedValue = e.target.value;
                  setEditableInputs(previousInputs => ({
                    ...previousInputs,
                    [`${record.sku}${record.node_name}${record.tag_id}${record.tag_type}`]: changedValue
                  }));
                }}
                min={0}
                size="large"
                className="rounded-none"
              />
            </Col>
            <Col>
              <Button
                size="large"
                type="primary"
                onClick={() => handleSaveInventory(record)}
                disabled={!record.manual_editable_available}
                className="disabled:bg-[#82809c] disabled:text-gray-300"
                style={{ borderRadius: 0 }}
              >
                Save
              </Button>
            </Col>
            <Col>
              <Button
                disabled={!record.manual_editable_available}
                size="large"
                type="link"
                onClick={() => handleSingleClear(record)}
                style={{ borderRadius: 0 }}
              >
                Clear
              </Button>
            </Col>
          </Row>
        </div>
      )
    });
  }

  React.useEffect(() => {
    fetchInitialData();
  }, []);

  const handleSetQuantity = (sku: string, node_id: string, tag_id: string, tag_type: string, quantity?: string) => {
    const updatedVariants = inventoryDetails.variants.map(variant => {
      if (
        `${variant.sku}${variant.node_name}${variant.tag_id}${variant.tag_type}` ===
        `${sku}${node_id}${tag_id}${tag_type}`
      )
        return { ...variant, manual_editable_available: quantity };
      else return variant;
    });

    setInventoryDetails(inventoryDetails => ({ ...inventoryDetails, variants: updatedVariants }));
  };

  const handleOnStatusChange = async (record: any) => {
    setLoading(true);
    const bodyData = {
      node_name: record?.node_name,
      sku: record?.sku,
      is_freeze_tag_level: true,
      tag_type: record.tag_type,
      tag_id: record.tag_id,
      freeze: !record.freeze
    };
    const { errors } = await inventoryService.updateFreeze(bodyData);
    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
    } else {
      fetchInitialData();
    }
    setLoading(false);
  };

  const fetchInitialData = () => {
    const articleId = params[productIdKey] as string;
    const node_id = (searchParams.get('node_id') as string) || '';

    setNodeIdValue(node_id);

    const pageNumber = searchParams.get('page_number') ? parseInt(searchParams.get('page_number') as string) : 1;
    fetchInventoryDetail(articleId, pageNumber, node_id);
  };

  const fetchInventoryDetail = async (articleId: string, pageNumber: number = 1, node_id?: string) => {
    setLoading(true);
    const newParams = {
      id: articleId
    };
    const { data } = await inventoryService.getInventoryDetails(articleId, pageNumber, 5, node_id, newParams);
    const filteredQueryParams: { [key: string]: any } = {
      page_number: pageNumber
    };

    if (node_id) filteredQueryParams.node_id = node_id;

    setSearchParams(prev => {
      const previousParams = convertQueryStringToObj(prev.toString());
      return { ...previousParams, ...filteredQueryParams };
    });

    setEditableInputs({});
    setInventoryDetails(data);
    setLoading(false);
  };

  const [inventoryDetails, setInventoryDetails] = React.useState<IInventoryDetailsResponseType>(
    {} as IInventoryDetailsResponseType
  );

  const getIfSaveAllDisabled = (inventoryDetails: IInventoryDetailsResponseType) => {
    let disabled = true;
    if (_.isEmpty(inventoryDetails)) return disabled;
    inventoryDetails.variants.forEach(variant => {
      if (variant.manual_editable_available) {
        disabled = false;
      }
    });

    return disabled;
  };

  const isSaveAllDisabled = getIfSaveAllDisabled(inventoryDetails);

  const handleResetTable = () => {
    const updatedVariants = inventoryDetails.variants.map(variant => {
      return { ...variant, manual_editable_available: undefined };
    });
    setInventoryDetails(inventoryDetails => ({ ...inventoryDetails, variants: updatedVariants }));
    setEditableInputs({});
  };

  const handleSaveInventory = async (variant: ItemVariant) => {
    setLoading(true);
    const { set_available, available, manual_editable_available, ...rest } = variant;
    const variantUpdationRequest: ManualStockUpdateRequest = {
      supply: [
        {
          adjustment_type: 'ABSOLUTE',
          bucket: 'ON-HAND',
          node_name: variant.node_name,
          quantity: {
            number_of_units: parseInt(variant.manual_editable_available as string),
            unit_of_measure: variant.unit_of_measure,
            unit_size: 1,
            unit_fraction: 1
          },
          sku: variant.sku,
          source_transaction_datetime: new Date().toISOString(),
          source_transaction_id: generateUUIDV4(),
          transaction_type: 'FULL-SYNC',
          article_id: inventoryDetails.article_id,

          tag_id: variant.tag_id,
          tag_type: variant.tag_type,
          product_id: variant.product_id
        }
      ]
    };

    const { errors } = await inventoryService.updateInventory(variantUpdationRequest);

    if (!_.isEmpty(errors)) displayErrorNotifications(errors);
    else {
      displaySuccessNotification({ message: 'Quantity updated successfully' });
      setEditableInputs(editableInputs => {
        delete editableInputs[`${variant.sku}${variant.node_name}`];
        return editableInputs;
      });
      setInventoryDetails(inventoryDetails => {
        const updatedVariants = inventoryDetails.variants.map(v => {
          if (`${v.sku}${v.node_name}` === `${variant.sku}${variant.node_name}`)
            return {
              ...v,
              manual_editable_available: undefined,
              available: parseInt(variant.manual_editable_available as string)
            };
          return v;
        });
        return { ...inventoryDetails, variants: updatedVariants };
      });
    }
    setLoading(false);
  };

  const handleSingleClear = (variant: ItemVariant) => {
    const { sku, node_name } = variant;

    setEditableInputs(editableInputs => {
      const { [`${sku}${node_name}`]: deletedVariantId, ...filteredEditableInputs } = editableInputs;
      return { ...filteredEditableInputs };
    });

    setInventoryDetails(inventoryDetails => {
      const updatedList = inventoryDetails.variants.map(variant => {
        if (variant.sku === sku) return { ...variant, manual_editable_available: undefined };
        return variant;
      });

      return { ...inventoryDetails, variants: updatedList };
    });
  };

  const handleNext = async (currentPage: number) => {
    const articleId = params[productIdKey] as string;
    fetchInventoryDetail(articleId, currentPage);
  };

  const handlePrevious = (currentPage: number) => {
    const articleId = params[productIdKey] as string;
    fetchInventoryDetail(articleId, currentPage);
  };
  const handlePageChange = (currentPage: number) => {
    const articleId = params[productIdKey] as string;
    fetchInventoryDetail(articleId, currentPage);
  };

  const handleClickBack = () => {
    const params = searchParams.get('from_query');
    const decodedParams = JSON.parse(decodeURIComponent(params as string));
    const pageParams = new URLSearchParams(decodedParams).toString();
    navigate(`/inventory?${pageParams}`);
  };

  const handleDetailsSearchWithNode = async (node: string) => {
    setLoading(true);
    const articleId = params[productIdKey] as string;
    const newParams = {
      id: articleId
    };
    const { data, errors } = await inventoryService.getInventoryDetails(articleId, 1, 5, node, newParams);

    if (_.isEmpty(errors)) setInventoryDetails(data);
    else displayErrorNotifications(errors);

    setSearchParams(prev => {
      const previousParams = convertQueryStringToObj(prev.toString());
      if (!node) {
        delete previousParams.node_id;
        return previousParams;
      }
      return { ...previousParams, node_id: node };
    });

    setLoading(false);
  };

  const handleRefetchData = async () => {
    setLoading(true);
    const node_id = searchParams.get('node_id') as string;
    const articleId = params[productIdKey] as string;
    const page_number = parseInt(searchParams.get('page_number') as string) || 1;
    const newParams = {
      id: articleId
    };
    const { data, errors } = await inventoryService.getInventoryDetails(articleId, page_number, 5, node_id, newParams);
    if (!_.isEmpty(errors)) displayErrorNotifications(errors);
    else {
      setInventoryDetails(data);
    }
    setLoading(false);
  };

  const handleSaveAllConfirmation = async () => {
    //!Check if some data is unset
    let unsetSkusPreset = false;
    const setSkus = inventoryDetails.variants.map(variant => {
      if (!isNaN(parseInt(variant.manual_editable_available as string))) return `${variant.sku}${variant.node_name}`;
    });

    Object.keys(editableInputs).forEach(key => {
      if (!isNaN(editableInputs[key]) && !setSkus.includes(key)) unsetSkusPreset = true;
    });

    //!data is unset if input is present and manual editable is not present
    if (unsetSkusPreset)
      Modal.confirm({
        title: <>Few Products values is not set, Update won't happen for unset data. Please confirm ? </>,
        centered: true,
        async onOk() {
          await handleSaveAll();
        }
      });
    else {
      await handleSaveAll();
    }
  };

  const handleSaveAll = async () => {
    setLoading(true);
    const inventorySupplyRequest: ManualStockUpdateRequest = { supply: [] };
    const transactionTime = new Date().toISOString();
    inventoryDetails.variants.forEach(variant => {
      if (!isNaN(parseInt(variant.manual_editable_available as string))) {
        const supply: ISupply = {
          adjustment_type: 'ABSOLUTE',
          bucket: 'ON-HAND',
          node_name: variant.node_name,
          quantity: {
            number_of_units: parseInt(variant.manual_editable_available as string),
            unit_of_measure: variant.unit_of_measure,
            unit_size: 1,
            unit_fraction: 1
          },
          sku: variant.sku,
          source_transaction_datetime: transactionTime,
          source_transaction_id: generateUUIDV4(),

          transaction_type: 'FULL-SYNC',
          article_id: inventoryDetails.article_id,

          tag_id: variant.tag_id,
          tag_type: variant.tag_type,
          product_id: variant.product_id
        };

        inventorySupplyRequest.supply.push(supply);
      }
    });
    const { errors } = await inventoryService.updateInventory(inventorySupplyRequest);
    if (!_.isEmpty(errors)) displayErrorNotifications(errors);
    else {
      displaySuccessNotification({ message: 'Quantity updated successfully' });
      setEditableInputs({});
      await handleRefetchData();
    }
    //!TODO refetch current page picture
    //! IF refetch ==> order wont be maintained //
    setLoading(false);
  };

  const [nodeIdValue, setNodeIdValue] = React.useState('');

  return (
    <PrimaryLayout>
      {' '}
      <div className="container mx-auto px-4 lg:px-2">
        <Card>
          <div className="mt-4 mb-2">
            <Row justify={'space-between'} gutter={[12, 12]}>
              <Col>
                <Typography.Title level={2} className="text-[#2e2a5b]">
                  Manual Stock Update
                </Typography.Title>
              </Col>
            </Row>
          </div>
          <div className="flex flex-col">
            {/* <Typography.Text className="text-[#424250] text-xl">
            Product : {productDetailsMap[inventoryDetails.article_id]?.product_name || inventoryDetails.article_id}
          </Typography.Text> */}
            <Typography.Text className="text-[#424250] text-xl">
              Article ID : {inventoryDetails.article_id}
            </Typography.Text>
          </div>
          <Row justify={'space-between'} className="mt-8 gap-4">
            <Col xs={24} lg={8}>
              <Input.Search
                value={nodeIdValue}
                onChange={e => {
                  const value = e.target.value;
                  setNodeIdValue(value);
                }}
                placeholder="Search By Node"
                onSearch={handleDetailsSearchWithNode}
                size="large"
              />{' '}
            </Col>
          </Row>{' '}
          <section>
            {!_.isEmpty(inventoryDetails.variants) && (
              <div className="flex justify-end">
                <CustomPagination
                  currentPage={inventoryDetails.page_info.current_page}
                  totalPages={inventoryDetails.page_info.total_pages}
                  handleNext={handleNext}
                  handlePageChange={handlePageChange}
                  handlePrevious={handlePrevious}
                />
              </div>
            )}

            <Table
              className="mt-4"
              scroll={{ x: 700 }}
              pagination={false}
              loading={loading}
              bordered
              dataSource={inventoryDetails.variants}
              columns={columns}
            ></Table>
            <div className="flex flex-row mt-2 gap-2 justify-between">
              <div>
                <Button onClick={handleClickBack} size="large" type="primary">
                  Back
                </Button>
              </div>
              <div className="flex gap-2">
                <Button disabled={isSaveAllDisabled} onClick={handleSaveAllConfirmation} size="large" type="primary">
                  Save All
                </Button>
                <Button disabled={_.isEmpty(editableInputs)} onClick={handleResetTable} size="large">
                  Clear All
                </Button>
              </div>
            </div>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default InventoryDetailsPage;
