import { Button, Card, Col, Form, Input, Row, Switch, Table, TableColumnsType, Typography } from 'antd';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../../../components/ActionButton';
import BoldButtonLabel from '../../../../../components/BoldButtonLabel';
import FieldDisplay from '../../../../../components/FieldDisplay';
import { getAccess } from '../../../../../constants/common-constants';
import { convertQueryStringToObj, objectHelpers, urlHelpers } from '../../../../../helpers';
import _ from '../../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../../layouts/primary-layout';
import { useLoader } from '../../../../../stores/use-loader';
import { logisticService } from '../../../services';

interface IShippingProfileListingProps {
  productOf: string;
}

const ShippingProfileListing: React.FunctionComponent<IShippingProfileListingProps> = ({ productOf }) => {
  const { permission } = getAccess(productOf);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const queryObj = convertQueryStringToObj(searchParams.toString());
  const [shippingProfileResponse, setShippingProfileResponse] = React.useState({} as any);
  const { name } = useParams();

  const [searchForm] = Form.useForm();

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const loadInitialData = async () => {
    const queryString = searchParams.toString();
    const queryStringObj = convertQueryStringToObj(queryString);
    if (!_.isEmpty(queryStringObj)) {
      searchForm.setFieldsValue(queryStringObj);
    }
    const offset = queryStringObj.offset ? parseInt(queryStringObj.offset) : 0;
    await handleFilterSearch(offset);
  };

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const handleFilterSearch = async (offset = 0, limit = 10) => {
    setLoading(true);
    const queryString = searchParams.toString();
    const queryStringObj = convertQueryStringToObj(queryString);
    const form = searchForm.getFieldsValue();
    const formValues = { ...form, snn_id: queryStringObj.snn_id };
    const fulfillment_type = queryStringObj['fulfilment-type'];
    const params = { ...formValues, offset, limit, 'fulfilment-type': fulfillment_type };

    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(filteredParams);
    const newparams = {
      name: filteredParams.name,
      'snn-id': filteredParams.snn_id,
      offset,
      limit
    };
    const newfilteredParams = objectHelpers.deleteUndefinedValuesFromObject(newparams);
    const { data, errors } = await logisticService.getShippingCharges(
      filteredParams.snn_id as string,
      newfilteredParams
    );
    if (_.isEmpty(errors)) {
      setShippingProfileResponse(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const backUrlParamString = urlHelpers.getBackUrlParamString(searchParams);

  const columns: TableColumnsType<any> = [
    {
      title: 'Source Node Network',
      render(value, record, index) {
        return <>{record?.snn_name}</>;
      },
      align: 'center'
    },
    {
      title: 'Shipping Profile Name',
      render(value, record, index) {
        return <>{record?.shipping_profile_name}</>;
      },
      align: 'center'
    },
    {
      title: 'Active?',
      render(value, record, index) {
        return (
          <section className="flex justify-center items-center">
            <Switch
              disabled={!permission}
              checkedChildren={'Yes'}
              unCheckedChildren={'No'}
              onChange={(value: boolean) => {
                setLoading(true);
                handleOnChaneStatus(value, record);
                setLoading(false);
              }}
              checked={record?.is_active ? true : false}
            />
          </section>
        );
      },
      align: 'center'
    },
    {
      title: 'Action',
      render(value, record, index) {
        return (
          <section className="flex justify-center gap-4">
            <ActionButton
              action="MANAGE"
              title="Service Network"
              onClick={() => {
                const queryString = searchParams.toString();
                const queryStringObj = convertQueryStringToObj(queryString);
                navigate(
                  `/logistics/service-network/${name}/${queryStringObj.snn_id}/${record.shipping_profile_name}/${record.shipping_profile_id}?fulfilment-type=${queryStringObj['fulfilment-type']}`
                );
              }}
            />
            {permission && (
              <ActionButton
                action="CREATE_NEW_VERSION"
                onClick={() => {
                  navigate(`/logistics/shipping-profile/${record.shipping_profile_id}/edit?${backUrlParamString}`);
                }}
                title="Edit Shipping Profile"
              />
            )}
            {permission && (
              <ActionButton
                action="DELETE"
                title={'Delete Shipping Profile'}
                onClick={() => handleShippingProfileDelete(record)}
              ></ActionButton>
            )}
          </section>
        );
      },
      align: 'center'
    }
  ];

  const handleOnChaneStatus = async (action: boolean, record: any) => {
    const bodyData = {
      is_active: action
    };
    const { errors } = await logisticService.updateShippingSNNState(record.shipping_profile_id as string, bodyData);
    if (_.isEmpty(errors)) {
      handleFilterSearch(0);
    } else {
      displayErrorNotifications(errors);
    }
  };

  const handleShippingProfileDelete = async (record: any) => {
    setLoading(true);
    const { errors } = await logisticService.removeShippingProfile(record.shipping_profile_id as string);
    if (_.isEmpty(errors)) {
      handleFilterSearch(0);
      displaySuccessNotification({ message: 'Item Removed !' });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };
  const handlePageChange = (currentPage: number | null, pageSize: number | null) => {
    const offset = currentPage ? currentPage - 1 : parseInt(searchParams.get('offset') || '0');
    const limit = pageSize ? pageSize : parseInt(searchParams.get('limit') || '10');
    handleFilterSearch(offset, limit);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                Shipping Profile
              </Typography.Title>
            </Col>
            {permission && (
              <Col>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    const queryString = searchParams.toString();
                    const queryStringObj = convertQueryStringToObj(queryString);
                    const id = queryStringObj.snn_id;
                    navigate(`/logistics/shipping-profile/create/${id}`);
                  }}
                >
                  <BoldButtonLabel labelText="Create" />
                </Button>
              </Col>
            )}
          </Row>
          <div className="my-4">
            <Form
              onFinish={() => handleFilterSearch(0, parseInt(searchParams.get('limit') || '10'))}
              form={searchForm}
              layout="vertical"
            >
              <Row gutter={12}>
                <section className="flex flex-wrap flex-col gap-2 mb-4">
                  <FieldDisplay label="Source Node Network Name" value={name} />
                  {/* <FieldDisplay label="Source Node Network Id" value={queryObj.snn_id} /> */}
                </section>
              </Row>

              <Row gutter={12}>
                <Col xs={12} md={6}>
                  <Form.Item name={'name'} label="Shipping Profile Name">
                    <Input placeholder="Shipping Profile Name" size="large" allowClear />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <div className="h-full flex justify-center items-center">
                    <Button type="primary" htmlType="submit" size="large" block>
                      <BoldButtonLabel labelText="Search"></BoldButtonLabel>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
          <section className="mt-4">
            {/* {!_.isEmpty([1]) && (
              <div className="flex justify-end my-2">
                <CustomPagination
                  currentPage={1}
                  totalPages={1}
                  handleNext={handlePageChange}
                  handlePageChange={handlePageChange}
                  handlePrevious={handlePageChange}
                />
              </div>
            )}{' '} */}
            <Table
              loading={false}
              pagination={{
                total: shippingProfileResponse?.total_pages
                  ? shippingProfileResponse.total_pages * parseInt(searchParams.get('limit') as string) || 10
                  : 0,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],
                pageSize: parseInt(searchParams.get('limit') as string) || 10,
                current: parseInt(searchParams.get('offset') as string) + 1 || 1,
                onChange: (current, pageSize) => {
                  handlePageChange(current, pageSize);
                }
              }}
              bordered
              dataSource={shippingProfileResponse.shipping_profiles || []}
              columns={columns}
              scroll={{ x: 1000 }}
            ></Table>
          </section>
          <Row gutter={[12, 12]} className="mt-4">
            <Col xs={24} md={8} lg={6}>
              <Button
                size="large"
                disabled={false}
                block
                onClick={() => {
                  navigate('/logistics/snn');
                }}
              >
                <BoldButtonLabel labelText="Go Back" />
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default ShippingProfileListing;
