import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import _ from '../../../../helpers/lodash';

interface IRewardForm1Props {
  initialValues?: any;
  onFinish?: () => void;
  formInstance: FormInstance<any>;
  disabled?: boolean;
}

const RewardForm: React.FunctionComponent<IRewardForm1Props> = ({
  formInstance,
  onFinish,
  initialValues,
  disabled
}) => {
  const isUpdate = !_.isEmpty(initialValues);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (isUpdate) formInstance.setFieldsValue(initialValues);
  }, [initialValues]);
  const navigate = useNavigate();
  const labelText = isUpdate ? t('update') : t('save');

  const [searchParams] = useSearchParams();

  return (
    <Form form={formInstance} layout="vertical" className="mt-4" onFinish={onFinish}>
      <Row gutter={12}>
        <Col xs={24} md={8} lg={6}>
          <Form.Item
            name={'reward_type'}
            label={t('rewardType')}
            rules={[{ message: t('rewardTypeIsRequired'), required: true }]}
          >
            <Select
              disabled={isUpdate}
              placeholder={t('rewardType')}
              size="large"
              options={[
                { label: t('points'), value: 'POINTS' },
                { label: t('cash'), value: 'CASH' }
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} lg={6}>
          <Form.Item
            name={'name'}
            label={t('rewardName')}
            rules={[
              {
                message: t('fieldIsRequiredFunction', { name: t('rewardName') }).replace('{name}', t('rewardName')),
                required: true
              },
              { max: 40, message: t('maxLimitIs40Characters') },
              {
                validator: (_, value) => {
                  const alphanumericRegex = /[a-zA-Z0-9]/;
                  if (!!value && value?.trim() === '') {
                    return Promise.reject(new Error(t('rewardNameCannotBeEmptyOrSpacesOnly')));
                  }
                  if (!alphanumericRegex.test(value)) {
                    return Promise.reject(new Error(t('rewardNameMustContainAtLeastOneAlphanumericCharacter')));
                  }

                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input disabled={isUpdate} placeholder={t('enterRewardName')} size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            name={'description'}
            label={t('rewardDescription')}
            rules={[
              {
                message: t('fieldIsRequiredFunction', { name: t('rewardDescription') }).replace(
                  '{name}',
                  t('rewardDescription')
                ),
                required: true
              },
              { max: 100, message: t('maxLimitIs100Characters') },
              {
                validator: (_, value) => {
                  if (value && value.trim() === '') {
                    return Promise.reject(new Error(t('descriptionCannotBeOnlySpaces')));
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input.TextArea rows={6} placeholder={t('rewardDescription')} size="large" />
          </Form.Item>
        </Col>
      </Row>

      {!disabled && (
        <Row gutter={12}>
          <Col xs={24} md={8} lg={6}>
            <Button block htmlType="submit" size="large" type="primary">
              <BoldButtonLabel labelText={labelText} />{' '}
            </Button>
          </Col>
          <Col xs={24} md={8} lg={6}>
            <Button
              block
              size="large"
              onClick={() => {
                const backUrl = searchParams.get('backUrl');
                if (backUrl) navigate(backUrl);
                else navigate(`/loyalty/config/rewards`);
              }}
            >
              <BoldButtonLabel labelText={t('back')} />{' '}
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default RewardForm;
