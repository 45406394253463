import { Card, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import DiscountTypePriority from './discount-type-priority';
import PromotionApplicationMethod from './promotion-application-method';

interface ICombinedPromotionConfigurationProps {
  activeTab: string;
  permission: boolean;
}

const CombinedPromotionConfiguration: React.FunctionComponent<ICombinedPromotionConfigurationProps> = ({
  activeTab,
  permission
}) => {
  const [applicationMethod, setApplicationMethod] = React.useState<string>('SEQUENTIAL');
  const [cpcActiveTab, setCpcActiveTab] = React.useState('1');
  const [tabDisable, setTabDisable] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (activeTab === '3') {
      // Add any necessary logic here if needed
    }
  }, [activeTab]);

  const handleOnChange = (value: string) => {
    setApplicationMethod(value);
  };

  const handleTabChange = (key: string) => {
    setCpcActiveTab(key);
  };

  const handleOnTabDisable = (status: boolean) => {
    setTabDisable(status);
  };

  return (
    <div>
      <Card>
        <Tabs activeKey={cpcActiveTab} onChange={handleTabChange}>
          <TabPane tab={<BoldButtonLabel labelText={t('promotionApplicationMethod')} />} key="1">
            <PromotionApplicationMethod
              handleOnChange={handleOnChange}
              handleOnTabDisable={handleOnTabDisable}
              cpcActiveTab={cpcActiveTab}
              activeTab={activeTab}
              permission={permission}
            />
          </TabPane>
          <TabPane tab={<BoldButtonLabel labelText={t('discountTypePriority')} />} key="2" disabled={tabDisable}>
            <DiscountTypePriority
              applicationMethod={applicationMethod}
              cpcActiveTab={cpcActiveTab}
              permission={permission}
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default CombinedPromotionConfiguration;
