import { DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Table,
  TableColumnsType,
  Tabs,
  Tooltip,
  Typography
} from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import dayjs from 'dayjs';
import i18next from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../../components/BoldButtonLabel';
import FieldDisplay from '../../../../components/FieldDisplay';
import GoBackButton from '../../../../components/GoBackButton';
import TenantInfo from '../../../../components/TenantIdInfo';
import { getAccess } from '../../../../constants/common-constants';
import { priceHelpers } from '../../../../data/intl-data';
import { fileHelpers, objectHelpers } from '../../../../helpers';
import {
  formatDate,
  getLanguageCode,
  resetISOTimeStampTimeToZeroIST,
  resetISOTimeStampTimeToZeroISTCurrent
} from '../../../../helpers/date.helpers';
import { fetchReqData, parseQueryString } from '../../../../helpers/file.helpers';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import { convertToTitleCase } from '../../../../helpers/utilHelpers';
import { generateUUIDV4 } from '../../../../helpers/uuid.helpers';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useLoader } from '../../../../stores/use-loader';
import { useLocale } from '../../../../stores/use-locale';
import ItemLevelView from '../../components/item-level-view';
import { useNodeDropdownSearch } from '../../hooks/drop-down-hooks/use-node-dropdown-search';
import { useOrderPicking } from '../../hooks/use-order-picking';
import { useOrderReceiving } from '../../hooks/use-order-receiving';
import { orderService } from '../../services';
import { IStatusAggregateResponse } from '../../types/ro-types';

const PAGE_SIZE = 10;

interface IReleaseOrderListProps {
  productOf: string;
}

const StatusTile = ({
  label,
  value,
  onClick,
  isActive
}: {
  label: string;
  value: string;
  onClick?: (status: string) => void;
  isActive?: boolean;
}) => {
  return (
    <Button
      size="large"
      type={isActive ? 'primary' : 'default'}
      onClick={() => {
        if (typeof onClick === 'function') onClick(value);
      }}
      style={{ height: '50px' }}
    >
      <BoldButtonLabel labelText={label} />
    </Button>
  );
};

const ReleaseOrderList: React.FunctionComponent<IReleaseOrderListProps> = ({ productOf }) => {
  const { permission } = getAccess(productOf);

  const [searchForm] = Form.useForm();
  const [packRoQuantityEditForm] = Form.useForm();
  const [packageAddForm] = Form.useForm();
  const [packROModalform] = Form.useForm();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [statusAggregateData, setStatusAggregateData] = React.useState({} as IStatusAggregateResponse);
  const [roListResponse, setRoListResponse] = React.useState({} as IReleaseOrdersResponse);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([] as string[]);
  const [expandedRowData, setExpandedRowData] = React.useState({} as Record<string, any>);
  const [activeStatusSearchKeys, setActiveStatusSearchKeys] = React.useState<string[]>([]);
  const [additionData, setAdditionData] = React.useState([] as string[]);
  const [addPackageData, setAddPackageData] = React.useState(null as any);
  const [shipRoInfo, setShipRoInfo] = React.useState<{ record: any; modalOpen?: boolean }>();
  const [rejectRoInfo, setRejectRoInfo] = React.useState<{ record: any; modalOpen?: boolean }>();
  const [holdRoInfo, setHoldRoInfo] = React.useState<{ record: any; modalOpen?: boolean }>();
  const [roCancelInfo, setRoCancelInfo] = React.useState<{
    modalOpen?: boolean;
    record?: any;
    cancellationLines: any[];
  }>({ cancellationLines: [], modalOpen: false, record: null });
  const [cancelRoQuantityEditForm] = Form.useForm();
  const [cancelRoQuantityFormVisible, setCancelRoQuantityFormVisible] = React.useState(false);
  const [currentEditingCancelRoLine, setCurrentEditingCancelRoLine] = React.useState({} as any);
  const [itemModalInfo, setItemModalInfo] = React.useState({ itemInfo: null, open: false } as {
    itemInfo: any;
    open: boolean;
  });
  const [packRoInfo, setPackROInfo] = React.useState({
    modalOpen: false,
    record: null,
    packages: [],
    foItemLines: []
  } as {
    modalOpen: boolean;
    record: any;
    packages: any[];
    foItemLines: any[];
  });
  const [currentEditingFoLineForPack, setCurrentEditingFoLineForPack] = React.useState({} as any);
  const [packRoQuantityFormVisible, setPackROQuantityFormVisible] = React.useState(false);
  const [showAddPackageModal, setShowAddPackageModal] = React.useState(false);
  const [packRoData, setPackROData] = React.useState(null as any);
  const [viewShippmentInfo, setViewShipmentInfo] = React.useState(false);
  const [shipmentData, setShipmentData] = React.useState([] as any);
  const [downloadShipModal, setDownloadShipModal] = React.useState(false);
  const [ShipmentDetailsDownloadResp, setShipmentDetailsDownloadResp] = React.useState([] as any);
  const [activeTabKey, setActiveTabKey] = React.useState<string>('1');
  const [auditModal, setAuditModal] = React.useState(false as boolean);
  const [auditDetails, setAuditDetails] = React.useState([] as any);

  const { baseCurrency, userCurrency } = useLocale();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const {
    changeOrderPickingModalVisibility,
    orderPickingData,
    orderPickingModalVisible,
    setOrderPickingData,
    validateOrderPickingData,
    transformAndSetOrderPickingData,
    validateOrderAndSetEditedPickedLine,
    closePickEditModal,
    pickingQuantityChangeModalVisible,
    currentPickingSkuDetails,
    handleClickPickEditQuantity,
    pickQuantityFormInstance,
    createPickRequest
  } = useOrderPicking();

  const {
    orderReceivingData,
    setOrderReceivingData,
    orderReceivingModalVisible,
    changeOrderReceivingModalVisibility,
    validateOrderReceivingData,
    transformAndSetOrderReceivingData,
    saveTheRecievedQuantityOnceConfirm,
    getReceiptNumber,
    receivingQuantityChangeModalVisible,
    handleClickReceiveEditQuantity,
    setReceivingQuantityChangeModalVisible,
    closeReceiveEditModal,
    currentReceivingSkuDetails,
    receiveQuantityFormInstance,
    createReceiveRequest,
    setReceiptNumbers
  } = useOrderReceiving();

  const backUrl = searchParams.get('backUrl');

  const { debouncedFetchNodeDropdownOptions, nodeDropdownFetching, nodeDropdownOptions } = useNodeDropdownSearch();
  dayjs.locale(getLanguageCode(i18next.language));

  React.useEffect(() => {
    setSearchParams(prev => {
      const searchParams = prev.toString();
      const searchParamObj = objectHelpers.convertQueryStringToObj(searchParams);
      const updated: any = {
        ...searchParamObj,
        status: activeStatusSearchKeys.join('')
      };
      return updated;
    });
    fetchROList();
  }, [activeStatusSearchKeys]);

  React.useEffect(() => {
    const searchParamObj = objectHelpers.convertQueryStringToObj(searchParams.toString());
    const { created_after, created_before } = searchParamObj;
    searchForm.setFieldsValue({
      ...searchParamObj,
      date_range: created_after ? [dayjs(created_after), dayjs(created_before)] : undefined
    });

    if (searchParamObj.status) {
      fetchReleaseOrderAggregates();
      setActiveStatusSearchKeys(searchParamObj?.status?.split(',') || []);
    } else {
      fetchReleaseOrderAggregates();
    }
  }, []);

  React.useEffect(() => {
    fetchROList();
  }, [searchParams]);

  const fetchReleaseOrderAggregates = async () => {
    setLoading(true);
    const { node_id, delivery_option, date_range = [] } = searchForm.getFieldsValue();

    const { data, errors } = await orderService.getReleaseOrderAggregates(
      node_id,
      delivery_option,
      date_range[0] ? resetISOTimeStampTimeToZeroIST(date_range[0]) : undefined
    );

    if (_.isEmpty(errors)) {
      setStatusAggregateData(data);
    }
    setLoading(false);
  };

  const handleFetchOrders = async () => {
    let formValues = searchForm.getFieldsValue();

    const transformedFormValues = {
      ...formValues,
      page_number: searchParams.get('page_number') || 1,
      page_size: searchParams.get('page_size') || 10
    };

    if (formValues.date_range) {
      const created_after = resetISOTimeStampTimeToZeroIST(formValues.date_range[0]);
      const created_before = resetISOTimeStampTimeToZeroIST(formValues.date_range[1]);
      formValues = { ...formValues, created_after, created_before, date_range: undefined };
      delete formValues['date_range'];
    }

    const filteredFormValues = objectHelpers.deleteUndefinedValuesFromObject(transformedFormValues);

    setSearchParams(filteredFormValues);

    await fetchReleaseOrderAggregates();
    await fetchROList();
  };

  const fetchROList = async (searchKey = activeStatusSearchKeys) => {
    setLoading(true);
    const { node_id, delivery_option, date_range, order_number, fo_number, ro_number, channel_id } =
      searchForm.getFieldsValue();
    //implemented after adding idempotent in Headers for Orch because of Render issue
    let idempotent_param = parseQueryString(window?.location?.search);
    if (idempotent_param?.order_number || idempotent_param?.fo_number) {
      setActiveTabKey('2');
    }
    let new_order_Num = idempotent_param?.order_number ? idempotent_param?.order_number : order_number;
    let new_fo_Num = idempotent_param?.fo_number ? idempotent_param?.fo_number : fo_number;
    const page_size = parseInt(searchParams.get('page_size') || '') || 10;
    const page_number = parseInt(searchParams.get('page_number') || '') || 1;
    const params = {
      node_id,
      channel_id,
      created_after: date_range ? resetISOTimeStampTimeToZeroIST(date_range[0]) : undefined,
      created_before: date_range ? resetISOTimeStampTimeToZeroISTCurrent(date_range[1]) : undefined,
      delivery_method: delivery_option,
      status: searchKey.join(','),
      offset: page_number - 1,
      limit: page_size,
      order_number: new_order_Num,
      fo_number: new_fo_Num,
      ro_number,
      order_type: searchParams.get('order_type') || undefined
    };
    const { data, errors } = await orderService.fetchRoList(objectHelpers.deleteUndefinedValuesFromObject(params));

    if (_.isEmpty(errors)) {
      const roResponse: any = data;
      const updatedRoList = roResponse.releaseorder_list?.map((ro: any) => ({ ...ro, key: ro.ro_number }));
      setRoListResponse({ ...roResponse, releaseorder_list: updatedRoList });
    }
    setLoading(false);
  };

  const handleFetchShipments = async (roNumber: string, roId: string, record: any) => {
    setExpandedRowData(expandedRowData => ({ ...expandedRowData, [roNumber]: record }));
  };

  const handleClickStatusSearchKey = (status: string) => {
    if (activeStatusSearchKeys.includes(status)) {
      setActiveStatusSearchKeys(activeStatusSearchKeys => activeStatusSearchKeys.filter(s => s !== status));
    } else setActiveStatusSearchKeys(s => [...s, status]);
  };

  const getShippingAddress = (addressObject: any) => {
    return `${addressObject?.address_line1 || ''}, ${addressObject?.address_line2 || ''}, ${
      addressObject?.location || ''
    }, ${addressObject?.state || ''}, ${addressObject?.country || ''} - ${addressObject?.postal_code || ''}`;
  };

  const getRoActionsBasedOnStatus = (
    status: string,
    on_hold?: boolean,
    status_number?: number,
    order_type?: string,
    tenant_id?: string,
    destination_node_type?: string
  ) => {
    const actions = [];
    // actions.push('VIEW');

    const isRelease = status === 'RELEASED' || status === 'PARTIALLY_RELEASED';

    if (isRelease && !on_hold) {
      actions.push('ACCEPT_RO', 'REJECT_RO', 'MARK_ONHOLD');
    }
    if (isRelease && on_hold) {
      actions.push('REJECT_RO', 'REMOVE_ONHOLD');
    }
    if (status === 'RELEASE_ACCEPTED') {
      actions.push('READY_FOR_PICK');
    }
    if (status === 'READY_TO_PICK') {
      actions.push('PICK');
    }
    if (status === 'PICKED') {
      actions.push('READY_FOR_PACK');
    }
    if (status === 'READY_TO_PACK') {
      actions.push('PACK');
    }
    if (status === 'PACKED') {
      actions.push('READY_TO_SHIP', 'GENERATE_SHIP_LABEL', 'GENERATE_INVOICE');
    }
    if (status === 'READY_TO_SHIP') {
      actions.push('SHIP');
    }

    if (status === 'SHIPPED') {
      actions.push('IN_TRANSIT');
    }
    if (status === 'IN_TRANSIT') {
      if (order_type === 'TRANSFER') {
        if (destination_node_type === 'STORE') {
          actions.push('ARRIVED_AT_STORE');
        } else actions.push('ARRIVED_AT_WH');
      } else actions.push('OUT_FOR_DELIVERY');
    }
    if (status === 'ARRIVED_AT_STORE' || status === 'ARRIVED_AT_WH') {
      actions.push('READY_TO_RECEIVE');
    }
    if (status === 'PARTIALLY_RECEIVED' && order_type === 'TRANSFER') {
      actions.push('PARTIALLY_RECEIVED');
    }
    if (status === 'RECEIVED' && order_type === 'TRANSFER') actions.push('TRANSFER_RECEIVED');

    if (status === 'OUT_FOR_DELIVERY') {
      actions.push('DELIVER');
    }
    if (tenant_id === 'ejje') {
      if ((status_number as number) < 2900) actions.push('CANCEL');
    } else if ((status_number as number) < 3300) {
      actions.push('CANCEL');
    }
    return actions;
  };

  const RoColumns: TableColumnsType<any> = [
    {
      title: t('releaseInformation'),
      align: 'left',
      width: '500px',
      render: (_, record) => {
        let ship_Details = fetchReqData(record?.address, 'address_tag', 'ship-to-details');
        let dispatch_from_details = fetchReqData(record?.address, 'address_tag', 'dispatch-from-details');
        return (
          <div className="w-[150px]">
            <div className="flex gap-[10px]">
              <Typography className="font-bold">{t('roNo')} :</Typography> {record.ro_number}
            </div>
            {record.order_type === 'FORWARD' && (
              <Tooltip placement="top" title={getShippingAddress(ship_Details?.address_info)}>
                <div className="flex">
                  <Typography className="font-bold">{t('shipNodeName')} :</Typography>&nbsp;{record.node_name}
                </div>
              </Tooltip>
            )}
            {/* {record.order_type === 'FORWARD' && <div className='flex flex-col'><Typography className='font-bold'>Dispatch Address : </Typography>{getShippingAddress(record.ship_to_details.address_info)} </div>} */}
            {record.order_type === 'FORWARD' && (
              <div className="flex flex-col">
                <Typography className="font-bold">{t('shipAddress')} : </Typography>
                {getShippingAddress(dispatch_from_details?.address_info)}{' '}
              </div>
            )}
          </div>
        );
      }
    },
    {
      title: t('releaseDate'),
      align: 'center',
      width: '300px',
      render: (_, record) => {
        let dateData = fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'release-date');
        return <>{formatDate(dateData?.date_time_stamp)}</>;
      }
    },
    {
      title: t('channelId'),
      align: 'center',
      width: '200px',
      render: (_, record: any) => {
        return (
          <section>
            <div>{record?.channel_id}</div>
          </section>
        );
      }
    },
    {
      title: t('orderType'),
      align: 'center',
      width: '300px',
      render: (_, record) => {
        return <>{t(record?.order_type)}</>;
      }
    },
    {
      title: t('status'),
      align: 'center',
      render: (_, record) => {
        return <>{t(record?.status_description)}</>;
      }
    },
    {
      title: t('onhold'),
      align: 'center',
      render(value, record: any, index) {
        return record?.is_on_hold ? (
          <Popover
            title={
              <>
                {' '}
                {t('holdReason')} - {record?.hold_reason}
              </>
            }
          >
            <Button type="link"> {t('yes')} </Button>
          </Popover>
        ) : (
          <> {t('no')} </>
        );
      }
    },
    {
      title: t('deliveryOption'),
      align: 'center',
      render: (_, record) => {
        return <>{t(record?.fulfilment_info?.delivery_method)}</>;
      }
    },
    {
      title: t('shipNode'),
      align: 'center',
      render: (_, record) => {
        return <>{record?.node_name}</>;
      }
    },
    {
      title: t('plannedShipDate'),
      align: 'center',
      width: '300px',
      render: (_, record) => {
        let dateData = record?.fulfilment_info
          ? fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'estimated-shipping-date')
          : '';
        return <>{formatDate(dateData?.date_time_stamp)}</>;
      }
    },
    {
      title: t('plannedDeliveryPickupReceiveDate'),
      align: 'center',
      render: (_, record) => {
        const orderType = record?.order_type;
        const promiseDeliveryDate = record?.fulfilment_info
          ? fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'promise-delivery-date')
          : '';
        const estimatedPickUpDate = record?.fulfilment_info
          ? fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'estimated-pick-up-date')
          : '';
        let label = '';
        if (orderType === 'TRANSFER') {
          label = t('receiveDate');
        } else if (estimatedPickUpDate) {
          label = t('pickUpDate');
        } else {
          label = t('deliveryDate');
        }
        return (
          <div className="w-[110px]">
            <span className="font-bold">{label}: </span>
            {promiseDeliveryDate ? `${formatDate(promiseDeliveryDate?.date_time_stamp)}` : ''}
            {estimatedPickUpDate ? `${formatDate(estimatedPickUpDate?.date_time_stamp)}` : ''}
          </div>
        );
      }
    },
    {
      title: t('dates'),
      align: 'center',
      width: '400px',
      render: (_, record: any) => {
        if (record?.status_description === 'PICKED_UP' && record?.fulfilment_info?.delivery_method === 'PICK_UP') {
          let actualPickUpData = fetchReqData(
            record?.fulfilment_info?.fulfillment_dates,
            'date_type',
            'actual-picked-up-date'
          );
          return (
            <div className="flex flex-col">
              <Typography className="font-bold">{t('pickedUpDate')} - </Typography>
              {formatDate(actualPickUpData?.date_time_stamp)}
            </div>
          );
        } else {
          let actualShippingData = fetchReqData(
            record?.fulfilment_info?.fulfillment_dates,
            'date_type',
            'actual-shipping-date'
          );
          let actualDeliveryData = fetchReqData(
            record?.fulfilment_info?.fulfillment_dates,
            'date_type',
            'actual-delivery-date'
          );

          let actualReceiveDate = record?.fulfilment_info
            ? fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'actual-receive-date')
            : '';

          return (
            <div className="w-[120px]">
              {actualShippingData && (
                <div className="flex flex-col">
                  <Typography className="font-bold">{t('shipDate')} : </Typography>{' '}
                  {formatDate(actualShippingData?.date_time_stamp)}
                </div>
              )}
              {actualDeliveryData && (
                <div className="flex flex-col">
                  <Typography className="font-bold">{t('deliveryDate')} :</Typography>{' '}
                  {formatDate(actualDeliveryData?.date_time_stamp)}
                </div>
              )}
              <>
                {actualReceiveDate && (
                  <div className="flex flex-col">
                    <Typography className="font-bold">{t('receivedDate')} :</Typography>{' '}
                    {formatDate(actualReceiveDate?.date_time_stamp)}
                  </div>
                )}
              </>
            </div>
          );
        }
      }
    },
    {
      title: t('orderedQty'),
      align: 'center',
      render: (_, record) => {
        let quantity = 0;
        record?.fo_details.forEach((fo: any) => {
          const qty = fo.item_list?.reduce((acc: any, curr: any) => {
            return acc + curr.original_quantity?.number_of_units;
          }, 0);
          quantity += qty;
        });
        return <>{quantity}</>;
      }
    },
    {
      title: t('cancelledQty'),
      align: 'center',
      render: (_, record) => {
        let canceledQuantity = 0;
        record.fo_details.forEach((foDetail: any) => {
          foDetail.item_list.forEach((item: any) => {
            item.tags.forEach((tag: any) => {
              tag.statuses.forEach((status: any) => {
                if (status.status_description === 'CANCELLED') {
                  canceledQuantity += status.quantity.number_of_units;
                }
              });
            });
          });
        });
        return <>{canceledQuantity}</>;
      }
    },
    {
      title: t('totalNoOfLines'),
      align: 'center',
      render: (_, record) => {
        const lineCount = record?.fo_details.reduce((acc: any, curr: any) => {
          return acc + curr.item_list?.length;
        }, 0);
        return <>{lineCount}</>;
      }
    }
  ];

  if (permission) {
    RoColumns.push({
      title: t('action'),
      align: 'center',
      render: (_, record) => {
        const deliveryOption = record?.fulfilment_info?.delivery_method;

        if (deliveryOption === 'PICK_UP' && record.status_description === 'PACKED') {
          return (
            <Button
              type="link"
              onClick={() => {
                handleReadyToPickforDeliveryOption(record);
              }}
            >
              {t('readyForPickUp')}
            </Button>
          );
        }
        if (deliveryOption === 'PICK_UP' && record.status_description === 'READY_FOR_PICKUP') {
          return (
            <Button
              type="link"
              onClick={() => {
                handleReadyToPickforDeliveryOptionNew(record);
              }}
            >
              {t('pickedUp')}
            </Button>
          );
        }
        const actions = getRoActionsBasedOnStatus(
          record?.status_description,
          record?.is_on_hold,
          record?.status,
          record?.order_type,
          record?.tenant_id,
          record?.destination_node_type
        );
        const actionButtons = actions?.map(action => {
          if (action === 'ACCEPT_RO')
            return (
              <Button
                type="link"
                onClick={() => {
                  changeRoAckowledgement(record, 'ACCEPTED');
                }}
              >
                {t('accept')}
              </Button>
            );
          if (action === 'REJECT_RO')
            return (
              <Button
                type="link"
                onClick={() => {
                  setRejectRoInfo({ record, modalOpen: true });
                }}
              >
                {t('reject')}
              </Button>
            );
          if (action === 'MARK_ONHOLD')
            return (
              <Button type="link" onClick={() => setHoldRoInfo({ record, modalOpen: true })}>
                {t('onHold')}
              </Button>
            );

          if (action === 'READY_FOR_PICK')
            return (
              <Button
                type="link"
                onClick={() => {
                  handleReadyToPick(record);
                }}
              >
                {t('readyForPick')}
              </Button>
            );

          if (action === 'READY_FOR_PACK')
            return (
              <Button
                type="link"
                onClick={() => {
                  handleReadyToPack(record);
                }}
              >
                {t('readyForPack')}
              </Button>
            );

          if (action === 'PACK')
            return (
              <Button
                type="link"
                onClick={() => {
                  handlePackRO(record);
                }}
              >
                {t('pack')}
              </Button>
            );

          if (action === 'READY_TO_SHIP')
            return (
              <Button
                type="link"
                onClick={() => {
                  handleReadyToShip(record);
                }}
              >
                {t('readyToShip')}
              </Button>
            );
          if (action === 'REMOVE_ONHOLD')
            return (
              <Button
                type="link"
                onClick={() => {
                  changeRoAckowledgement(record, 'REMOVE_HOLD');
                }}
              >
                {t('removeHold')}
              </Button>
            );
          if (action === 'PICK')
            return (
              <Button type="link" onClick={() => handlePickComplete(record)}>
                {t('pick')}
              </Button>
            );

          if (action === 'SHIP') {
            return (
              <Button type="link" onClick={() => handleClickShipRo(record)}>
                {t('ship')}
              </Button>
            );
          }
          if (action === 'IN_TRANSIT') {
            return (
              <Button type="link" onClick={() => handleCarrierUpdate(record, 'IN_TRANSIT')}>
                {t('inTransit')}
              </Button>
            );
          }
          if (action === 'READY_TO_RECEIVE') {
            return (
              <Button type="link" onClick={() => handleReceiveComplete(record)}>
                {t('readyToReceive')}
              </Button>
            );
          }
          if (action === 'PARTIALLY_RECEIVED') {
            return (
              <Button type="link" onClick={() => handleReceiveComplete(record)}>
                {t('partiallyReceived')}
              </Button>
            );
          }
          if (action === 'ARRIVED_AT_STORE') {
            return (
              <Button type="link" onClick={() => handleCarrierUpdate(record, 'ARRIVED_AT_STORE')}>
                {t('arrivedAtStore')}
              </Button>
            );
          }
          if (action === 'ARRIVED_AT_WH') {
            return (
              <Button type="link" onClick={() => handleCarrierUpdate(record, 'ARRIVED_AT_WH')}>
                {t('arrivedAtWarehouse')}
              </Button>
            );
          }
          if (action === 'TRANSFER_RECEIVED') {
            return (
              <Typography.Text type="secondary" style={{ minWidth: '100px' }}>
                {t('received')}
              </Typography.Text>
            );
          }

          if (action === 'OUT_FOR_DELIVERY') {
            return (
              <Button type="link" onClick={() => handleCarrierUpdate(record, 'OUT_FOR_DELIVERY')}>
                {t('outForDelivery')}
              </Button>
            );
          }
          if (action === 'DELIVER') {
            return (
              <Button type="link" onClick={() => handleCarrierUpdate(record, 'DELIVERED')}>
                {t('deliver')}
              </Button>
            );
          }
          if (action === 'CANCEL') {
            return (
              <Button type="link" onClick={() => handleClickCancelRo(record)}>
                {t('cancel')}
              </Button>
            );
          }
        });

        return <section className="flex gap-2 justify-center items-center">{actionButtons}</section>;
      }
    });
  }

  const getReceiptNumberOnReceivedQuantityConfirmed = async (
    orderNumber: string,
    roNumber: string,
    foNumber: string,
    destinationNodeId: string
  ) => {
    setLoading(true);
    const body = {
      order_number: orderNumber,
      ro_number: roNumber,
      fo_number: foNumber,
      destination_node_id: destinationNodeId
    };
    const { errors, data } = await orderService.getReceiptNumber(body);
    if (!_.isEmpty(errors)) {
      setLoading(false);
      return displayErrorNotifications(errors);
    } else {
      const newReceipt = {
        [`${orderNumber}-${roNumber}-${foNumber}`]: data?.receipt_number || ''
      };
      receiveQuantityFormInstance.setFieldsValue({
        ...receiveQuantityFormInstance.getFieldsValue(),
        receipt_number: data?.receipt_number
      });
      setReceiptNumbers((prev: any) => ({ ...prev, ...newReceipt }));
      displaySuccessNotification({ message: t('receiptNumberGeneratedSuccessfully') });
      refetchPageDetails();
    }
    setLoading(false);
  };

  const handleClickGenerateInvoiceNew = async (record: any) => {
    setLoading(true);
    const { errors, data } = await orderService.generateInvoiceNew(record?.ro_number);
    if (!_.isEmpty(errors)) {
      setLoading(false);
      return displayErrorNotifications(errors);
    } else {
      displaySuccessNotification({
        message: t('invoiceGeneratedSuccessfullyForRONumber', { roNumber: data.ro_number })
      });
      refetchPageDetails();
    }
    setLoading(false);
  };

  const handleCLickGenerateShipLabelNew = async (record: any) => {
    setLoading(true);
    const { errors } = await orderService.generateShiplabelNew(record?.ro_number);
    if (!_.isEmpty(errors)) {
      setLoading(false);
      return displayErrorNotifications(errors);
    } else {
      displaySuccessNotification({ message: t('shipmentLabelCreated') });
      refetchPageDetails();
    }
    setLoading(false);
  };

  const handleOnShipDownload = async (record: any) => {
    setShipmentDetailsDownloadResp(record?.shipment_details);
    setDownloadShipModal(true);
  };

  const downloadInvoicePdf = async (record: any) => {
    setLoading(true);
    if (Object.keys(record.invoice)) {
      const { data, errors } = await orderService.downloadPDF(record.invoice.invoice_link);
      if (_.isEmpty(errors)) {
        fileHelpers.triggerFileDownload({ data, fileName: 'Report', extension: '.pdf' });
        displaySuccessNotification({ message: t('downloadSuccess') });
      } else {
        displayErrorNotifications(errors);
      }
    } else {
      displayErrorNotifications([{ message: t('invoiceNotFound') }]);
    }
    setLoading(false);
  };

  const handleOnDownloadlabelLink = async (labelLink: any) => {
    const { data, errors } = await orderService.downloadShipLabel(labelLink);
    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'Report', extension: '.pdf' });
      displaySuccessNotification({ message: t('downloadSuccess') });
    } else {
      displayErrorNotifications(errors);
    }
  };

  const handleCarrierUpdate = async (record: any, status: string) => {
    setLoading(true);
    if (record.order_type === 'TRANSFER') {
      const body = generateStatusUpdateRequest(record, status);
      const { data, errors } = await orderService.handleRoStatusUpdate(body);
      if (_.isEmpty(errors)) {
        displaySuccessNotification({ message: t('updatedSuccessfully') });

        refetchPageDetails();
      }
    } else {
      const { business_group_id, tenant_id, ro_number, shipment_details } = record;
      const { eway_bill_no, awb_no, parent_awb_no, parent_eway_bill_no } = shipment_details?.tracking_info || {};
      const request = {
        eway_bill_no: eway_bill_no ? eway_bill_no : null,
        awb_no: awb_no ? awb_no : null,
        ro_number: record.ro_number,
        status
      };
      const { errors } = await orderService.carrierUpdate(request);
      if (_.isEmpty(errors)) {
        displaySuccessNotification({
          message: t('carrierUpdateToStatusSuccess', { status: t(status) })
        });

        refetchPageDetails();
      }
    }

    setLoading(false);
  };

  const handleClickShipRo = async (record: any) => {
    const request = generateStatusUpdateRequest(record, 'SHIPPED');
    const { data, errors } = await orderService.handleRoStatusUpdate(request);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('roShipped') });
      refetchPageDetails();
    }
  };

  const handleReadyToPick = async (record: any) => {
    const request = generateStatusUpdateRequest(record, 'READY_TO_PICK');
    const { data, errors } = await orderService.handleRoStatusUpdate(request);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('roMarkedReadyToPick') });
      refetchPageDetails();
    }
  };

  const handleReadyToPickforDeliveryOption = async (record: any) => {
    const request = generateStatusUpdateRequest(record, 'READY_FOR_PICKUP');
    const { data, errors } = await orderService.handleRoStatusUpdate(request);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('roMarkedReadyToPickUp') });
      refetchPageDetails();
    }
  };

  const handleReadyToPickforDeliveryOptionNew = async (record: any) => {
    const request = generateStatusUpdateRequest(record, 'PICKED_UP');
    const { data, errors } = await orderService.handleRoStatusUpdate(request);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('roMarkedReadyToPickUp') });
      refetchPageDetails();
    }
  };

  const handleReadyToPack = async (record: any) => {
    const request = generateStatusUpdateRequest(record, 'READY_TO_PACK');
    const { data, errors } = await orderService.handleRoStatusUpdate(request);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('roMarkedReadyToPack') });
      refetchPageDetails();
    }
  };

  const handleReadyToShip = async (record: any) => {
    const request = generateStatusUpdateRequest(record, 'READY_TO_SHIP');
    const { data, errors } = await orderService.handleRoStatusUpdate(request);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('roMarkedReadyToShip') });
      refetchPageDetails();
    }
  };

  const handleSubmitParcelRequest = async (formValues: any) => {
    setLoading(true);
    const roRecord = shipRoInfo?.record;
    const { tenant_id, business_group_id, ro_number } = roRecord;
    const {
      package_height_in_cms,
      package_width_in_cms,
      package_length_in_cms,
      container_type,
      veh_type,
      package_weight_in_kgs,
      parent_eway_bill_no,
      eway_bill_no,
      parent_awb_no,
      awb_no,
      veh_no,
      delivery_notes
    } = formValues;

    const parcel_lines = roRecord?.fo_details?.map((fo: any) => {
      const fo_lines = fo.item_list.map((item: any) => {
        const fulfilled_quantity = item.tags.map((tag: any) => {
          const { tag_id, tag_type, quantity, serial_id } = tag;
          return {
            tag_id,
            quantity,
            tag_type,
            serial_id,
            back_order_quantity: tag?.back_order_details?.back_order_quantity
          };
        });

        return {
          item: { fulfilled_quantity, sku_id: item?.sku_id, seller_info: item?.seller_info },
          co_line_id: item?.co_line_id
        };
      });

      return {
        fo_lines,
        fo_number: fo?.fo_number,
        order_number: fo?.order_number
      };
    });

    const request = {
      tenant_id,
      business_group_id,
      ro_number,
      parcel: {
        shipment_no: generateUUIDV4(),
        shipment_date: new Date().toISOString(),
        parcel_lines,
        carrier_id: 'string', //!Pending
        delivery_notes,

        delivery_speed: roRecord?.delivery_info?.delivery_speed,
        ship_node: roRecord?.delivery_info?.ship_node_id,
        delivery_method: roRecord?.delivery_info?.delivery_method,
        package_details: {
          package_height_in_cms,
          package_length_in_cms,
          package_weight_in_kgs,
          package_width_in_cms
        },

        carrier_name: 'string', //!Pending

        veh_type,
        tracking_info: {
          parent_eway_bill_no,
          parent_awb_no,
          eway_bill_no,
          awb_no
        },
        fulfillment_type: roRecord?.delivery_info?.delivery_method, //!pending

        container_type,

        veh_no,
        delivery_price_info: {
          amount: 0,
          currency_code: 'string',
          fraction: 1
        }
      },
      status: 'SHIPPED',
      created_at: new Date().toISOString()
    };

    const { errors } = await orderService.parcelRo(request);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('roParcelUpdateSuccess') });

      setShipRoInfo({ record: null, modalOpen: false });
      refetchPageDetails();
    }
    setLoading(false);
  };

  const getfulFilmentQty = (tags: any) => {
    let tagData = tags.map((item: any) => {
      return {
        tag_id: item.tag_id,
        tag_type: item.tag_type,
        quantity: item.quantity,
        mark_remaining_as_backorder: false
        // back_order_quantity: item.back_order_details?.back_order_quantity ? item.back_order_details?.back_order_quantity : item?.back_order_quantity
      };
    });

    return tagData;
  };

  const getFoLines = (foItem: any) => {
    let foData = foItem.map((item: any) => {
      return {
        co_line_id: item.co_line_id,
        sku_id: item.sku_id,
        fulfilled_quantity: getfulFilmentQty(item.tags)
      };
    });
    return foData;
  };

  const generateStatusUpdateRequest = (record: any, status: string) => {
    const { business_group_id, tenant_id, ro_number } = record;

    const foDetails = record?.fo_details || [];
    const newLines = foDetails?.map((item: any) => {
      return {
        order_number: item?.order_number,
        fo_number: item?.fo_number,
        fo_lines: getFoLines(item.item_list)
      };
    });

    const newRequest = {
      business_group_id,
      tenant_id,
      ro_number,
      lines: newLines,
      created_at: new Date().toISOString(),
      created_by: 'ui',
      status,
      status_datetime: new Date().toISOString()
    };

    return newRequest;
  };

  const getItemDimensionsDataSource = (shipRoInfo: any) => {
    const itemList = shipRoInfo?.record?.fo_details?.flatMap((fo: any) => {
      return fo?.item_list?.map((item: any) => item);
    });
    return itemList;
  };

  const expandedRowRender = () => {
    if (_.isEmpty(expandedRowData[expandedRowKeys[0]])) return <>{t('noItemsInShipment')}</>;

    const columns: TableColumnsType<any> = [
      {
        title: t('item'),
        align: 'center',
        render: (_, record) => (
          <div className="flex flex-col">
            <div className="flex justify-center">
              <Typography className="font-bold">{t('sku')}: </Typography> {record?.sku_id} <br /> {record?.description}
            </div>
            <div
              className="text-[#1e90ffde] underline cursor-pointer"
              onClick={() => {
                setAuditDetails(record);
                setAuditModal(true);
              }}
            >
              {t('showAudit')}
            </div>
          </div>
        )
      },
      {
        title: t('orderNumber'),
        align: 'center',

        render: (_, record: any) => {
          return <>{record?.order_number}</>;
        }
      },
      {
        title: t('orderCreatedDate'),
        align: 'center',
        render: (_, record: any) => {
          return <>{formatDate(record?.order_start_trans_datetime)}</>;
        }
      },

      {
        title: t('fulfillments'),
        align: 'center',
        render: (_, record: any) => {
          return (
            <div>
              <Typography className="font-bold">{t('fo')}: </Typography> &nbsp;{record.fo_number}{' '}
            </div>
          );
        }
      },
      {
        title: t('fulfillmentType'),
        align: 'center',
        render: (_, record) => {
          return <>{t(record.fulfillment_type)}</>;
        }
      },

      {
        title: t('quantity'),
        align: 'center',
        render: (_, record) => <>{record?.quantity}</>
      },

      {
        title: t('total'),
        align: 'center',
        render: (_, record: any) => {
          return (
            <>
              {priceHelpers.formatPriceToLocaleCurrency({
                amount: record.total_item_value,
                baseCurrency: 'INR',
                userCurrency: 'INR'
              })}
            </>
          );
        }
      },
      {
        title: t('status'),
        align: 'center',
        render: (_, record: any) => {
          return <>{t(record.status)}</>;
        }
      },
      {
        title: t('action'),
        align: 'center',
        render: (_, record: any) => {
          return (
            <Button
              onClick={() => {
                showItemLevelModal(record);
              }}
              type="link"
            >
              {t('view')}
            </Button>
          );
        }
      }
    ];

    const fos = expandedRowData[expandedRowKeys[0]]?.fo_details || [];
    const fulfilInfo = expandedRowData[expandedRowKeys[0]]?.fulfilment_info || {};
    const roDetails = expandedRowData[expandedRowKeys[0]];

    const tableData: any = [];
    fos.forEach((fo: any) => {
      const { channel_id } = fo;
      fo.item_list.forEach((item: any) => {
        const { sku_id, product_desc, status_description, total_amount } = item;
        tableData.push({
          ...item,
          channel_id: channel_id,
          fo_number: fo.fo_number,
          order_number: fo.order_number,
          sku_id: sku_id,
          key: sku_id,
          description: product_desc,
          created_date: roDetails?.created_datetime,
          status: status_description,
          quantity: item.quantity?.number_of_units,
          fulfillment_type: fulfilInfo?.delivery_method,
          status_description: roDetails.status_description,
          amount: total_amount,
          order_start_trans_datetime: fo.order_start_trans_datetime
        });
      });
    });

    const shipmentInfo = roDetails?.shipment_details;

    const roId = roListResponse.releaseorder_list.find(ro => ro.ro_number === expandedRowKeys[0])?.id || '';

    return (
      <section className="pl-8">
        <Card>
          {shipmentInfo && (
            <section
              style={{
                background: '#9b9b9b29',
                padding: '20px',
                borderRadius: '5px',
                marginBottom: '15px'
              }}
            >
              <div className="my-auto font-bold text-base">
                <Button type="primary" size="large" onClick={shipmentInfoModal}>
                  <BoldButtonLabel labelText={t('viewShipmentInfo')} />
                </Button>
              </div>
            </section>
          )}
          <section className="flex items-center gap-4 my-2 justify-between">
            <div className="my-auto font-bold text-base">{t('items')}</div>
          </section>
          <Table className="mt-2" bordered columns={columns} dataSource={tableData} pagination={false} />
        </Card>
      </section>
    );
  };

  const refetchPageDetails = async () => {
    setExpandedRowData({});
    setExpandedRowKeys([]);
    fetchReleaseOrderAggregates();
    fetchROList();
  };

  const orderPickingTableColumns: TableColumnsType<any> = [
    {
      title: t('orderNumber'),
      dataIndex: 'order_number',
      align: 'center'
    },
    {
      title: t('orderCreatedDate'),
      render(value, record, index) {
        return formatDate(record.created_date);
      },
      align: 'center'
    },
    {
      title: t('fulfillments'),
      align: 'left',
      width: '400px',
      render: (_, record: any) => {
        return (
          <>
            <div>
              {t('fo')} - {record?.fo_number}{' '}
            </div>
            <div>
              {t('shipnode')} - {record?.node_id}
            </div>
          </>
        );
      }
    },

    {
      title: t('fulfillmentType'),
      align: 'center',
      render: (_, record) => {
        return <>{t(record?.delivery_method)}</>;
      }
    },
    {
      title: t('item'),
      width: 300,
      align: 'center',
      render: (_, record) => (
        <>
          {record?.sku_id} <br /> {record?.sku_description}
        </>
      )
    },
    {
      title: t('plannedQuantity'),
      align: 'center',
      render: (_, record) => <> {record?.quantity?.number_of_units} </>
    },
    {
      title: t('pickQuantity'),
      align: 'center',
      render: (_, record) => (
        <Button
          type="ghost"
          onClick={() => {
            handleClickPickEditQuantity(record.id);
          }}
        >
          {record.existing_picked_quantity + record.editable_pick_quantity} / {record?.quantity?.number_of_units}{' '}
          <EditOutlined />
        </Button>
      )
    },
    {
      title: t('backorder'),
      align: 'center',
      render: (_, record) => <> {record?.editable_backorder_quantity + record?.existing_backorder_quantity} </>
    },

    {
      title: t('total'),
      align: 'center',
      render: (_, record: any) => {
        return (
          <>
            {priceHelpers.formatPriceToLocaleCurrency({
              amount: record.total_amount,
              baseCurrency: 'INR',
              userCurrency: 'INR'
            })}
          </>
        );
      }
    },
    {
      title: t('status'),
      align: 'center',
      render: (_, record: any) => {
        return <>{t(record.status)}</>;
      }
    }
  ];

  const orderReceivingTableColumns: TableColumnsType<any> = [
    {
      title: t('orderNumber'),
      dataIndex: 'order_number',
      align: 'center'
    },
    {
      title: t('orderCreatedDate'),
      render(value, record, index) {
        return formatDate(record.created_date);
      },
      align: 'center'
    },
    {
      title: t('fulfillments'),
      align: 'left',
      width: '400px',
      render: (_, record: any) => {
        return (
          <>
            <div>
              {t('to')} - {record?.fo_number}{' '}
            </div>
            <div>
              {t('shipnode')} - {record?.node_id}
            </div>
          </>
        );
      }
    },

    {
      title: t('fulfillmentType'),
      align: 'center',
      render: (_, record) => {
        return <>{t(record?.delivery_method || '')}</>;
      }
    },
    {
      title: t('item'),
      width: 300,
      align: 'center',
      render: (_, record) => (
        <>
          {record?.sku_id} <br /> {record?.sku_description}
        </>
      )
    },
    {
      title: t('requestQuantity'),
      align: 'center',
      render: (_, record) => <> {record?.total_requested_quantity} </>
    },
    {
      title: t('receivedQuantity'),
      align: 'center',
      render: (_, record) => (
        <Button
          type="ghost"
          onClick={() => {
            handleClickReceiveEditQuantity(record.id);
          }}
        >
          {record.received_quantity || 0} / {record?.total_receivable_quantity} <EditOutlined />
        </Button>
      )
    },
    {
      title: t('shippedQuantity'),
      align: 'center',
      render: (_, record) => <> {record?.total_shipped_quantity} </>
    },
    // {
    //   title: t('backorder'),
    //   align: 'center',
    //   render: (_, record) => <> {record?.editable_backorder_quantity + record?.existing_backorder_quantity} </>
    // },

    {
      title: t('total'),
      align: 'center',
      render: (_, record: any) => {
        return (
          <>
            {priceHelpers.formatPriceToLocaleCurrency({
              amount: record.total_amount,
              baseCurrency: 'INR',
              userCurrency: 'INR'
            })}
          </>
        );
      }
    },
    {
      title: t('status'),
      align: 'center',
      render: (_, record: any) => {
        return <>{t(record.status || '')}</>;
      }
    }
  ];

  const handlePickComplete = (record: IReleaseOrderList) => {
    //!Open Order Picking Modal
    //!Tag wise order picking.
    transformAndSetOrderPickingData(record);
    changeOrderPickingModalVisibility(true);

    //!Populate Order Picking Data
  };
  const handleReceiveComplete = (record: IReleaseOrderList) => {
    transformAndSetOrderReceivingData(record);
    changeOrderReceivingModalVisibility(true);
  };

  const handleSubmitPickRequest = async () => {
    setLoading(true);
    const request = createPickRequest();
    const { errors } = await orderService.pickOrder(request);

    if (_.isEmpty(errors)) {
      changeOrderPickingModalVisibility(false);
      displaySuccessNotification({ message: t('releaseOrderPickSuccessful') });
      refetchPageDetails();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleSubmitReceiveRequest = async () => {
    setLoading(true);
    const request = createReceiveRequest();
    const { errors } = await orderService.receiveOrder(request);
    if (_.isEmpty(errors)) {
      changeOrderReceivingModalVisibility(false);
      displaySuccessNotification({ message: t('receivedItemsUpdated') });
      refetchPageDetails();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const changeRoAckowledgement = async (
    order: any,
    status: 'ACCEPTED' | 'REJECTED' | 'ON_HOLD' | 'REMOVE_HOLD',
    reason?: String
  ) => {
    setLoading(true);
    const request = {
      business_group_id: order.business_group_id,
      tenant_id: order.tenant_id,
      ro_number: order.ro_number,
      status,
      reason,
      status_datetime: new Date().toISOString()
    };

    const { errors } = await orderService.handleRoStatusChange(request);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Release order status updated' });
      refetchPageDetails();
    }
    setLoading(false);
  };

  const handlePackRO = async (record: any) => {
    setPackROInfo({ modalOpen: true, record, packages: [], foItemLines: [] });
    setPackROData(record);
  };

  const roCancelColumns: any = [
    { title: t('sku'), dataIndex: 'sku_id', align: 'center' },
    {
      title: t('orderQuantity'),
      dataIndex: 'sku_id',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record?.quantity?.number_of_units}</>;
      }
    },
    {
      title: t('cancelQuantity'),
      dataIndex: 'editable_cancel_quantity',
      align: 'center',
      editable: true,
      render: (_: any, record: any) => {
        return (
          <Button
            type="ghost"
            onClick={() => {
              setCurrentEditingCancelRoLine(record);
              setCancelRoQuantityFormVisible(true);
              cancelRoQuantityEditForm.setFieldsValue({
                cancel_reason: record?.cancel_reason,
                tags_cancel_info: record.tagLevelQuantities.map((tag: any) => {
                  return {
                    sku_id: record?.sku_id,
                    product_name: record?.product_name,

                    ...tag,
                    quantity_number: tag?.quantity?.number_of_units
                  };
                })
              });
            }}
          >
            {record.editable_cancel_quantity} <EditOutlined />
          </Button>
        );
      }
    }
  ];

  const handleClickCancelRo = (record: any) => {
    // console.log({ record });
    const tagLevelLines: any[] = [];
    record?.fo_details?.forEach((fo: any) => {
      fo.item_list.forEach((item: any) => {
        const tagLevelQuantities = item.tags.map((tag: any) => ({ ...tag, editable_cancel_quantity: 0 }));
        tagLevelLines.push({
          order_fo_combination: `${fo.order_number}-${fo.fo_number}`,
          fo_number: fo.fo_number,
          order_number: fo.order_number,
          sku_id: item.sku_id,
          product_desc: item.product_desc,
          product_name: item.product_name,
          status: item.status,
          // tag_id: tag.tag_id,
          // tag_type: tag.tag_type,
          quantity: item.quantity,
          editable_cancel_quantity: 0,
          tagLevelQuantities,
          co_line_id: item.co_line_id,
          key: item.co_line_id
        });
      });
    });
    setRoCancelInfo({
      modalOpen: true,
      cancellationLines: tagLevelLines,
      record
    });
  };

  const onEditCancelFoQuantityLineSave = () => {
    const { record } = roCancelInfo;

    const tagsFormInfo = cancelRoQuantityEditForm.getFieldsValue().tags_cancel_info || [];
    const cancellationReason = cancelRoQuantityEditForm.getFieldsValue().cancel_reason;
    const currentEditingSku = currentEditingCancelRoLine?.sku_id;

    setRoCancelInfo(info => {
      const updatedTagLevelLines = info.cancellationLines.map(line => {
        if (line.sku_id === currentEditingSku) {
          const tagLevelQuantities = line.tagLevelQuantities.map((tag: any) => {
            const updatedCancelQuantity =
              tagsFormInfo.find((tagForm: any) => tag.tag_id === tagForm.tag_id)?.editable_cancel_quantity || 0;
            return {
              ...tag,
              editable_cancel_quantity: updatedCancelQuantity
            };
          });

          return {
            ...line,
            tagLevelQuantities,
            cancel_reason: cancellationReason,
            editable_cancel_quantity: tagLevelQuantities.reduce((acc: any, curr: any) => {
              return acc + curr.editable_cancel_quantity;
            }, 0)
          };
        }
        return line;
      });

      return { ...info, cancellationLines: updatedTagLevelLines };
    });
    setCurrentEditingCancelRoLine(record);
    setCancelRoQuantityFormVisible(false);
    cancelRoQuantityEditForm.resetFields();
  };

  const handleSubmitCancelRo = async () => {
    const { cancellationLines, modalOpen, record } = roCancelInfo;
    let checkQtyisZero = cancellationLines.some(data => data.editable_cancel_quantity === 0);
    if (checkQtyisZero) {
      displayErrorNotifications([{ message: t('cancelQuantityCannotBeZero') }]);
      return;
    }

    const groupedLines = _.groupBy(cancellationLines, 'order_fo_combination');
    // console.log({ groupedLines, record });

    const lines: any = [];

    Object.keys(groupedLines).map(key => {
      const foLines = groupedLines[key];

      const item_list = cancellationLines.map(line => {
        return {
          co_line_id: line.co_line_id,
          reason: line.cancel_reason,
          sku_id: line.sku_id,
          tags: line.tagLevelQuantities
            .filter((tag: any) => tag.editable_cancel_quantity > 0)
            .map((tag: any) => {
              return {
                tag_id: tag.tag_id,
                tag_type: tag.tag_type,
                quantity: {
                  ...line.quantity,
                  number_of_units: tag.editable_cancel_quantity
                }
              };
            })
        };
      });

      lines.push({
        order_number: foLines[0].order_number,
        fo_number: foLines[0].fo_number,
        item_list
      });
    });

    const request = {
      source: 'NEBULA',
      doc_type: 'RO',
      orders: [
        {
          ro_number: record?.ro_number,
          cancelled_by: 'ui',
          cancelled_at: new Date().toISOString(),
          lines
        }
      ]
    };

    setLoading(true);
    const { errors } = await orderService.cancelROFO(request);

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('roCancelledSuccessfully') });

      setRoCancelInfo({ cancellationLines: [], modalOpen: false, record: null });
      refetchPageDetails();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handlePageChange = (currentPage: number) => {
    const offset = (currentPage - 1) * PAGE_SIZE;
    // fetchROList(offset);
  };

  const showItemLevelModal = (item: any) => {
    setItemModalInfo({ itemInfo: item, open: true });
  };

  const closeItemLevelModal = () => {
    setItemModalInfo({ itemInfo: null, open: false });
  };

  const closePackRO = () => {
    setPackROInfo({ foItemLines: [], modalOpen: false, record: null, packages: [] });
    setAddPackageData(null);
  };

  const onEditPackRoQuantityLineSave = () => {
    // currentEditingFoLine
    const { record } = packRoInfo;

    const tagsFormInfo = packRoQuantityEditForm.getFieldsValue().tags_packing_info || [];

    const currentEditingSku = currentEditingFoLineForPack?.sku_id;

    setPackROInfo(info => {
      const updatedLines = info.foItemLines.map(line => {
        if (line.sku_id === currentEditingSku) {
          const tagLevelQuantities = line.tags.map((tag: any) => {
            const updatedPackQuantity =
              tagsFormInfo.find((tagForm: any) => tag.tag_id === tagForm.tag_id)?.editable_pack_quantity || 0;
            return {
              ...tag,
              editable_pack_quantity: updatedPackQuantity,
              remaining_data: tag.quantity.number_of_units - updatedPackQuantity
            };
          });

          return {
            ...line,
            tags: tagLevelQuantities,

            editable_pack_quantity: tagLevelQuantities.reduce((acc: any, curr: any) => {
              return acc + curr.editable_pack_quantity;
            }, 0)
          };
        }
        return line;
      });

      return { ...info, foItemLines: updatedLines };
    });

    setCurrentEditingFoLineForPack(record);
    setPackROQuantityFormVisible(false);
    packRoQuantityEditForm.resetFields();
  };

  const handleOnPackageAdd = () => {
    setAdditionData(packRoInfo.foItemLines);
    let foItems = packRoInfo.foItemLines;
    let duplicateArray = foItems.slice();
    let TabelDataUpdate = duplicateArray.map(tabelData => {
      let tabelItem = {
        ...tabelData,
        quantity: {
          ...tabelData.quantity,
          number_of_units: tabelData.quantity.number_of_units - tabelData.editable_pack_quantity
        },
        editable_pack_quantity: 0
      };
      return tabelItem;
    });

    if (additionData.length > 0) {
      let reducedData = duplicateArray.map(dupObj => {
        let qty = dupObj.quantity.number_of_units;
        let edtQty = dupObj.editable_pack_quantity;
        let data = {
          ...dupObj,
          quantity: {
            ...dupObj.quantity,
            number_of_units: qty - edtQty
          }
        };
        return data;
      });
      let packData = addPackageData ? addPackageData : [];
      let addPackageMoreData = [
        ...packData,
        {
          packageBasicData: packageAddForm.getFieldsValue(),
          tabelData: [...reducedData]
        }
      ];
      setAddPackageData(addPackageMoreData);
    } else {
      let addPackageData = [
        {
          packageBasicData: packageAddForm.getFieldsValue(),
          tabelData: [...foItems]
        }
      ];
      setAddPackageData(addPackageData);
    }
    setPackROInfo(packRoInfo => ({ ...packRoInfo, foItemLines: TabelDataUpdate }));
    setShowAddPackageModal(false);
    packageAddForm.setFieldsValue({
      package_height_in_cms: null,
      package_width_in_cms: null,
      package_length_in_cms: null,
      package_weight_in_kgs: null,
      awb_no: null,
      eway_bill_no: null,
      delivery_notes: null
    });
  };

  const getFulfilableQty = (tags: any) => {
    let data = tags.map((item: any) => {
      return {
        tag_id: item.tag_id,
        quantity: {
          number_of_units: item.editable_pack_quantity,
          unit_of_measure: item.quantity.unit_of_measure,
          unit_fraction: item.quantity.unit_fraction,
          unit_size: item.quantity.unit_size
        }
      };
    });
    return data;
  };

  const getLineData = (tableData: any) => {
    let data = tableData?.map((item: any) => {
      return {
        fo_number: item.fo_number,
        order_number: item.order_number,
        co_line_id: item.co_line_id,
        sku_id: item.sku_id,
        fulfilled_quantity: getFulfilableQty(item.tags)
      };
    });
    return data;
  };

  const addAllPackageData = (allPackageData: any) => {
    let data = allPackageData.map((item: any) => {
      return {
        lines: getLineData(item.tabelData)
      };
    });
    return data;
  };

  const getBodyData = () => {
    let data = {
      ro_number: packRoInfo?.record.ro_number,
      invoice_number: packROModalform.getFieldsValue().invoice_number,
      invoice_url: packROModalform.getFieldsValue().invoice_url,
      status: 'PACKED',
      status_datetime: new Date().toISOString(),
      mark_remaining_as_backorder: false,
      parcel: addAllPackageData(addPackageData)
    };
    return data;
  };

  const handleOnSubmitPackRO = async () => {
    setLoading(true);
    let bdyData = getBodyData();
    let { errors } = await orderService.releaseOrderPackUpdate(bdyData);
    if (_.isEmpty(errors)) {
      closePackRO();
      refetchPageDetails();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const addNewPackage = () => {
    setShowAddPackageModal(true);
    if (addPackageData) {
      setPackROInfo(packRoInfo => ({ ...packRoInfo, foItemLines: packRoInfo?.foItemLines }));
    } else {
      const foItemLines = packRoInfo.record?.fo_details?.flatMap((fo: any) =>
        fo.item_list.map((item: any) => ({
          ...item,
          fo_number: fo.fo_number,
          editable_pack_quantity: 0,
          order_number: fo.order_number
        }))
      );
      setPackROInfo(packRoInfo => ({ ...packRoInfo, foItemLines }));
    }
  };

  const handleOnDeletePackage = (deleteIndex: any) => {
    let currentDeleteData = addPackageData[deleteIndex].tabelData.map((item: any) => {
      return {
        skuId: item.sku_id,
        quantity: item.quantity.number_of_units,
        ordered_quantity: item.editable_pack_quantity,
        item_data: item
      };
    });
    if (packRoInfo.foItemLines.length) {
      const result = packRoInfo?.foItemLines.map((item1: any) => {
        let checkCurrentSKUPresentInDeletingPackageSKU = currentDeleteData
          .map((item: any) => item.skuId)
          .includes(item1.sku_id);
        if (checkCurrentSKUPresentInDeletingPackageSKU) {
          let returnMatchedSkuFromDeletingPackage = currentDeleteData.find((item: any) => item.skuId === item1.sku_id);
          let DataRetunToResult = {
            ...item1,
            quantity: {
              ...item1.quantity,
              number_of_units: item1.quantity.number_of_units + returnMatchedSkuFromDeletingPackage.ordered_quantity
            }
          };
          return DataRetunToResult;
        }
      });
      const flattenedData = result.flat();
      setPackROInfo(packRoInfo => ({ ...packRoInfo, foItemLines: flattenedData }));
    } else {
      let mainData = currentDeleteData.map((item: any) => {
        let main = item.item_data;
        let data = {
          ...main,
          quantity: {
            ...main.quantity,
            number_of_units: main.editable_pack_quantity
          },
          editable_pack_quantity: 0
        };
        return data;
      });
      setPackROInfo(packRoInfo => ({ ...packRoInfo, foItemLines: mainData }));
    }
    setAddPackageData((prevData: any) => {
      const newArray = prevData.filter((_: any, index: any) => index !== deleteIndex);
      return newArray;
    });
    // setPackROInfo
  };

  const shipmentInfoModal = () => {
    const roDetails = expandedRowData[expandedRowKeys[0]];
    let data = roDetails?.shipment_details;
    setShipmentData(data);
    setViewShipmentInfo(true);
  };

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
    searchForm.resetFields();
    let formValues = searchForm.getFieldsValue();
    const transformedFormValues = {
      ...formValues
    };
    const filteredFormValues = objectHelpers.deleteUndefinedValuesFromObject(transformedFormValues);
    setActiveStatusSearchKeys([]);
    setSearchParams(filteredFormValues);
    fetchROList([]);
    // refetchPageDetails()
  };

  const handleOnCloseAuditModal = () => {
    setAuditModal(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto">
        <Card>
          <Row justify={'start'} className="mb-4">
            {backUrl && (
              <div className="flex items-center">
                <GoBackButton backUrl={backUrl} />
              </div>
            )}
            <Col>
              <Typography.Title level={2} className="text-[#2e2a5b]">
                {t('releaseOrders')}
              </Typography.Title>
            </Col>
          </Row>

          <TenantInfo />

          <Tabs activeKey={activeTabKey} className="mt-4" onChange={handleTabChange}>
            <TabPane tab={<BoldButtonLabel labelText={t('releaseOrderSummary')} />} key="1">
              {!_.isEmpty(statusAggregateData) && (
                <section className="mt-4">
                  <Card className="bg-[#e7e7e729]">
                    {/* <span className="font-bold text-xl">{t('releaseOrderSummary')}</span> */}
                    {_.isEmpty(statusAggregateData?.delivery_methods) && <>{t('noReleaseOrdersFound')}</>}
                    {statusAggregateData?.delivery_methods.map(status => {
                      return (
                        <div className="mt-4" key={status.delivery_method}>
                          <section className="flex flex-wrap gap-4 items-center">
                            <Typography.Text className="text-base font-bold">
                              {t(status.delivery_method)} :
                            </Typography.Text>{' '}
                            <br />
                            <div className="flex gap-4 flex-wrap">
                              {status.status_counts.map(statusTile => (
                                <StatusTile
                                  isActive={activeStatusSearchKeys.includes(statusTile.status_description)}
                                  key={statusTile.status}
                                  label={`${t('total')} ${t(statusTile.status_description)} (${
                                    statusTile.status_count
                                  })`}
                                  value={statusTile.status_description}
                                  onClick={handleClickStatusSearchKey}
                                />
                              ))}
                            </div>
                          </section>
                        </div>
                      );
                    })}
                  </Card>
                </section>
              )}
            </TabPane>
            <TabPane tab={<BoldButtonLabel labelText={t('advanceSearch')} />} key="2">
              <section className="mt-4">
                <Form layout="vertical" form={searchForm} onFinish={handleFetchOrders}>
                  <Row gutter={12}>
                    <Col xs={24} md={12} lg={6}>
                      <Form.Item name={'node_id'} label={t('shipNode')}>
                        <Select
                          allowClear
                          size="large"
                          filterOption={false}
                          showSearch
                          placeholder={t('searchAndSelectNode')}
                          notFoundContent={nodeDropdownFetching ? <Spin size="small" /> : null}
                          onSearch={async searchTerm => {
                            if (searchTerm) await debouncedFetchNodeDropdownOptions(searchTerm);
                          }}
                          options={[...nodeDropdownOptions]}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                      <Form.Item name={'delivery_option'} label={t('deliveryOption')}>
                        <Select
                          size="large"
                          placeholder={t('selectDeliveryOption')}
                          allowClear
                          options={[
                            { label: t('pickUp'), value: 'PICK_UP' },
                            { label: t('homeDelivery'), value: 'HOME_DELIVERY' }
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                      <Form.Item name="date_range" label={t('orderDate')}>
                        <DatePicker.RangePicker size="large" style={{ borderRadius: '0' }} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                      <Form.Item name="order_number" label={t('orderNumber')}>
                        <Input size="large" placeholder={t('searchOrderNumber')} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col xs={24} md={12} lg={6}>
                      <Form.Item name="fo_number" label={t('foNumber')}>
                        <Input size="large" placeholder={t('searchFoNumber')} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                      <Form.Item name="ro_number" label={t('roNumber')}>
                        <Input size="large" placeholder={t('searchRoNumber')} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                      <Form.Item name="channel_id" label={t('channelId')}>
                        <Input size="large" placeholder={t('searchChannelId')} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} md={10} lg={4}>
                      <Button htmlType="submit" size="large" type="primary" block>
                        <BoldButtonLabel labelText={t('fetchOrders')} />
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </section>
            </TabPane>
          </Tabs>

          {!_.isEmpty(roListResponse?.releaseorder_list) && (
            <>
              {/* <div className="flex justify-end my-2">
          <CustomPagination
            currentPage={Math.floor(roListResponse.page_info.current_page / PAGE_SIZE + 1)}
            totalPages={roListResponse.page_info.total_pages}
            handleNext={handlePageChange}
            handlePageChange={handlePageChange}
            handlePrevious={handlePageChange}
          />
        </div> */}

              <section className="mt-4">
                <Table
                  pagination={{
                    total: roListResponse?.page_info?.total_pages
                      ? roListResponse.page_info?.total_pages *
                        (parseInt(searchParams.get('page_size') as string) || 10)
                      : 0,
                    showSizeChanger: true,
                    pageSizeOptions: ['1', '10', '20', '50', '100'],
                    pageSize: parseInt(searchParams.get('page_size') as string) || 10,
                    current: parseInt(searchParams.get('page_number') as string) || 1,
                    onChange: (currentPage, pageSize) => {
                      setSearchParams(prev => {
                        prev.set('page_number', currentPage + '');
                        prev.set('page_size', pageSize + '');
                        return prev;
                      });
                    }
                  }}
                  expandable={{
                    expandedRowRender,
                    expandedRowKeys,
                    async onExpand(expanded, record) {
                      if (expandedRowKeys.includes(record.ro_number)) {
                        return setExpandedRowKeys([]);
                      }
                      if (expanded) {
                        if (_.isEmpty(expandedRowData[record.ro_number])) {
                          await handleFetchShipments(record.ro_number, record.id, record);
                        }
                        setExpandedRowKeys([record.ro_number]);
                      }
                    }
                  }}
                  expandedRowClassName={record => (record ? 'bg-[#f0f2f5]' : '')} // Apply background color conditionally
                  bordered
                  className="mt-4"
                  dataSource={roListResponse?.releaseorder_list || []}
                  columns={RoColumns}
                  scroll={{ x: 1600 }}
                ></Table>
              </section>
            </>
          )}
        </Card>
      </div>

      <Modal
        title={t('orderPicking')}
        width={1400}
        maskClosable={false}
        closable={false}
        open={orderPickingModalVisible}
        footer={null}
        centered
      >
        <Table
          pagination={false}
          bordered
          className="mt-4"
          dataSource={orderPickingData.picking_lines}
          columns={orderPickingTableColumns}
          scroll={{ x: 1000 }}
        ></Table>
        <section className="flex gap-4 flex-wrap mt-4">
          <Button type="primary" size="large" onClick={handleSubmitPickRequest}>
            <BoldButtonLabel labelText={t('submit')} />
          </Button>
          <Button size="large" onClick={() => changeOrderPickingModalVisibility(false)}>
            <BoldButtonLabel labelText={t('cancel')} />
          </Button>
        </section>

        <Modal
          title={t('editPickQuantity')}
          maskClosable={false}
          closable={false}
          open={pickingQuantityChangeModalVisible}
          footer={null}
          centered
        >
          <section className="my-2 flex flex-col gap-2">
            <div className="flex flex-wrap gap-2">
              <FieldDisplay label={t('plannedQuantity')} value={currentPickingSkuDetails?.quantity?.number_of_units} />
              <FieldDisplay
                label={t('remainingQuantityToBePicked')}
                value={
                  (currentPickingSkuDetails?.quantity?.number_of_units || 0) -
                  (currentPickingSkuDetails?.existing_picked_quantity +
                    currentPickingSkuDetails?.existing_backorder_quantity +
                    currentPickingSkuDetails?.editable_backorder_quantity +
                    currentPickingSkuDetails?.editable_pick_quantity)
                }
              />
            </div>
            <div>
              <FieldDisplay label={t('sku')} value={t('toBeAdded')} />
            </div>
            <div>
              <FieldDisplay label={t('tag')} value={currentPickingSkuDetails?.tag_id} />
            </div>
          </section>

          <section className="my-2">
            <Form layout="vertical" form={pickQuantityFormInstance} onFinish={validateOrderAndSetEditedPickedLine}>
              <Form.Item
                name={'picked_quantity'}
                dependencies={['backorder_quantity']}
                label={t('pickQuantity')}
                validateTrigger={['onBlur']}
                rules={[
                  { required: true, message: t('pickedQuantityRequired') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const fulfillableQuantity = currentPickingSkuDetails?.quantity?.number_of_units || 0;
                      const alreadyPickedQuantity = currentPickingSkuDetails?.existing_picked_quantity;
                      const alreadyBackorderedQuantity = currentPickingSkuDetails?.existing_backorder_quantity;
                      const quantityRemainingToBePicked =
                        fulfillableQuantity - alreadyPickedQuantity - alreadyBackorderedQuantity;

                      if (!value || quantityRemainingToBePicked >= value + (getFieldValue('backorder_quantity') || 0)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('pickQuantityAndBackorderQuantityError')));
                    }
                  })
                ]}
              >
                <InputNumber className="w-full" placeholder="..." size="large" min={0} />
              </Form.Item>
              <Form.Item
                name={'backorder_quantity'}
                label={t('backorderQuantity')}
                dependencies={['picked_quantity']}
                validateTrigger="onBlur"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const fulfillableQuantity = currentPickingSkuDetails?.quantity?.number_of_units || 0;
                      const alreadyPickedQuantity = currentPickingSkuDetails?.existing_picked_quantity;
                      const alreadyBackorderedQuantity = currentPickingSkuDetails?.existing_backorder_quantity;
                      const quantityRemainingToBePicked =
                        fulfillableQuantity - alreadyPickedQuantity - alreadyBackorderedQuantity;

                      if (!value || quantityRemainingToBePicked >= value + (getFieldValue('picked_quantity') || 0)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('pickQuantityAndBackorderQuantityError')));
                    }
                  })
                ]}
              >
                <InputNumber className="w-full" placeholder="..." size="large" min={0} />
              </Form.Item>
              <Row gutter={[12, 12]}>
                <Col xs={12}>
                  <Button type="primary" block size="large" htmlType="submit">
                    <BoldButtonLabel labelText={t('confirm')} />
                  </Button>
                </Col>
                <Col xs={12}>
                  <Button size="large" block onClick={closePickEditModal}>
                    <BoldButtonLabel labelText={t('cancel')} />
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>
        </Modal>
      </Modal>

      <Modal
        title={t('orderReceiving')}
        width={1400}
        maskClosable={false}
        closable={false}
        open={orderReceivingModalVisible}
        footer={null}
        centered
      >
        <Table
          pagination={false}
          bordered
          className="mt-4"
          dataSource={orderReceivingData.receiving_lines}
          columns={orderReceivingTableColumns}
          scroll={{ x: 1000 }}
        ></Table>
        <section className="flex gap-4 flex-wrap mt-4">
          <Button type="primary" size="large" onClick={handleSubmitReceiveRequest}>
            <BoldButtonLabel labelText={t('submit')} />
          </Button>
          <Button size="large" onClick={() => changeOrderReceivingModalVisibility(false)}>
            <BoldButtonLabel labelText={t('cancel')} />
          </Button>
        </section>

        <Modal
          title={t('editReceiveQuantity')}
          maskClosable={true}
          closable={true}
          open={receivingQuantityChangeModalVisible}
          footer={null}
          onCancel={closeReceiveEditModal}
          centered
        >
          <section
            className="my-4 flex flex-col gap-4"
            style={{ overflowY: 'scroll', maxHeight: '60vh', overflowX: 'hidden' }}
          >
            <div>
              {receiveQuantityFormInstance.getFieldValue(['receipt_number']) && (
                <p className="text-l font-semibold m-0 pl-4">
                  {t('receiptNumber')}: {receiveQuantityFormInstance.getFieldValue(['receipt_number'])}
                </p>
              )}
            </div>
            <Form
              layout="vertical"
              form={receiveQuantityFormInstance}
              onFinish={() => {
                saveTheRecievedQuantityOnceConfirm();
              }}
              validateTrigger="onSubmit"
            >
              <Form.List name="tags">
                {tagFields => (
                  <>
                    {tagFields.map(({ key: tagsKey, name: tagsName, ...tagsRestField }) => {
                      const receivableQuantity = receiveQuantityFormInstance.getFieldValue([
                        'tags',
                        tagsName,
                        'receivable_quantity'
                      ]);
                      const destinationNodeName = receiveQuantityFormInstance.getFieldValue([
                        'tags',
                        tagsName,
                        'destination_node_name'
                      ]);
                      return (
                        <div key={tagsKey} className="p-4 border border-gray-200 rounded-lg shadow-sm">
                          <div className="flex gap-6 flex-wrap">
                            <p className="text-l font-semibold mb-4">
                              {t('tagId')}: {t(receiveQuantityFormInstance.getFieldValue(['tags', tagsName, 'tag_id']))}
                            </p>
                            <p className="text-l font-semibold mb-4">
                              {t('totalReceivable')}: {receivableQuantity}
                            </p>
                            <p className="text-l font-semibold mb-4">
                              {t('receivingNode')}: {destinationNodeName}
                            </p>
                          </div>

                          <Form.List name={[tagsName, 'quantityInput']}>
                            {(quantityFields, { add: addQuantity, remove: removeQuantity }) => (
                              <>
                                {quantityFields.map(
                                  (
                                    { key: quantityInputKey, name: quantityInputName, ...quantityInputRestField },
                                    index
                                  ) => (
                                    <div key={quantityInputKey} className="my-2">
                                      <Row gutter={[16, 12]}>
                                        <Col xs={14}>
                                          <Form.Item
                                            {...quantityInputRestField}
                                            name={[quantityInputName, 'received_quantity_input']}
                                            label={t('receivedQuantity')}
                                            validateTrigger={['onBlur']}
                                            rules={[
                                              { required: true, message: t('receivedQuantityRequired') },
                                              ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                  const totalQuantity = (
                                                    getFieldValue(['tags', tagsName, 'quantityInput']) || []
                                                  ).reduce(
                                                    (acc: any, curr: any) => acc + (curr?.received_quantity_input || 0),
                                                    0
                                                  );

                                                  if (totalQuantity > receivableQuantity) {
                                                    return Promise.reject(
                                                      new Error(
                                                        t('quantityExceeded', { receivableQuantity, totalQuantity })
                                                      )
                                                    );
                                                  }
                                                  return Promise.resolve();
                                                }
                                              })
                                            ]}
                                          >
                                            <InputNumber
                                              className="w-full"
                                              placeholder={t('enterQuantity')}
                                              size="large"
                                              min={0}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={8}>
                                          <Form.Item
                                            {...quantityInputRestField}
                                            name={[quantityInputName, 'disposition_code']}
                                            rules={[{ required: true, message: t('dispositionCodeRequired') }]}
                                            label={t('dispositionCode')}
                                          >
                                            <Select
                                              size="large"
                                              placeholder={t('selectCode')}
                                              options={[
                                                { value: 'GOOD', label: t('good') },
                                                { value: 'BAD', label: t('bad') },
                                                { value: 'UGLY', label: t('ugly') },
                                                { value: 'MISSING', label: t('missing') }
                                              ]}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={2} className="flex items-center justify-end">
                                          {quantityFields.length > 1 && (
                                            <DeleteOutlined
                                              onClick={() => removeQuantity(quantityInputName)}
                                              className="text-red-500 cursor-pointer"
                                            />
                                          )}
                                        </Col>
                                      </Row>
                                      {index === quantityFields.length - 1 && (
                                        <Button
                                          type="dashed"
                                          block
                                          icon={<PlusOutlined />}
                                          onClick={() => addQuantity()}
                                          className="mt-2"
                                        >
                                          {t('addMore')}
                                        </Button>
                                      )}
                                    </div>
                                  )
                                )}
                              </>
                            )}
                          </Form.List>
                        </div>
                      );
                    })}
                  </>
                )}
              </Form.List>
              <Row gutter={[12, 12]}>
                {!receiveQuantityFormInstance.getFieldValue(['receipt_number']) && (
                  <Col xs={12}>
                    <Button
                      type="primary"
                      block
                      size="large"
                      onClick={event => {
                        event.preventDefault();
                        getReceiptNumber(getReceiptNumberOnReceivedQuantityConfirmed);
                      }}
                      htmlType="button"
                    >
                      <BoldButtonLabel labelText={t('getReceiptNumber')} />
                    </Button>
                  </Col>
                )}
                {receiveQuantityFormInstance.getFieldValue(['receipt_number']) && (
                  <Col xs={12}>
                    <Button type="primary" block size="large" htmlType="submit">
                      <BoldButtonLabel labelText={t('confirm')} />
                    </Button>
                  </Col>
                )}
                <Col xs={12}>
                  <Button size="large" block onClick={closeReceiveEditModal}>
                    <BoldButtonLabel labelText={t('cancel')} />
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>
        </Modal>
      </Modal>

      <Modal
        title={t('rejectRO')}
        maskClosable={false}
        closable={false}
        open={rejectRoInfo?.modalOpen}
        footer={null}
        centered
      >
        <Form
          layout="vertical"
          onFinish={({ reason }) => {
            changeRoAckowledgement(rejectRoInfo?.record, 'REJECTED', reason);
            setRejectRoInfo({ record: null, modalOpen: false });
          }}
        >
          <Form.Item name={'reason'} rules={[{ required: true }]}>
            <Input.TextArea placeholder={t('enterRejectionReason')} />
          </Form.Item>
          <Row gutter={[12, 12]}>
            <Col xs={12}>
              <Button size="large" htmlType="submit" block type="primary">
                <BoldButtonLabel labelText={t('save')} />
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                size="large"
                block
                onClick={() => {
                  setRejectRoInfo({ record: null, modalOpen: false });
                }}
              >
                <BoldButtonLabel labelText={t('cancel')} />
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={t('shipmentInfo')}
        maskClosable={true}
        closable={true}
        open={viewShippmentInfo}
        footer={null}
        centered
        onCancel={() => {
          setViewShipmentInfo(false);
        }}
      >
        <Card>
          {shipmentData.map((shipData: any) => {
            return (
              <section
                style={{
                  background: '#9b9b9b29',
                  padding: '20px',
                  borderRadius: '5px',
                  marginBottom: '15px'
                }}
              >
                <div className="flex gap-4 flex-wrap mt-2">
                  <div>
                    <section className="px-2 flex flex-col">
                      <FieldDisplay label={t('carrierId')} value={shipData?.carrier_id} />
                      <FieldDisplay label={t('carrierName')} value={shipData?.carrier_name} />
                      {/* <FieldDisplay label={t('shipmentDate')} value={formatDate(shipData?.shipment_date)} /> */}
                    </section>
                  </div>
                  <div>
                    <section className="px-2 flex flex-col">
                      <FieldDisplay label={t('deliveryMethod')} value={convertToTitleCase(shipData?.delivery_method)} />
                      {/* <FieldDisplay label={t('deliverySpeed')} value={convertToTitleCase(shipData?.delivery_speed)} /> */}
                      <FieldDisplay
                        label={t('deliveryCharge')}
                        value={priceHelpers.formatPriceToLocaleCurrency({
                          amount:
                            (shipData?.delivery_price_info?.amount || 0) /
                            (shipData?.delivery_price_info?.fraction || 0),
                          baseCurrency,
                          userCurrency
                        })}
                      />
                    </section>
                  </div>
                  <div>
                    <section className="px-2 flex flex-col">
                      {/* <FieldDisplay label={t('awbNumber')} value={shipData?.tracking_info?.awb_number} /> */}
                      <FieldDisplay label={t('ewayBillNumber')} value={shipData?.tracking_info?.eway_bill_no} />
                    </section>
                  </div>
                </div>
              </section>
            );
          })}
          {/* {shipmentData && (
            <section
              style={{
                background: '#9b9b9b29',
                padding: '20px',
                borderRadius: '5px',
                marginBottom: '15px'
              }}
            >
              <div className="flex gap-4 flex-wrap mt-2">
                <div>
                  <section className="px-2 flex flex-col">
                    <FieldDisplay label="Carrier ID" value={shipmentData?.carrier_id} />
                    <FieldDisplay label="Carrier Name" value={shipmentData?.carrier_name} />
                    <FieldDisplay label="Shipment Date" value={formatDate(shipmentData?.shipment_date)} />
                  </section>
                </div>
                <div>
                  <section className="px-2 flex flex-col">
                    <FieldDisplay label="Delivery Method" value={convertToTitleCase(shipmentData?.delivery_method)} />
                    <FieldDisplay label="Delivery Speed" value={convertToTitleCase(shipmentData?.delivery_speed)} />
                    <FieldDisplay
                      label="Delivery Charge"
                      value={priceHelpers.formatPriceToLocaleCurrency({
                        amount:
                          (shipmentData?.delivery_price_info?.amount || 0) /
                          (shipmentData?.delivery_price_info?.fraction || 0),
                        baseCurrency,
                        userCurrency
                      })}
                    />
                  </section>
                </div>
                <div>
                  <section className="px-2 flex flex-col">
                    <FieldDisplay label="AWB Number" value={shipmentData?.tracking_info?.awb_number} />
                    <FieldDisplay label="Eway Bill Number" value={shipmentData?.tracking_info?.eway_bill_no} />
                  </section>
                </div>
              </div>
            </section>
          )} */}
        </Card>
      </Modal>
      <Modal
        title={t('holdRo')}
        maskClosable={true}
        closable={false}
        open={holdRoInfo?.modalOpen}
        footer={null}
        centered
      >
        <Form
          layout="vertical"
          onFinish={({ reason }) => {
            changeRoAckowledgement(holdRoInfo?.record, 'ON_HOLD', reason);
            setHoldRoInfo({ record: null, modalOpen: false });
          }}
        >
          <Form.Item name={'reason'} rules={[{ required: true }]}>
            <Input.TextArea placeholder={t('enterReasonForHold')} />
          </Form.Item>
          <Row gutter={[12, 12]}>
            <Col xs={12}>
              <Button size="large" htmlType="submit" block type="primary">
                <BoldButtonLabel labelText={t('save')} />
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                size="large"
                block
                onClick={() => {
                  setHoldRoInfo({ record: null, modalOpen: false });
                }}
              >
                <BoldButtonLabel labelText={t('cancel')} />
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={t('shipReleaseOrder')}
        maskClosable={false}
        closable={false}
        width={1200}
        open={shipRoInfo?.modalOpen}
        footer={null}
        centered
      >
        <Form
          layout="vertical"
          onFinish={formValues => {
            handleSubmitParcelRequest(formValues);
          }}
        >
          <div
            style={{
              height: 'fit-content',
              background: '#9b9b9b29',
              padding: '20px',
              marginBottom: '1rem',
              borderRadius: '10px'
            }}
          >
            <section className="mb-2">
              <Typography.Text className="font-bold">{t('itemDimensions')}</Typography.Text>

              <Table
                bordered
                className="mt-2"
                columns={[
                  {
                    title: t('sku'),
                    render(value, record, index) {
                      return (
                        <>
                          {t('sku')} - {record?.sku_id} <br /> {record?.product_desc}
                        </>
                      );
                    }
                  },
                  {
                    title: t('productDimensions'),
                    render(value, record, index) {
                      const {
                        package_height_in_cms,
                        package_length_in_cms,
                        package_weight_in_kgs,
                        package_width_in_cms
                      } = record?.package_details;
                      return (
                        <>
                          {t('length')} - {package_length_in_cms} cm <br /> {t('width')} - {package_width_in_cms} cm{' '}
                          <br /> {t('height')} - {package_height_in_cms} cm <br /> {t('weight')} -{' '}
                          {package_weight_in_kgs} kg <br />
                        </>
                      );
                    }
                  }
                ]}
                pagination={{ pageSize: 5 }}
                dataSource={getItemDimensionsDataSource(shipRoInfo)}
              />
            </section>
            <div className="mb-4">
              <Typography.Text className="font-bold">{t('packageDimensions')}</Typography.Text>
            </div>
            <Row gutter={12}>
              <Col xs={12} md={8} lg={6}>
                <Form.Item
                  label={t('heightInCM')}
                  name={'package_height_in_cms'}
                  rules={[{ required: true, message: t('fieldRequired') }]}
                >
                  <InputNumber size="large" min={0} className="w-full" />
                </Form.Item>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <Form.Item
                  label={t('widthInCM')}
                  name={'package_width_in_cms'}
                  rules={[{ required: true, message: t('fieldRequired') }]}
                >
                  <InputNumber size="large" min={0} className="w-full" />
                </Form.Item>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <Form.Item
                  label={t('lengthInCM')}
                  name={'package_length_in_cms'}
                  rules={[{ required: true, message: t('fieldRequired') }]}
                >
                  <InputNumber size="large" min={0} className="w-full" />
                </Form.Item>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <Form.Item
                  label={t('weightInKG')}
                  name={'package_weight_in_kgs'}
                  rules={[{ required: true, message: t('fieldRequired') }]}
                >
                  <InputNumber size="large" min={0} className="w-full" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            style={{
              height: 'fit-content',
              background: '#9b9b9b29',
              padding: '20px',
              borderRadius: '10px',
              marginBottom: '1rem'
            }}
          >
            <Row gutter={12}>
              <Col xs={12} md={8} lg={6}>
                <Form.Item label={t('vehicleType')} name={'veh_type'}>
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={12} md={8} lg={6}>
                <Form.Item label={t('containerType')} name={'container_type'}>
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            style={{
              height: 'fit-content',
              background: '#9b9b9b29',
              padding: '20px',
              borderRadius: '10px',
              marginBottom: '1rem'
            }}
          >
            <Typography.Text className="font-bold">{t('trackingInformation')}</Typography.Text>
            <Row gutter={12}>
              {shipRoInfo?.record?.value_details?.gross_amount < 50000 ? (
                <Col xs={12} md={8} lg={6}>
                  <Form.Item label={t('awbNo')} name={'awb_no'}>
                    <Input size="large" />
                  </Form.Item>
                </Col>
              ) : (
                <Col xs={12} md={8} lg={6}>
                  <Form.Item label={t('ewayBillNo')} name={'eway_bill_no'}>
                    <Input size="large" />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </div>
          <div
            style={{
              height: 'fit-content',
              background: '#9b9b9b29',
              padding: '20px',
              borderRadius: '10px',
              marginBottom: '1rem'
            }}
          >
            <Row>
              <Col xs={24}>
                <Form.Item label={t('deliveryNotes')} name={'delivery_notes'} rules={[{ required: true }]}>
                  <Input.TextArea placeholder={t('deliveryNotesPlaceholder')} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Row gutter={[12, 12]} justify="center">
            <Col xs={12} md={6}>
              <Button size="large" htmlType="submit" block type="primary">
                <BoldButtonLabel labelText={t('save')} />
              </Button>
            </Col>
            <Col xs={12} md={6}>
              <Button
                size="large"
                block
                onClick={() => {
                  setShipRoInfo({ record: null, modalOpen: false });
                }}
              >
                <BoldButtonLabel labelText={t('cancel')} />
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={t('cancelOrder')}
        footer={null}
        maskClosable={false}
        centered
        width={1200}
        open={roCancelInfo.modalOpen}
        onCancel={() => {
          setRoCancelInfo({ cancellationLines: [], modalOpen: false, record: null });
        }}
      >
        <section className="mt-8">
          <Table pagination={false} bordered columns={roCancelColumns} dataSource={roCancelInfo.cancellationLines} />
          <section className="flex gap-4 flex-wrap mt-4">
            <Button type="primary" size="large" onClick={handleSubmitCancelRo}>
              <BoldButtonLabel labelText={t('submit')} />
            </Button>
            <Button
              size="large"
              onClick={() => setRoCancelInfo({ cancellationLines: [], modalOpen: false, record: null })}
            >
              <BoldButtonLabel labelText={t('cancel')} />
            </Button>
          </section>
        </section>

        <Modal
          onCancel={() => {
            setCurrentEditingCancelRoLine(null);
            setCancelRoQuantityFormVisible(false);
            cancelRoQuantityEditForm.resetFields();
          }}
          width={1200}
          centered
          title={t('enterTagLevelDetails')}
          footer={null}
          maskClosable={false}
          open={cancelRoQuantityFormVisible}
        >
          <section>
            <Form
              form={cancelRoQuantityEditForm}
              layout="vertical"
              onFinish={() => {
                onEditCancelFoQuantityLineSave();
              }}
            >
              <Form.Item
                rules={[{ required: true, message: t('cancelReasonRequired') }]}
                label={t('cancellationReason')}
                name={'cancel_reason'}
              >
                <Input.TextArea placeholder={t('cancellationReason')} />
              </Form.Item>
              <Form.List name="tags_cancel_info">
                {fields => (
                  <>
                    <div style={{ display: 'flex', gap: 16, flexDirection: 'row' }}>
                      {fields.map((field, index) => (
                        <Card size="small" key={field.key}>
                          <Row gutter={12}>
                            <Col xs={8}>
                              <Form.Item label={t('sku')} name={[field.name, 'sku_id']}>
                                <Input className="w-full" readOnly size="large" />
                              </Form.Item>
                            </Col>
                            <Col xs={8}>
                              <Form.Item label={t('tagId')} name={[field.name, 'tag_id']}>
                                <Input className="w-full" readOnly size="large" />
                              </Form.Item>
                            </Col>
                            <Col xs={8}>
                              <Form.Item label={t('tagType')} name={[field.name, 'tag_type']}>
                                <Input className="w-full" readOnly size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={12}>
                            <Col xs={12}>
                              <Form.Item label={t('orderedQuantity')} name={[field.name, 'quantity_number']}>
                                <InputNumber className="w-full" readOnly size="large" />
                              </Form.Item>
                            </Col>
                            <Col xs={12}>
                              <Form.Item
                                rules={[
                                  { required: true, message: t('cancelQuantityRequired') },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      const orderedTagQuantity =
                                        getFieldValue('tags_cancel_info')[index]?.quantity_number;

                                      if (value === 0) {
                                        return Promise.reject(new Error(t('cancelOrderCannotBeZero')));
                                      }

                                      if (value > orderedTagQuantity)
                                        return Promise.reject(new Error(t('cancelQuantityExceedsOrderedQuantity')));

                                      return Promise.resolve();
                                    }
                                  })
                                ]}
                                label={t('cancelQuantity')}
                                name={[field.name, 'editable_cancel_quantity']}
                              >
                                <InputNumber
                                  className="w-full"
                                  min={1}
                                  size="large"
                                  placeholder={t('enterCancelQuantity')}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      ))}
                    </div>
                  </>
                )}
              </Form.List>
              <Row gutter={12} className="mt-4" justify={'center'}>
                <Col xs={12} md={6}>
                  <Button htmlType="submit" block size="large" type="primary">
                    <BoldButtonLabel labelText={t('save')} />
                  </Button>
                </Col>
                <Col xs={12} md={6}>
                  <Button
                    block
                    size="large"
                    onClick={() => {
                      setCurrentEditingCancelRoLine(null);
                      setCancelRoQuantityFormVisible(false);
                      cancelRoQuantityEditForm.resetFields();
                    }}
                  >
                    <BoldButtonLabel labelText={t('cancel')} />
                  </Button>
                </Col>
              </Row>
            </Form>
          </section>
        </Modal>
      </Modal>

      <Modal
        title={t('itemInformation')}
        footer={null}
        maskClosable={false}
        centered
        width={1200}
        open={itemModalInfo.open}
        onCancel={() => {
          closeItemLevelModal();
        }}
      >
        <ItemLevelView itemInfo={itemModalInfo.itemInfo} />
      </Modal>

      <Modal
        title={t('packRO')}
        footer={null}
        maskClosable={false}
        centered
        width={1200}
        open={packRoInfo.modalOpen}
        onCancel={() => {
          closePackRO();
        }}
      >
        <>
          <Form
            layout="vertical"
            form={packROModalform}
            onFinish={formValues => {
              handleOnSubmitPackRO();
            }}
          >
            <section>
              <Card
                className="my-6"
                bodyStyle={{ padding: '.5rem' }}
                bordered
                title={<div className="text-center h-full">{t('packRO')}</div>}
              >
                {!(packRoInfo?.record?.order_type === 'TRANSFER') && (
                  <Row gutter={12}>
                    <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        label={t('invoiceNumber')}
                        name={'invoice_number'}
                        rules={[{ required: true, message: t('fieldRequired') }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        label={t('invoiceUrl')}
                        name={'invoice_url'}
                        rules={[{ required: true, message: t('fieldRequired') }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <>
                  <div style={{ display: 'flex', flexWrap: 'wrap', maxHeight: '500px', overflow: 'auto' }}>
                    {addPackageData?.map((item: any, index: any) => {
                      return (
                        <div className="bg-[#9b9b9b29] flex flex-col gap-3 p-4 rounded-10" id={index}>
                          <div className="font-semibold flex items-center justify-between">
                            <div>
                              {t('package')} {index + 1}:
                            </div>
                            <div>
                              <Button type="link" className="text-red-600" onClick={() => handleOnDeletePackage(index)}>
                                <DeleteOutlined className="text-red-500 text-xl" title={t('deletePackage')} />
                              </Button>
                            </div>
                          </div>
                          {/* <div className='flex gap-[10px]'>
                          <div className="flex gap-2">
                            <div>
                              <Typography.Text className="text-[#898c98]">Weight: </Typography.Text>
                            </div>
                            <div className="font-semibold">{item?.packageBasicData?.package_weight_in_kgs} Cm</div>
                          </div>
                          <div className="flex gap-2">
                            <div>
                              <Typography.Text className="text-[#898c98]">Length: </Typography.Text>
                            </div>
                            <div className="font-semibold">{item?.packageBasicData?.package_length_in_cms} Cm</div>
                          </div>
                          <div className="flex gap-2">
                            <div>
                              <Typography.Text className="text-[#898c98]">Height: </Typography.Text>
                            </div>
                            <div className="font-semibold">{item?.packageBasicData?.package_height_in_cms} Cm</div>
                          </div>
                          <div className="flex gap-2">
                            <div>
                              <Typography.Text className="text-[#898c98]">Width: </Typography.Text>
                            </div>
                            <div className="font-semibold">{item?.packageBasicData?.package_width_in_cms} Cm</div>
                          </div>
                        </div>
                        <div>
                          <div className="flex gap-2">
                            <div>
                              <Typography.Text className="text-[#898c98]">Bill Num: </Typography.Text>
                            </div>
                            <div className="font-semibold">{item?.packageBasicData?.eway_bill_no}</div>
                          </div>
                        </div> */}
                          {item.tabelData.map((tableData: any) => {
                            return (
                              <div className="bg-[#9b9b9b29] flex flex-col p-4 rounded-10">
                                <div className="flex gap-2">
                                  <div>
                                    <Typography.Text className="text-[#898c98]">{t('sku')}:</Typography.Text>
                                  </div>
                                  <div className="font-semibold">{tableData.sku_id}</div>
                                </div>
                                <div className="flex gap-2">
                                  <div>
                                    <Typography.Text className="text-[#898c98]">{t('product')}:</Typography.Text>
                                  </div>
                                  <div className="font-semibold">{tableData.product_name}</div>
                                </div>
                                <div className="flex gap-2">
                                  <div>
                                    <Typography.Text className="text-[#898c98]">
                                      {t('pendingOrderQuantity')}:
                                    </Typography.Text>
                                  </div>
                                  <div className="font-semibold">{tableData.quantity.number_of_units}</div>
                                </div>
                                <div className="flex gap-2">
                                  <div>
                                    <Typography.Text className="text-[#898c98]">
                                      {t('orderedQuantity')}:
                                    </Typography.Text>
                                  </div>
                                  <div className="font-semibold">{tableData.editable_pack_quantity}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </>
              </Card>
              <Button size="large" type="primary" onClick={addNewPackage}>
                <BoldButtonLabel labelText={t('addPackage')} />
              </Button>
            </section>

            <div className="mt-2 flex gap-2 flex-wrap">
              <Button htmlType="submit" size="large" type="primary">
                <BoldButtonLabel labelText={t('submit')} />
              </Button>
              <Button size="large" onClick={closePackRO}>
                <BoldButtonLabel labelText={t('cancel')} />
              </Button>
            </div>
          </Form>
        </>
      </Modal>

      <Modal
        title={t('addPackage')}
        footer={null}
        maskClosable={false}
        centered
        width={1200}
        open={showAddPackageModal}
        onCancel={() => {
          setShowAddPackageModal(false);
        }}
      >
        <>
          <Form
            layout="vertical"
            form={packageAddForm}
            onFinish={formValues => {
              // handlePackageAdd();
              handleOnPackageAdd();
            }}
          >
            {/* <div
              style={{
                height: 'fit-content',
                background: '#9b9b9b29',
                padding: '20px',
                marginBottom: '1rem',
                borderRadius: '10px'
              }}
            >
              <div className="mb-4">
                <Typography.Text className="font-bold">{t('packageDimensions')}</Typography.Text>
              </div>
              <Row gutter={12}>
                <Col xs={12} md={8} lg={6}>
                  <Form.Item label={t('heightInCm')} name={'package_height_in_cms'} rules={[{ required: true, message: t('fieldRequired') }]}>
                    <InputNumber size="large" min={0} className="w-full" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={8} lg={6}>
                  <Form.Item label={t('widthInCm')} name={'package_width_in_cms'} rules={[{ required: true, message: t('fieldRequired') }]}>
                    <InputNumber size="large" min={0} className="w-full" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={8} lg={6}>
                  <Form.Item label={t('lengthInCm')} name={'package_length_in_cms'} rules={[{ required: true, message: t('fieldRequired') }]}>
                    <InputNumber size="large" min={0} className="w-full" />
                  </Form.Item>
                </Col>
                <Col xs={12} md={8} lg={6}>
                  <Form.Item label={t('weightInKg')} name={'package_weight_in_kgs'} rules={[{ required: true, message: t('fieldRequired') }]}>
                    <InputNumber size="large" min={0} className="w-full" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            */}

            {/* 
            <div
              style={{
                height: 'fit-content',
                background: '#9b9b9b29',
                padding: '20px',
                borderRadius: '10px',
                marginBottom: '1rem'
              }}
            >
              <Typography.Text className="font-bold">{t('trackingInformation')}</Typography.Text>
              <Row gutter={12}>
                {shipRoInfo?.record?.value_details?.gross_amount < 50000 ? (
                  <Col xs={12} md={8} lg={6}>
                    <Form.Item label={t('awbNo')} name={'awb_no'} rules={[{ required: true, message: t('fieldRequired') }]}>
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col xs={12} md={8} lg={6}>
                    <Form.Item label={t('ewayBillNo')} name={'eway_bill_no'} rules={[{ required: true, message: t('fieldRequired') }]}>
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </div>
            */}

            {/* 
            <div
              style={{
                height: 'fit-content',
                background: '#9b9b9b29',
                padding: '20px',
                borderRadius: '10px',
                marginBottom: '1rem'
              }}
            >
              <Row>
                <Col xs={24}>
                  <Form.Item label={t('deliveryNotes')} name={'delivery_notes'} rules={[{ required: true, message: t('fieldRequired') }]}>
                    <Input.TextArea placeholder={t('deliveryNotesPlaceholder')} />
                  </Form.Item>
                </Col>
              </Row>
            </div> */}
            <div
              style={{
                height: 'fit-content',
                background: '#9b9b9b29',
                padding: '20px',
                borderRadius: '10px',
                marginBottom: '1rem'
              }}
            >
              <Typography.Title level={5}>{t('addItemQuantities')}</Typography.Title>
              <Table
                pagination={false}
                dataSource={packRoInfo.foItemLines}
                columns={[
                  { title: t('sku'), dataIndex: 'sku_id', align: 'center' },
                  {
                    title: t('orderQuantity'),
                    dataIndex: 'sku_id',
                    align: 'center',
                    render: (_: any, record: any) => {
                      return <>{record?.quantity?.number_of_units}</>;
                    }
                  },
                  {
                    title: t('packageQuantity'),
                    align: 'center',
                    render: (_: any, record: any) => {
                      return (
                        <Button
                          type="ghost"
                          onClick={() => {
                            setCurrentEditingFoLineForPack(record);
                            setPackROQuantityFormVisible(true);

                            packRoQuantityEditForm.setFieldsValue({
                              tags_packing_info: record.tags.map((tag: any) => {
                                return {
                                  sku_id: record?.sku_id,
                                  product_name: record?.product_name,
                                  ...tag,
                                  quantity_number: record.quantity.number_of_units,
                                  editable_pack_quantity: 0
                                };
                              })
                            });
                          }}
                        >
                          {record.editable_pack_quantity} <EditOutlined />
                        </Button>
                      );
                    }
                  }
                ]}
              ></Table>
            </div>
            <div className="flex gap-4">
              <Button size="large" type="primary" htmlType="submit">
                <BoldButtonLabel labelText={t('save')} />
              </Button>
              <Button size="large" onClick={() => setShowAddPackageModal(false)}>
                <BoldButtonLabel labelText={t('cancel')} />
              </Button>
            </div>
          </Form>
        </>
      </Modal>

      <Modal
        onCancel={() => {
          setCurrentEditingFoLineForPack(null);
          setPackROQuantityFormVisible(false);
          packRoQuantityEditForm.resetFields();
        }}
        width={1200}
        centered
        title={t('enterTagLevelPackingDetails')}
        footer={null}
        maskClosable={false}
        open={packRoQuantityFormVisible}
      >
        <section>
          <Form
            form={packRoQuantityEditForm}
            layout="vertical"
            onFinish={() => {
              onEditPackRoQuantityLineSave();
            }}
          >
            <Form.List name="tags_packing_info">
              {fields => {
                return (
                  <>
                    <div style={{ display: 'flex', gap: 16, flexDirection: 'row' }}>
                      {fields.map((field, index) => (
                        <Card size="small" key={field.key}>
                          <Row gutter={12}>
                            <Col xs={8}>
                              <Form.Item label={t('sku')} name={[field.name, 'sku_id']}>
                                <Input className="w-full" readOnly size="large" />
                              </Form.Item>
                            </Col>
                            <Col xs={8}>
                              <Form.Item label={t('tagId')} name={[field.name, 'tag_id']}>
                                <Input className="w-full" readOnly size="large" />
                              </Form.Item>
                            </Col>
                            <Col xs={8}>
                              <Form.Item label={t('tagType')} name={[field.name, 'tag_type']}>
                                <Input className="w-full" readOnly size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={12}>
                            {/* <Col xs={12}>
                        <Form.Item label={t('productName')} name={[field.name, 'product_name']}>
                          <Input className="w-full" readOnly size="large" />
                        </Form.Item>
                      </Col> */}
                            <Col xs={12}>
                              <Form.Item label={t('orderedQuantity')} name={[field.name, 'quantity_number']}>
                                <InputNumber className="w-full" readOnly size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={12}>
                            <Col xs={12}>
                              <Form.Item
                                rules={[
                                  { required: true, message: t('packedQuantityRequired') },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      const orderedTagQuantity =
                                        getFieldValue('tags_packing_info')[index]?.quantity_number;

                                      if (value > orderedTagQuantity)
                                        return Promise.reject(new Error(t('packedQuantityError')));

                                      return Promise.resolve();
                                    }
                                  })
                                ]}
                                label={t('packedQuantity')}
                                name={[field.name, 'editable_pack_quantity']}
                              >
                                <InputNumber
                                  className="w-full"
                                  min={0}
                                  size="large"
                                  placeholder={t('enterCancelQuantity')}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      ))}
                    </div>
                  </>
                );
              }}
            </Form.List>
            <Row gutter={12} className="mt-4" justify={'center'}>
              <Col xs={12} md={6}>
                <Button htmlType="submit" block size="large" type="primary">
                  <BoldButtonLabel labelText={t('save')} />
                </Button>
              </Col>
              <Col xs={12} md={6}>
                <Button
                  block
                  size="large"
                  onClick={() => {
                    setCurrentEditingFoLineForPack(null);
                    setPackROQuantityFormVisible(false);
                    packRoQuantityEditForm.resetFields();
                  }}
                >
                  <BoldButtonLabel labelText={t('cancel')} />
                </Button>
              </Col>
            </Row>
          </Form>
        </section>
      </Modal>

      <Modal
        title={t('downloadShipLabel')}
        footer={null}
        maskClosable={false}
        centered
        width={900}
        open={downloadShipModal}
        onCancel={() => setDownloadShipModal(false)}
      >
        <Table
          className="mt-2"
          bordered
          columns={[
            {
              title: t('deliveryMethod'),
              align: 'center',
              render: (_, record) => {
                return <div>{record.delivery_method}</div>;
              }
            },
            {
              title: t('carrierId'),
              align: 'center',
              render: (_, record) => {
                return <div>{record.carrier_id}</div>;
              }
            },
            {
              title: t('carrierName'),
              align: 'center',
              render: (_, record) => {
                return <div>{record.carrier_name}</div>;
              }
            },
            {
              title: t('download'),
              align: 'center',
              render: (_, record) => {
                return (
                  <div>
                    {record?.label_link ? (
                      <>
                        {t('download')}{' '}
                        <DownloadOutlined onClick={() => handleOnDownloadlabelLink(record?.label_link)} />{' '}
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                );
              }
            }
          ]}
          dataSource={ShipmentDetailsDownloadResp || []}
          pagination={false}
        />
      </Modal>

      <Modal
        title={t('auditDetails')}
        footer={null}
        maskClosable={false}
        centered
        width={1000}
        open={auditModal}
        onCancel={() => {
          handleOnCloseAuditModal();
        }}
      >
        <section>
          {(auditDetails?.tags || []).map((item: any) => {
            return (
              <Card className="mb-2" key={item?.tag_id}>
                <Typography.Title level={5} className="text-[#2e2a5b]">
                  {t('tagId')}: {t(item?.tag_id)}
                </Typography.Title>
                <Descriptions size="middle" bordered className="mb-2">
                  <Descriptions.Item span={1} label={t('tagType')}>
                    <span> {t(item?.tag_type)}</span>
                  </Descriptions.Item>
                  <Descriptions.Item span={1} label={t('orderedQuantity')}>
                    <span>{item?.original_quantity?.number_of_units * item?.original_quantity?.unit_fraction}</span>
                  </Descriptions.Item>
                  <Descriptions.Item span={1} label={t('fulfillableQuantity')}>
                    <span>{item?.quantity?.number_of_units * item?.quantity?.unit_fraction}</span>
                  </Descriptions.Item>
                </Descriptions>
                <Table
                  pagination={false}
                  bordered
                  columns={[
                    {
                      title: t('statusDescription'),
                      align: 'center',
                      render: (_: any, record: any) => {
                        return <>{record?.status_description}</>;
                      }
                    },
                    {
                      title: t('createdAt'),
                      align: 'center',
                      render: (_: any, record: any) => {
                        let date = record?.created_at
                          ? `${dayjs(record?.created_at).format('YYYY-MM-DD')} ${dayjs(record?.created_at).format(
                              'HH:mm:ss'
                            )}`
                          : '';
                        return <>{date}</>;
                      }
                    },
                    {
                      title: t('quantity'),
                      align: 'center',
                      render: (_: any, record: any) => {
                        let quantity = record?.quantity?.number_of_units * record?.quantity?.unit_fraction;
                        return <>{quantity}</>;
                      }
                    }
                  ]}
                  dataSource={item?.statuses || []}
                  scroll={{ y: 400 }}
                />
              </Card>
            );
          })}
        </section>
      </Modal>
    </PrimaryLayout>
  );
};

export default ReleaseOrderList;
